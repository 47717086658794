import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "./component/partials/Navbar";
// import logo from "./logo.svg";

// css
import "./App.css";
// import './styles/main.css'

import LoginPage from "./component/pages/Login";
import LeftBar from "./component/partials/LeftBar";
import Institusi from "./component/pages/User/Institusi/Institusi";
import CreateInstitusi from "./component/pages/User/Institusi/CreateInstitusi";
import EditInstitusi from "./component/pages/User/Institusi/EditInstitusi";
import User from "./component/pages/User/User";
import Edit from "./component/pages/User/UserItems/Edit";
import StrukturKurikulum from "./component/pages/Referensi/StrukturKurikulum/StrukturKurikulum";
import KompetensiDasar from "./component/pages/Referensi/KompetensiDasar/KompetensiDasar";
import KodeWilayah from "./component/pages/Referensi/KodeWilayah";
import StandarSarpras from "./component/pages/Referensi/StandarSarpras/StandarSapras";
import SarprasManagement from "./component/pages/SarprasManagement/SarprasManagement";
import Penugasan from "./component/pages/Penugasan/Penugasan";
import Rombel from "./component/pages/Rombel/Rombel";
import JadwalPelajaran from "./component/pages/JadwalPelajaran/JadwalPelajaran";
import Mapel from "./component/pages/Referensi/Mapel/Mapel";

//Trafik
// import TrafikInstitusi from "./component/pages/User/Trafik/Institusi"; //jsx
// import Trafik from "./component/pages/User/Trafik/Trafik";
 //jsx


// import TrafikIns from "./component/pages/User/Trafik/Institusi"; //js


// import TrafikGuru from "./component/pages/Trafik/Guru";


function App() {
  let navbar = <Navbar />;
  let leftbar = <LeftBar />;
  // console.log(window.location.pathname);
  if (
    window.location.pathname === "/admin_login" &&
    window.location.pathname === "/"
  ) {
    navbar = null;
    leftbar = null;
  }
  if (localStorage.getItem("token") === "") {
    console.log("p1")
    return (
      <Router>
        <Switch>
          <Route path="/admin_login" component={LoginPage} />
          <Route exact path="/" component={LoginPage} />
        </Switch>
      </Router>
    );
  } else {
    return (
      <>
        <Router>
          {navbar}
          {leftbar}
          <Switch>
            {/* institusi */}
            <Route exact path="/" component={Institusi} />
            <Route exact path="/institusi" component={Institusi} />
            <Route path="/institusi/create" component={CreateInstitusi} />
            <Route
              path="/institusi/edit/:id_institusi"
              component={EditInstitusi}
            />
            <Route path="/user/edit/:id_user/:type" component={Edit} />
            <Route exact path="/user/:menuLabel" component={User} />

            {/* referensi */}
            <Route
              exact
              path="/referensi/struktur_kurikulum/:jenjangLabel"
              component={StrukturKurikulum}
            />
            <Route
              path="/referensi/kompetensi_dasar/:jenjangLabel"
              component={KompetensiDasar}
            />
            <Route path="/referensi/kode_wilayah" component={KodeWilayah} />
            <Route
              path="/referensi/standar_sarpras/:sarprasId"
              component={StandarSarpras}
            />
            <Route path="/referensi/mapel/:jenjangLabel" component={Mapel} />

            {/* SarprasManagement */}
            <Route path="/sarpras/:menuLabel" component={SarprasManagement} />

            {/* Penugasan  */}
            <Route path="/penugasan/:menuLabel" component={Penugasan} />

            {/* Rombel  */}
            <Route path="/rombel/:menuLabel" component={Rombel} />

            {/* Jadwal Pelajaran  */}
            <Route path="/jadwal_pelajaran/:menuLabel" component={JadwalPelajaran}/>

            {/* Trafik  */}
            {/* <Route path="/trafik/:menuLabel" component={TrafikInstitusi} /> */}

            {/* <Route exact path="/trafik" component={TrafikIns} />
            <Route exact path="/trafik/institusi" component={TrafikIns} />  */}

            
            {/* <Route exact path="/trafik/trafik" component={Trafik} />  */}
          

          </Switch>
        </Router>
      </>
    );
  }
}

export default App;
