import DataTable from '../../../partials/table/DataTable';
import React from "react";
import conf from '../../../../config';
import Select from 'react-select';
import axios from 'axios';
import SkeletonFilter from "../../../partials/SkeletonFilter";

export default class ContentRombelPerTa extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: `${conf.uri_backend}api/admin/v1/rombel/rombel`,
      url_insitutis_slc: `${conf.uri_backend}api/admin/v1/institusi/select_institusi`,
      institusi_sc: "",
      ta_sc: "",
      kelas_sc: "",
      text_sc: "",
      dataInstitusi: "",
      dataTa: "",
      dataKelas: "",
      display: false,
    };

    this.getInstitusi = this.getInstitusi.bind(this);
    this.getTa = this.getTa.bind(this);
    this.getKelas = this.getKelas.bind(this);
    this.filterInstitusi = this.filterInstitusi.bind(this);
    this.filterTa = this.filterTa.bind(this);
    this.filterKelas = this.filterKelas.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.searchText = this.searchText.bind(this);
  }

  componentDidMount() {
    this.getInstitusi();
    this.getTa();
    this.getKelas();
    this.dt.loading();
    this.dt.fetchEntities();
  }
  btnFilter = () => {
    this.setState({
      display: !this.state.display,
    });
  };

  getTa() {
    var ta = [];
    var u = new Date().getFullYear() + 1;
    var tahun_sekarang = new Date().getFullYear() + 1;

    for (var i = tahun_sekarang; i >= 2005; i--) {
      u = u - 1;
      ta.push({ value: u + " / " + i, label: u + " / " + i });
    }
    this.setState({ dataTa: ta });
  }

  getKelas() {
    var kelas = [
      { value: "I", label: "I" },
      { value: "II", label: "II" },
      { value: "III", label: "III" },
      { value: "IV", label: "IV" },
      { value: "V", label: "V" },
      { value: "VI", label: "VI" },
      { value: "VII", label: "VII" },
      { value: "VIII", label: "VIII" },
      { value: "IX", label: "IX" },
      { value: "X", label: "X" },
      { value: "XI", label: "XI" },
      { value: "XII", label: "XII" },
      { value: "XIII", label: "XIII" },
    ];

    this.setState({ dataKelas: kelas });
  }

  getInstitusi() {
    axios.get(this.state.url_insitutis_slc).then((res) => {
      this.setState({ dataInstitusi: res.data });
    });
  }

  filterInstitusi(e) {
    if (e) {
      this.setState({ institusi_sc: e.value });
    } else {
      this.setState({ institusi_sc: "" });
    }
  }

  filterTa(e) {
    if (e) {
      this.setState({ ta_sc: e.value });
    } else {
      this.setState({ ta_sc: "" });
    }
  }

  filterKelas(e) {
    if (e) {
      this.setState({ kelas_sc: e.value });
    } else {
      this.setState({ kelas_sc: "" });
    }
  }

  resetFilter() {
    this.setState(
      {
        institusi_sc: "",
        ta_sc: "",
        kelas_sc: "",
        dataTa: "",
        dataInstitusi: "",
        dataKelas: "",
      },
      function () {
        this.getTa();
        this.getInstitusi();
        this.getKelas();
        this.dt.loading();
        this.dt.resetPage(`
        &query=${this.state.text_sc}
        &institusi=${this.state.institusi_sc}
        &tahun_ajaran=${this.state.ta_sc}
        &kelas=${this.state.kelas_sc}
      `);
      }
    );
  }

  setFilter() {
    this.dt.loading();
    this.dt.resetPage(`
      &query=${this.state.text_sc}
      &institusi=${this.state.institusi_sc}
      &tahun_ajaran=${this.state.ta_sc}
      &kelas=${this.state.kelas_sc}
    `);
  }

  searchText(e) {
    e.preventDefault();
    this.setState({ text_sc: e.target.value }, function () {
      this.dt.loading();
      this.dt.resetPage(`
        &query=${this.state.text_sc}
        &institusi=${this.state.institusi_sc}
        &tahun_ajaran=${this.state.ta_sc}
        &kelas=${this.state.kelas_sc}
      `);
    });
  }

  render() {
    return (
      <div className="col-12 mt-4">
        <div className="input-group input-group-merge input-group-borderless">
          <div className="input-group-prepend">
            <div className="input-group-text">
              <i className="tio-search"></i>
            </div>
          </div>
          <input
            id="datatableSearch"
            onChange={this.searchText}
            type="search"
            className="form-control"
            placeholder="Cari Nama Rombel, Nama Wali Kelas, Nama Institusi"
            aria-label="Search users"
          />
          <div className="col-auto">
            <div className="d-flex align-items-center">
              {/* <!-- Filter Collapse Trigger --> */}
              <a
                className="btn btn-white dropdown-toggle"
                data-toggle="collapse"
                href="#filterSearchCollapse"
                role="button"
                onClick={()=>this.btnFilter()}
              >
                <i className="tio-filter-outlined mr-1"></i> Filters
              </a>
              {/* <!-- End Filter Collapse Trigger --> */}
            </div>
          </div>
        </div>
        <hr />
        {/* <!-- Filter Search Collapse --> */}
        <div style={{display:this.state.display === false  ? "none" : "block"}}>
          <form>
            <div className="row">
              {this.state.dataInstitusi !== "" ? (
                <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
                  <label className="text-sm">Institusi</label>
                  <Select
                    placeholder={"Pilih Institusi"}
                    isClearable={true}
                    options={this.state.dataInstitusi}
                    onChange={this.filterInstitusi}
                  />
                </div>
              ) : (
                <SkeletonFilter />
              )}
              {this.state.dataTa !== "" ? (
                <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
                  <label className="text-sm">Tahun Ajaran</label>
                  <Select
                    placeholder={"Pilih Tahun Ajaran"}
                    isClearable={true}
                    options={this.state.dataTa}
                    onChange={this.filterTa}
                  />
                </div>
              ) : (
                <SkeletonFilter />
              )}
              {this.state.dataKelas !== "" ? (
                <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
                  <label className="text-sm">Kelas</label>
                  <Select
                    placeholder={"Pilih Kelas"}
                    isClearable={true}
                    options={this.state.dataKelas}
                    onChange={this.filterKelas}
                  />
                </div>
              ) : (
                <SkeletonFilter />
              )}
            </div>
            {/* <!-- End Row --> */}

            <div className="d-flex justify-content-end">
              <button
                onClick={this.resetFilter}
                type="button"
                className="btn btn-white mr-2"
              >
                Reset
              </button>
              <button
                onClick={this.setFilter}
                type="button"
                className="btn btn-primary"
              >
                Cari
              </button>
            </div>
          </form>
          <hr />
        </div>

        <DataTable
          ref={(dt) => {
            this.dt = dt;
          }}
          action={{
            jenis_kelamin: (col, data) => {
              var jekel = "";
              if (col.jenis_kelamin === 1) {
                jekel = "Laki - laki";
              } else if (col.jenis_kelamin === 0) {
                jekel = "Perempuan";
              } else {
                jekel = "Belum diketahui";
              }
              return <p className="text-sm">{jekel}</p>;
            },
          }}
          url={this.state.url}
          columns={[
            {
              index: "tahun_ajaran",
              label: "TAHUN AJARAN",
            },
            {
              index: "kelas",
              label: "KELAS",
            },
            {
              index: "nama_rombel",
              label: "ROMBEL",
            },
            {
              index: "siswa_count",
              label: "JUMLAH",
            },
            {
              index: "nama_institusi",
              label: "INSTITUSI",
            },
            {
              index: "penugasan_guru_nama",
              label: "WALI KELAS",
            },
          ]}
        />
      </div>
    );
  }
}