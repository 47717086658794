import DataTable from '../../../../partials/table/DataTable';
import React from "react";
import conf from '../../../../../config';
import Select from 'react-select';
import tokenApi from "../../../../auth/Helpers";
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';

export default class ContentSd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url       :  `${conf.uri_backend}api/admin/v1/referensi/kd/`,
      url_kmp   :  `${conf.uri_backend}api/v1/referensi/kmp/select/`,
      url_jkmp  :  `${conf.uri_backend}api/v1/referensi/jkmp/select/`,
      url_mp    :  `${conf.uri_backend}api/v1/referensi/mp/select/`,
      url_mpk   :  `${conf.uri_backend}api/v1/referensi/mpk/select/`,
      url_ki    :  `${conf.uri_backend}api/v1/referensi/ki/select/`,
      url_kd    :  `${conf.uri_backend}api/v1/referensi/kd/select/`,

      data_kmp  : '',
      data_jkmp : '',
      data_mp   : '',
      data_mpk  : '',
      data_ki   : '',
      data_kd   : '',
      
      kmp_sc    : '',
      jkmp_sc   : '',
      mp_sc     : '',
      mpk_sc    : '',
      ki_sc     : '',
      kd_sc     : '',
    };

    this.getKmp     = this.getKmp.bind(this);
    this.getJkmp    = this.getJkmp.bind(this);
    this.getMp      = this.getMp.bind(this);
    this.getMpk     = this.getMpk.bind(this);
    this.getKi      = this.getKi.bind(this);
    this.getKd      = this.getKd.bind(this);
    
    this.filterKmp  = this.filterKmp.bind(this);
    this.filterJkmp = this.filterJkmp.bind(this);
    this.filterMp   = this.filterMp.bind(this);
    this.filterMpk  = this.filterMpk.bind(this);
    this.filterKi   = this.filterKi.bind(this);
    this.filterKd   = this.filterKd.bind(this);
  }

  componentDidMount() {
    let q = "";
    if (this.props.query) {
      q = `&query=${this.props.query}`;
    }
    var jenjang= this.props.jenjang;
    this.getKmp();
    this.dt.loading();
    this.dt.fetchEntities(
      `&jenjang_id=${jenjang}${q}`
    );
  }
  
  componentWillReceiveProps(nextProps) {
    let q = "";
    if (nextProps.query) {
      q = `&query=${nextProps.query}`;
    }
    var jenjang = this.props.jenjang;
    this.dt.loading();
    this.dt.fetchEntities(
      `&jenjang_id=${jenjang}${q}`
    );
  }

  getKmp() {
    axios.get(this.state.url_kmp+`?jenjang_id=${this.props.jenjang}`, {
      headers: {
        Authorization: tokenApi
      }
    }).then((res) => {
      this.setState({data_kmp: res.data});
    });
  }

  getJkmp() {
    axios.get(this.state.url_jkmp+`?jenjang_id=${this.props.jenjang}&kmp_id=${this.state.kmp_sc}`, {
      headers: {
        Authorization: tokenApi
      }
    }).then((res) => {
      this.setState({data_jkmp: res.data});
    });
  }

  getMp(){
    axios.get(this.state.url_mp+`?jenjang_id=${this.props.jenjang}&jkmp_id=${this.state.jkmp_sc}`, {
      headers: {
        Authorization: tokenApi
      }
    }).then((res) => {
      this.setState({data_mp: res.data});
    });
  }

  getMpk(){
    axios.get(this.state.url_mpk+`?jenjang_id=${this.props.jenjang}&mp_id=${this.state.mp_sc}`, {
      headers: {
        Authorization: tokenApi
      }
    }).then((res) => {
      this.setState({data_mpk: res.data});
    });
  }

  getKi(){
    axios.get(this.state.url_ki+`?jenjang_id=${this.props.jenjang}&mpk_id=${this.state.mpk_sc}`, {
      headers: {
        Authorization: tokenApi
      }
    }).then((res) => {
      this.setState({data_ki: res.data});
    });
  }

  getKd(){
    axios.get(this.state.url_kd+`?jenjang_id=${this.props.jenjang}&ki_id=${this.state.ki_sc}`, {
      headers: {
        Authorization: tokenApi
      }
    }).then((res) => {
      this.setState({data_kd: res.data});
    });
  }

  filterKmp(e){
    if(e){
      this.setState({ 
        kmp_sc    : e.value,
        data_jkmp : '',
        data_mp   : '',
        data_mpk  : '',
        data_ki   : '',
        data_kd   : '',
        jkmp_sc   : '',
        mp_sc     : '',
        mpk_sc    : '',
        ki_sc     : '',
        kd_sc     : '',
      }, function(){
        this.getJkmp();
        this.dt.loading();
        this.dt.resetPage(`
          &jenjang_id=${this.props.jenjang}
          &kmp_id=${this.state.kmp_sc}
        `);
      })
    } else {
      this.setState({ 
        kmp_sc    : '',
        data_jkmp : '',
        data_mp   : '',
        data_mpk  : '',
        data_ki   : '',
        data_kd   : '',
        jkmp_sc   : '',
        mp_sc     : '',
        mpk_sc    : '',
        ki_sc     : '',
        kd_sc     : '',
      }, function(){
        this.dt.loading();
        this.dt.resetPage(`
          &jenjang_id=${this.props.jenjang}
        `)
      })
    }
  }

  filterJkmp(e){
    if(e){
      this.setState({ 
        jkmp_sc   : e.value,
        data_mp   : '',
        data_mpk  : '',
        data_ki   : '',
        data_kd   : '',
        mp_sc     : '',
        mpk_sc    : '',
        ki_sc     : '',
        kd_sc     : '',
      }, function(){
        this.getMp();
        this.dt.loading();
        this.dt.resetPage(`
          &jenjang_id=${this.props.jenjang}
          &kmp_id=${this.state.kmp_sc}
          &jkmp_id=${this.state.jkmp_sc}
        `);
      })
    } else {
      this.setState({ 
        jkmp_sc   : '',
        data_mp   : '',
        data_mpk  : '',
        data_ki   : '',
        data_kd   : '',
        mp_sc     : '',
        mpk_sc    : '',
        ki_sc     : '',
        kd_sc     : '',
      }, function(){
        this.dt.loading();
        this.dt.resetPage(`
          &jenjang_id=${this.props.jenjang}
          &kmp_id=${this.state.kmp_sc}
        `)
      })
    }
  }

  filterMp(e){
    if(e){
      this.setState({ 
        mp_sc     : e.value,
        data_mpk  : '',
        data_ki   : '',
        data_kd   : '',
        mpk_sc    : '',
        ki_sc     : '',
        kd_sc     : '',
      }, function(){
        this.getMpk();
        this.dt.loading();
        this.dt.resetPage(`
          &jenjang_id=${this.props.jenjang}
          &kmp_id=${this.state.kmp_sc}
          &jkmp_id=${this.state.jkmp_sc}
          &mp_id=${this.state.mp_sc}
        `);
      })
    } else {
      this.setState({ 
        mp_sc     : '',
        data_mpk  : '',
        data_ki   : '',
        data_kd   : '',
        mpk_sc    : '',
        ki_sc     : '',
        kd_sc     : '',
      }, function(){
        this.dt.loading();
        this.dt.resetPage(`
          &jenjang_id=${this.props.jenjang}
          &kmp_id=${this.state.kmp_sc}
          &jkmp_id=${this.state.jkmp_sc}
        `)
      })
    }
  
  }

  filterMpk(e){
    if(e){
      this.setState({ 
        mpk_sc    : e.value,
        data_ki   : '',
        data_kd   : '',
        ki_sc     : '',
        kd_sc     : '',
      }, function(){
        this.getKi();
        this.dt.loading();
        this.dt.resetPage(`
          &jenjang_id=${this.props.jenjang}
          &kmp_id=${this.state.kmp_sc}
          &jkmp_id=${this.state.jkmp_sc}
          &mp_id=${this.state.mp_sc}
          &mpk_id=${this.state.mpk_sc}
        `);
      })
    } else {
      this.setState({ 
        mpk_sc    : '',
        data_ki   : '',
        data_kd   : '',
        ki_sc     : '',
        kd_sc     : '',
      }, function(){
        this.dt.loading();
        this.dt.resetPage(`
          &jenjang_id=${this.props.jenjang}
          &kmp_id=${this.state.kmp_sc}
          &jkmp_id=${this.state.jkmp_sc}
          &mp_id=${this.state.mp_sc}
        `)
      })
    }
  }
  
  filterKi(e){
    if(e){
      this.setState({ 
        ki_sc     : e.value,
        data_kd   : '',
        kd_sc     : '',
      }, function(){
        this.getKd();
        this.dt.loading();
        this.dt.resetPage(`
          &jenjang_id=${this.props.jenjang}
          &kmp_id=${this.state.kmp_sc}
          &jkmp_id=${this.state.jkmp_sc}
          &mp_id=${this.state.mp_sc}
          &mpk_id=${this.state.mpk_sc}
          &ki_id=${this.state.ki_sc}
        `);
      })
    } else {
      this.setState({ 
        ki_sc     : '',
        data_kd   : '',
        kd_sc     : '',
      }, function(){
        this.dt.loading();
        this.dt.resetPage(`
          &jenjang_id=${this.props.jenjang}
          &kmp_id=${this.state.kmp_sc}
          &jkmp_id=${this.state.jkmp_sc}
          &mp_id=${this.state.mp_sc}
          &mpk_id=${this.state.mpk_sc}
        `)
      })
    }
  }

  filterKd(e){
    if(e){
      this.setState({ 
        kd_sc     : e.value,
      }, function(){
        this.dt.loading();
        this.dt.resetPage(`
          &jenjang_id=${this.props.jenjang}
          &kmp_id=${this.state.kmp_sc}
          &jkmp_id=${this.state.jkmp_sc}
          &mp_id=${this.state.mp_sc}
          &mpk_id=${this.state.mpk_sc}
          &ki_id=${this.state.ki_sc}
          &kd_id=${this.state.kd_sc}
        `);
      })
    } else {
      this.setState({ 
        kd_sc     : '',
      }, function(){
        this.dt.loading();
        this.dt.resetPage(`
          &jenjang_id=${this.props.jenjang}
          &kmp_id=${this.state.kmp_sc}
          &jkmp_id=${this.state.jkmp_sc}
          &mp_id=${this.state.mp_sc}
          &mpk_id=${this.state.mpk_sc}
          &ki_id=${this.state.ki_sc}
        `)
      })
    }
  }
  
  render() {
    return (
      <>
        <div className="col-12 mt-4">
          <div className="row">
          {this.state.data_kmp !== '' ? (
              <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
                <label className="text-sm">Kelompok Mapel</label>
                <Select
                  isClearable={true}
                  options={this.state.data_kmp}
                  placeholder={"Kelompok Mapel"}
                  onChange={this.filterKmp}
                />
              </div>
            ) : (
              <div className="col-3 "> 
                <Skeleton height={15} className="bg-light"/> 
                <Skeleton height={15} className="bg-light"/> 
                <Skeleton height={15} className="bg-light"/> 
              </div>
            )}
            {this.state.data_jkmp !== '' && this.state.kmp_sc !== '' ? (
              <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
                <label className="text-sm">Jenis Mata Kelompok</label>
                <Select
                  isClearable={true}
                  options={this.state.data_jkmp}
                  placeholder={"Jenis Mata Kelompok"}
                  onChange={this.filterJkmp}
                />
              </div>
            ) : (
              this.state.data_jkmp === '' && this.state.kmp_sc !== '' ? (
                <div className="col-3 "> 
                  <Skeleton height={15} className="bg-light"/> 
                  <Skeleton height={15} className="bg-light"/> 
                  <Skeleton height={15} className="bg-light"/> 
                </div>
              ) : ("")
            )}
            {this.state.data_mp !== '' && this.state.jkmp_sc !== '' ? (
              <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
                <label className="text-sm">Mata Pelajaran</label>
                <Select
                  isClearable={true}
                  options={this.state.data_mp}
                  placeholder={"Mata Pelajaran"}
                  onChange={this.filterMp}
                />
              </div>
            ) : (
              this.state.data_mp === '' && this.state.jkmp_sc !== '' ? (
                <div className="col-3 "> 
                  <Skeleton height={15} className="bg-light"/> 
                  <Skeleton height={15} className="bg-light"/> 
                  <Skeleton height={15} className="bg-light"/> 
                </div>
              ) : ("")
            )}
            {this.state.data_mpk !== '' && this.state.mp_sc !== '' ? (
              <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
                <label className="text-sm">Kelas</label>
                <Select
                  isClearable={true}
                  options={this.state.data_mpk}
                  placeholder={"Kelas"}
                  onChange={this.filterMpk}
                />
              </div>
            ) : (
              this.state.data_mpk === '' && this.state.mp_sc !== '' ? (
                <div className="col-3 "> 
                  <Skeleton height={15} className="bg-light"/> 
                  <Skeleton height={15} className="bg-light"/> 
                  <Skeleton height={15} className="bg-light"/> 
                </div>
              ) : ("")
            )}
            {this.state.data_ki !== '' && this.state.mpk_sc !== '' ? (
              <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
                <label className="text-sm">Kompetensi Inti</label>
                <Select
                  isClearable={true}
                  options={this.state.data_ki}
                  placeholder={"Kompetensi Inti"}
                  onChange={this.filterKi}
                />
              </div>
            ) : (
              this.state.data_ki === '' && this.state.mpk_sc !== '' ? (
                <div className="col-3 "> 
                  <Skeleton height={15} className="bg-light"/> 
                  <Skeleton height={15} className="bg-light"/> 
                  <Skeleton height={15} className="bg-light"/> 
                </div>
              ) : ("")
            )}
            {this.state.data_kd !== '' && this.state.ki_sc !== '' ? (
              <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
                <label className="text-sm">Kompetensi Dasar</label>
                <Select
                  isClearable={true}
                  options={this.state.data_kd}
                  placeholder={"Kompetensi Dasar"}
                  onChange={this.filterKd}
                />
              </div>
            ) : (
              this.state.data_kd === '' && this.state.ki_sc !== '' ? (
                <div className="col-3 "> 
                  <Skeleton height={15} className="bg-light"/> 
                  <Skeleton height={15} className="bg-light"/> 
                  <Skeleton height={15} className="bg-light"/> 
                </div>
              ) : ("")
            )}
          </div>
        </div>
        <DataTable
          ref={(dt) => {
            this.dt = dt;
          }}
          url={this.state.url}
          columns={[
            {
              index: "indeks_jenjang",
              label: "J",
            },
            {
              index: "kode_kmp",
              label: "KMP",
            },

            {
              index: "kode_jkmp",
              label: "JKMP",
            },
            {
              index: "kode_mp",
              label: "MP",
            },
            {
              index: "kode_mpk",
              label: "KLs",
            },
            {
              index: "kode_ki",
              label: "KI",
            },
            {
              index: "kode_kd",
              label: "KD",
            },
            {
              index: "kd",
              label: "Rincian Kompetensi Dasar",
            },
            {
              index: "indeks_kd",
              label: "Indeks KD",
            },
          ]}
        />
      </>
    );
  }
}