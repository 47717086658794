import DataTable from '../../../partials/table/DataTable';
import React from "react";
import conf from '../../../../config';
import axios from 'axios';
import tokenApi from "../../../auth/Helpers";
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

export default class DetailUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url            : `${conf.uri_backend}api/admin/v1/user/detail`,
      showModal      : false,
      loading_detail : false,
      data_detail    : '',
    };

    this.hideModal = this.hideModal.bind(this);
  }

  componentDidMount() {
    this.setState({ loading_detail : true });

    if(this.props.id !== null && this.props.showMode === true){
      axios.get(this.state.url+"/"+this.props.id+"/"+this.props.typeUser, {headers:{Authorization:tokenApi}})
      .then(res => {
        this.setState({
          data_detail    : res.data.data,
          loading_detail : false,
          showModal      : true,
        });
      });
    }
  }
  
  componentWillReceiveProps(nextProps) {
    this.setState({ loading_detail : true });

    if(nextProps.id !== null && nextProps.showMode === true){
      axios.get(this.state.url+"/"+nextProps.id+"/"+nextProps.typeUser, {headers:{Authorization:tokenApi}})
      .then(res => {
        this.setState({
          data_detail    : res.data.data,
          loading_detail : false,
          showModal      : true,
        });
      });
    }
  }

  hideModal(){
    this.setState({ 
      data_detail : '',
      showModal   : false,
    }, function(){
      this.props.hideModal(true);
    })
  }
  
  render() {
    return (
      <Modal
        size="xl"
        show={this.state.showModal}
        onHide={this.hideModal}
      >
        <Modal.Body className="p-0">
          {this.state.loading_detail === true ? (
            <>
              <div className="col-3"> 
                <Skeleton height={15} className="bg-light"/> 
                <Skeleton height={15} className="bg-light"/> 
                <Skeleton height={15} className="bg-light"/> 
              </div>
              <div className="row"> 
                <div className="col-6">
                  <Skeleton height={15} className="bg-light"/> 
                  <Skeleton height={15} className="bg-light"/> 
                  <Skeleton height={15} className="bg-light"/> 
                  <Skeleton height={15} className="bg-light"/> 
                  <Skeleton height={15} className="bg-light"/> 
                  <Skeleton height={15} className="bg-light"/> 
                </div> 
                <div className="col-6">
                  <Skeleton height={15} className="bg-light"/> 
                  <Skeleton height={15} className="bg-light"/> 
                  <Skeleton height={15} className="bg-light"/> 
                  <Skeleton height={15} className="bg-light"/> 
                  <Skeleton height={15} className="bg-light"/> 
                  <Skeleton height={15} className="bg-light"/> 
                </div> 
              </div>
            </>
          ) : (
            <>
              <div className="col-12 px-0">
                <div className={`col-12 bg-1 mb-2`} 
                  style={this.state.data_detail.foto_sampul ? {
                    height: '10rem',
                    width : '100%',
                    backgroundImage: `url(https://static.schoolmedia.id/public/assets/socmed/${this.props.typeUser}/cover/${this.state.data_detail.foto_sampul})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  } : {
                    height: '10rem',
                    width : '100%',
                    backgroundColor : `#dddddd`,
                  }}
                >
                  <div className="overflow-hidden rounded-lg" >
                    <img className="avatar-img mt-5 ml-2" style={{width: '6rem', height: '6rem', borderRadius: '100%'}} src={this.state.data_detail.foto_profil ? `https://static.schoolmedia.id/public/assets/socmed/${this.props.typeUser}/photo/${( this.state.data_detail.foto_profil )}` : (`${conf.uri_frontend}assets/img/profile-unavailable.png`)}/>
                  </div>
                </div>
              </div>
              <h1 className="text-xl ml-4">{this.state.data_detail.nama ? ( this.state.data_detail.nama ) : ('-')}</h1>
              <div className="p-4">
                <Tabs defaultActiveKey="detail1" id="uncontrolled-tab-example" className="mt-4">
                  <Tab eventKey="detail1" title="Detail 1">
                    <table className="table table-striped">
                      <tr>
                        <td>Token : {this.state.data_detail.token ? ( this.state.data_detail.token ) : ('-')}</td>
                        <td>Agama : {this.state.data_detail.agama ? ( this.state.data_detail.agama ) : ('-')}</td>
                      </tr>
                      <tr>
                        <td>No Pendaftaran : {this.state.data_detail.kode_pendaftaran ? ( this.state.data_detail.kode_pendaftaran ) : ('-')}</td>
                        <td>No. Handphone : {this.state.data_detail.no_handphone ? ( this.state.data_detail.no_handphone ) : ('-')}</td>
                      </tr>
                      <tr>
                        <td>Jenis Kelamin : {this.state.data_detail.jenis_kelamin !== null ?  ( this.state.data_detail.jenis_kelamin !== 1 ? "Perempuan" : "Laki - laki" ) : ('-')}</td>
                        <td>Golongan Darah : {this.state.data_detail.golongan_darah ? ( this.state.data_detail.golongan_darah ) : ('-')}</td>
                      </tr>
                      <tr>
                        <td>Tempat Lahir : {this.state.data_detail.tempat_lahir ? ( this.state.data_detail.tempat_lahir ) : ('-')}</td>
                        {this.props.typeUser !== "SI" ? (<td>Pendidikan Terakhir : {this.state.data_detail.pendidikan_terakhir ? ( this.state.data_detail.pendidikan_terakhir ) : ('-')}</td>) : ""}
                      </tr>
                      <tr>
                        <td>Tanggal Lahir : {this.state.data_detail.tanggal_lahir ? ( this.state.data_detail.tanggal_lahir ) : ('-')}</td>
                        <td>Motto : {this.state.data_detail.motto ? ( this.state.data_detail.motto ) : ('-')}</td>
                      </tr>
                    </table>
                  </Tab>
                  <Tab eventKey="detail2" title="Detail 2">
                    <p className="mt-4">Identitas dan Alamat Asal : </p>
                    <table className="table table-striped">
                      <tr>
                        <td>No. KK : {this.state.data_detail.kk ? ( this.state.data_detail.kk ) : ('-')}</td>
                        <td>Provinsi : {this.state.data_detail.agama ? ( this.state.data_detail.agama ) : ('-')}</td>
                      </tr>
                      <tr>
                        <td>No. NIK : {this.state.data_detail.nik ? ( this.state.data_detail.nik ) : ('-')}</td>
                        <td>Kabupaten / Kota : {this.state.data_detail.no_handphone ? ( this.state.data_detail.no_handphone ) : ('-')}</td>
                      </tr>
                      <tr>
                        <td>No. NPWP : {this.state.data_detail.npwp ? ( this.state.data_detail.npwp ) : ('-')}</td>
                        <td>Kecamatan : {this.state.data_detail.golongan_darah ? ( this.state.data_detail.golongan_darah ) : ('-')}</td>
                      </tr>
                      <tr>
                        <td>No. Paspor : {this.state.data_detail.paspor ? ( this.state.data_detail.paspor ) : ('-')}</td>
                        <td>Desa : {this.state.data_detail.pendidikan_terakhir ? ( this.state.data_detail.pendidikan_terakhir ) : ('-')}</td>
                      </tr>
                      <tr>
                        <td>Negara : {this.state.data_detail.negara ? ( this.state.data_detail.negara ) : ('-')}</td>
                        <td>Kode Pos : {this.state.data_detail.motto ? ( this.state.data_detail.motto ) : ('-')}</td>
                      </tr>
                      <tr>
                        <td>RW / RT : {this.state.data_detail.rw_tinggal ? ( this.state.data_detail.rw_tinggal ) : ('-')} / {this.state.data_detail.rt_tinggal ? ` ${( this.state.data_detail.rt_tinggal )}` : ('-')}</td>
                        <td>Alamat Tinggal : {this.state.data_detail.alamat_tinggal ? ( this.state.data_detail.alamat_tinggal ) : ('-')}</td>
                      </tr>
                    </table>
                    
                    <p className="mt-4">Alamat Domisili : </p>
                    <table className="table table-striped mt-2">
                      <tr>
                        <td>Provinsi : {this.state.data_detail.provinsi_domisili ? ( this.state.data_detail.provinsi_domisili ) : ('-')}</td>
                        <td>Kabupaten / Kota : {this.state.data_detail.kabupaten_domisili ? ( this.state.data_detail.kabupaten_domisili ) : ('-')}</td>
                      </tr>
                      <tr>
                        <td>Kecamatan : {this.state.data_detail.kecamatan_domisili ? ( this.state.data_detail.kecamatan_domisili ) : ('-')}</td>
                        <td>Desa : {this.state.data_detail.desa_domisili ? ( this.state.data_detail.desa_domisili ) : ('-')}</td>
                      </tr>
                      <tr>
                        <td>Kode Pos : {this.state.data_detail.kode_pos__domisili ? ( this.state.data_detail.kode_pos__domisili ) : ('-')}</td>
                        <td>RW / RT : {this.state.data_detail.rw_domisili ? ( this.state.data_detail.rw_domisili ) : ('-')} / {this.state.data_detail.rt_domisili ? ` ${( this.state.data_detail.rt_domisili )}` : ('-')}</td>
                      </tr>
                      <tr>
                        <td>Alamat : {this.state.data_detail.alamat_domisili ? ( this.state.data_detail.alamat_domisili ) : ('-')}</td>
                      </tr>
                    </table>
                  </Tab>
                </Tabs>
                <div className="row">
                  <div className="col-6">
                    <p className="my-2 ml-2">Foto KTP</p>
                    <img className="img-thumbnail" src={this.state.data_detail.foto_ktp ? `https://static.schoolmedia.id/public/assets/socmed/${this.props.typeUser}/ktp/${( this.state.data_detail.foto_ktp )}` : (`${conf.uri_frontend}assets/img/img-unavailable.png`)}/>
                  </div>
                  <div className="col-6">
                    <p className="my-2 ml-2">Foto KTP & Wajah</p>
                    <img className="img-thumbnail" src={this.state.data_detail.foto_ktp_dan_wajah ? `https://static.schoolmedia.id/public/assets/socmed/${this.props.typeUser}/ktp-face/${( this.state.data_detail.foto_ktp_dan_wajah )}` : (`${conf.uri_frontend}assets/img/img-unavailable.png`)}/>
                  </div>
                  <div className="col-6">
                    <p className="my-2 ml-2">Foto Doc. Pendukung</p>
                    <img className="img-thumbnail" src={this.state.data_detail.foto_doc_pendukung ? `https://static.schoolmedia.id/public/assets/socmed/${this.props.typeUser}/img-support/${( this.state.data_detail.foto_doc_pendukung )}` : (`${conf.uri_frontend}assets/img/img-unavailable.png`)}/>
                  </div>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="p-2">
          <div className="col-12 text-right">
            <button
              className="btn btn-default px-4 py-2 rounded-full items-center"
              type="button"
              style={{ transition: "all .15s ease" }}
              onClick={this.hideModal}
            >
              Tutup
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}