import DataTable from '../../../../partials/table/DataTable';
import React from "react";
import conf from '../../../../../config';

export default class ContentLahan extends React.Component {
  componentWillReceiveProps(nextProps) {
    let q = "";
    if (nextProps.query) {
      q = `&query=${nextProps.query}`;
    }
    this.dt.loading();
    this.dt.fetchEntities(
      `&jenjang_id=${nextProps.jenjang}${q}`
    );
  }
  
  componentDidMount(){
    let q = "";
    if (this.props.query) {
      q = `&query=${this.props.query}`;
    }
    this.dt.loading();
    this.dt.fetchEntities(
      `&jenjang_id=${this.props.jenjang}${q}`
    );
  }
    
  render() {
    return (
      <DataTable
        ref={(dt) => {
          this.dt = dt;
        }}
        url={`${conf.uri_backend}api/v1/referensi/sarpras/lahan`}
        columns={[
          {
            index: "kode_deskripsi_lahan",
            label: "Kode",
          },
          {
            index: "deskripsi_lahan",
            label: "Deskripsi Lahan",
          },
        ]}
      />
    );
  }
}
  