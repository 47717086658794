import DataTable from '../../../partials/table/DataTable';
import React from "react";
import conf from '../../../../config';
import Select from 'react-select';
import axios from 'axios';
import SkeletonFilter from "../../../partials/SkeletonFilter";


export default class ContentLahan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: `${conf.uri_backend}api/admin/v1/sarpras/sarpras/lahan`,
      url_insitutis_slc: `${conf.uri_backend}api/admin/v1/institusi/select_institusi`,
      showModal: false,
      text_sc: "",
      institusi_sc: "",
      dataInstitusi: "",

      display: false,
    };

    this.getInstitusi = this.getInstitusi.bind(this);
    this.filterInstitusi = this.filterInstitusi.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.searchText = this.searchText.bind(this);
  }

  componentDidMount() {
    this.getInstitusi();
    this.dt.loading();
    this.dt.fetchEntities();
  }

  getInstitusi() {
    axios.get(this.state.url_insitutis_slc).then((res) => {
      this.setState({ dataInstitusi: res.data });
    });
  }

  filterInstitusi(e) {
    if (e) {
      this.setState({ institusi_sc: e.value });
    } else {
      this.setState({ institusi_sc: "" });
    }
  }
  btnFilter = () => {
    this.setState({
      display: !this.state.display,
    });
  };
  resetFilter() {
    this.setState(
      {
        institusi_sc: "",
        dataInstitusi: "",
      },
      function () {
        this.getInstitusi();
        this.dt.loading();
        this.dt.resetPage(`
        &query=${this.state.text_sc}
        &institusi=${this.state.institusi_sc}
      `);
      }
    );
  }

  setFilter() {
    this.dt.loading();
    this.dt.resetPage(`
      &query=${this.state.text_sc}
      &institusi=${this.state.institusi_sc}
    `);
  }

  searchText(e) {
    e.preventDefault();
    this.setState({ text_sc: e.target.value }, function () {
      this.dt.loading();
      this.dt.resetPage(`
        &query=${this.state.text_sc}
        &institusi=${this.state.institusi_sc}
      `);
    });
  }

  render() {
    return (
      <div className="col-12 mt-4">
        <div className="input-group input-group-merge input-group-borderless">
          <div className="input-group-prepend">
            <div className="input-group-text">
              <i className="tio-search"></i>
            </div>
          </div>
          <input
            id="datatableSearch"
            onChange={this.searchText}
            type="search"
            className="form-control"
            placeholder="Search Nama Bangunan, Nama Institusi"
            aria-label="Search users"
          />
          <div className="col-auto">
            <div className="d-flex align-items-center">
              {/* <!-- Filter Collapse Trigger --> */}
              <a
                className="btn btn-white dropdown-toggle"
                data-toggle="collapse"
                href="#filterSearchCollapse"
                role="button"
                onClick={()=>this.btnFilter()}
              >
                <i className="tio-filter-outlined mr-1"></i> Filters
              </a>
              {/* <!-- End Filter Collapse Trigger --> */}
            </div>
          </div>
        </div>
        <hr />
        {/* <!-- Filter Search Collapse --> */}
        <div style={{display:this.state.display === false ? "none" : "block"}}>
          <form>
            <div className="row">
              {this.state.dataInstitusi !== "" ? (
                <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
                  <label className="text-sm">Institusi</label>
                  <Select
                    placeholder={"Pilih Institusi"}
                    isClearable={true}
                    options={this.state.dataInstitusi}
                    onChange={this.filterInstitusi}
                  />
                </div>
              ) : (
                <SkeletonFilter />
              )}
            </div>
            {/* <!-- End Row --> */}

            <div className="d-flex justify-content-end">
              <button
                onClick={this.resetFilter}
                type="button"
                className="btn btn-white mr-2"
              >
                Reset
              </button>
              <button
                onClick={this.setFilter}
                type="button"
                className="btn btn-primary"
              >
                Cari
              </button>
            </div>
          </form>
          <hr />
        </div>

        <DataTable
          ref={(dt) => {
            this.dt = dt;
          }}
          action={{
            created_at: (col, data) => {
              if (col.created_at !== null) {
                var date = "";
                var month = "";
                var year = "";
                date = col.created_at.substr(8, 2);
                month = col.created_at.substr(5, 2);
                year = col.created_at.substr(0, 4);

                return (
                  <p className="text-sm">{date + "-" + month + "-" + year}</p>
                );
              } else {
                return <p className="text-sm">Tidak Diketahui</p>;
              }
            },
          }}
          url={this.state.url}
          columns={[
            {
              index: "nama_institusi",
              label: "NAMA Sekolah",
            },
            {
              index: "nama",
              label: "NAMA LAHAN",
            },
            {
              index: "luas_terbuka",
              label: "Luas Terbuka",
            },
            {
              index: "luas_terbangun",
              label: "Luas Terbangun",
            },
            {
              index: "total_luas",
              label: "LUAS",
            },
            {
              index: "keterangan",
              label: "Keterangan Kondisi Lahan",
            },
            {
              index: "kesesuaian",
              label: "Kesesuaian terhadap standar (%)",
            },
            {
              index: "created_at",
              label: "Dibuat Tanggal",
            },
          ]}
        />
      </div>
    );
  }
}