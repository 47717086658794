import DataTable from '../../../../partials/table/DataTable';
import React from "react";
import conf from '../../../../../config';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import tokenApi from "../../../../auth/Helpers";
import axios from "axios";

export default class Penggerak extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url        : `${conf.uri_backend}api/admin/v1/referensi/spektrum/`,
      url_corect : `${conf.uri_backend}api/admin/v1/referensi/benar-corect`,
      showModal  : false,
      data_mapel : '',
      id         : '',
      dataI      : '',
      dataII     : '',
      dataIII    : '',
      dataIV     : '',
      dataV      : '',
      dataVI     : '',
      dataVII    : '',
      dataVIII   : '',
      dataIX     : '',
      dataX      : '',
      dataXI     : '',
      dataXII    : '',
      dataKelas  : '',
    };

    this.showDataPerItem    = this.showDataPerItem.bind(this);
    this.showData    = this.showData.bind(this);
    this.setShowModal = this.setShowModal.bind(this);
    this.benar = this.benar.bind(this);
    this.salah = this.salah.bind(this);
    this.checkcorect = this.checkcorect.bind(this);
  }

  componentDidMount() {
    let q = "";
    if (this.props.query) {
      q = `&query=${this.props.query}`;
    }
    var jenjang= this.props.jenjang;
    this.dt.loading();
    this.dt.fetchEntities(
      `&jenjang_id=${jenjang}${q}&status=Penggerak`
    );
  }
  
  componentWillReceiveProps(nextProps) {
    let q = "";
    if (nextProps.query) {
      q = `&query=${nextProps.query}`;
    }
    var jenjang = this.props.jenjang;
    this.dt.loading();
    this.dt.fetchEntities(
      `&jenjang_id=${jenjang}${q}&status=prototipe`
    );
  }

  showData(){
    
    if(this.state.id !== ''){

      return(
        <Tabs defaultActiveKey={this.props.kelas} id="uncontrolled-tab-example">
          {this.state.dataI !== '' ? (this.showTab(this.state.dataI, "I")) : ''}
          {this.state.dataII !== '' ? (this.showTab(this.state.dataII, "II")) : ''}
          {this.state.dataIII !== '' ? (this.showTab(this.state.dataIII, "III")) : ''}
          {this.state.dataIV !== '' ? (this.showTab(this.state.dataIV, "IV")) : ''}
          {this.state.dataV !== '' ? (this.showTab(this.state.dataV, "V")) : ''}
          {this.state.dataVI !== '' ? (this.showTab(this.state.dataVI, "VI")) : ''}
          {this.state.dataVII !== '' ? (this.showTab(this.state.dataVII, "VII")) : ''}
          {this.state.dataVIII !== '' ? (this.showTab(this.state.dataVIII, "VIII")) : ''}
          {this.state.dataIX !== '' ? (this.showTab(this.state.dataIX, "IX")) : ''}
          {this.state.dataX !== '' ? (this.showTab(this.state.dataX, "X")) : ''}
          {this.state.dataXI !== '' ? (this.showTab(this.state.dataXI, "XI")) : ''}
          {this.state.dataXII !== '' ? (this.showTab(this.state.dataXII, "XII")) : ''}
        </Tabs>
      )
      
    }
  }

  showDataPerItem(kelas, id){
    axios.get(`${this.state.url}${id}/detail?kelas=${kelas}&status=prototipe`, {
      headers: {
        Authorization: tokenApi
      }
    }).then(res => {
      if(kelas === "I" ? this.setState({dataI : res.data}) : "");
      if(kelas === "II" ? this.setState({dataII : res.data}) : "");
      if(kelas === "III" ? this.setState({dataIII : res.data}) : "");
      if(kelas === "IV" ? this.setState({dataIV : res.data}) : "");
      if(kelas === "V" ? this.setState({dataV : res.data}) : "");
      if(kelas === "VI" ? this.setState({dataVI : res.data}) : "");
      if(kelas === "VII" ? this.setState({dataVII : res.data}) : "");
      if(kelas === "VIII" ? this.setState({dataVIII : res.data}) : "");
      if(kelas === "IX" ? this.setState({dataIX : res.data}) : "");
      if(kelas === "X" ? this.setState({dataX : res.data}) : "");
      if(kelas === "XI" ? this.setState({dataXI : res.data}) : "");
      if(kelas === "XII" ? this.setState({dataXII : res.data}) : "");
    });
  }

    salah(kelas){
      axios.post(this.state.url_corect, {status:'benar'}).then(function(res){

      });
    }

    benar(kelas){


     
    

      var corect = document.getElementById('corect').elements.namedItem(''+kelas+'-mapel[]');



      var datacorect = [];

      for (var i = 0; i < corect.length; i++) {
        if (corect[i].checked === true) {
          datacorect.push(corect[i].value);
        }
      }

     

      axios.post(this.state.url_corect,   {status:'salah', data:datacorect}).then(function(res){

      });
    }

    checkcorect(kelas){


        this.setState({dataKelas: kelas});

      var corectccc = document.getElementById(''+kelas+'-checkcorect').checked;

      if (corectccc === true) {

        var corect = document.getElementById('corect').elements.namedItem(''+kelas+'-mapel[]');

        for (var i = 0; i < corect.length; i++) {

            corect[i].checked = true;
        } 

      }else{
           var corect = document.getElementById('corect').elements.namedItem(''+kelas+'-mapel[]');

            for (var i = 0; i < corect.length; i++) {

                corect[i].checked = false;
            } 
        }
    }



  showTab(data, kelas){
   
    if(this.props.jenjang === 2 || this.props.jenjang === 3){
      return(
        <Tab className="text-uppercase" eventKey={kelas} title={kelas}>
          <div className="col-12">
           
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">KODE</th>
                  <th scope="col">KMP</th>
                  <th scope="col">KODE</th>
                  <th scope="col">JKMP</th>
                  <th scope="col">KODE</th>
                  <th scope="col">MATA PELAJARAN</th>
                  <th scope="col">INDEKS MPK</th>
                  <th scope="col">KUOTA SEMESTER 1</th>
                  <th scope="col">KUOTA SEMESTER 2</th>
                  <th scope="col">Hapus <input type="checkbox" onChange={this.checkcorect.bind(this, kelas)} id={kelas + "-checkcorect"} />   </th>
                  <th scope="col">status </th>

                </tr>
              </thead>
              <tbody>
                {data.map((peminatan, index) => {
                  return(
                    <tr>
                      <td>{peminatan.kode_kmp}</td>
                      <td>{peminatan.kmp}</td>
                      <td>{peminatan.kode_jkmp}</td>
                      <td>{peminatan.jkmp}</td>
                      <td>{peminatan.kode_mp}</td>
                      <td>{peminatan.mp}</td>
                      <td>{peminatan.indeks_mpk}</td>
                      <td>{peminatan.semester_1}</td>
                      <td>{peminatan.semester_2}</td>
                      <td><input type="checkbox" name={kelas+"-mapel[]"} value={peminatan.id_mp}/></td>
                      <td>{peminatan.status_corect === 1 ? "draf": "active"}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </Tab>
      )
    } else {
      return(
        <Tab className="text-uppercase" eventKey={kelas} title={kelas}>
          <div className="col-12">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">KODE</th>
                  <th scope="col">KMP</th>
                  <th scope="col">KODE</th>
                  <th scope="col">JKMP</th>
                  <th scope="col">KODE</th>
                  <th scope="col">MATA PELAJARAN</th>
                  <th scope="col">INDEKS MPK</th>
                  <th scope="col">KUOTA SEMESTER 1</th>
                  <th scope="col">KUOTA SEMESTER 2</th>
                  <th scope="col">Check <input type="checkbox" onChange={this.checkcorect.bind(this, kelas)} id={kelas + "-checkcorect"} /></th>
                   <th scope="col">status </th>
                </tr>
              </thead>
              <tbody>
                {data.map((peminatan, index) => {
                  return(
                    <tr>
                      <td>{peminatan.kode_kmp}</td>
                      <td>{peminatan.kmp}</td>
                      <td>{peminatan.kode_jkmp}</td>
                      <td>{peminatan.jkmp}</td>
                      <td>{peminatan.kode_mp}</td>
                      <td>{peminatan.mp}</td>
                      <td>{peminatan.indeks_mpk}</td>
                      <td>{peminatan.semester_1}</td>
                      <td>{peminatan.semester_2}</td>
                      <td><input type="checkbox" name={kelas+"-mapel[]"} value={peminatan.id_mp}/></td>
                      <td>{peminatan.status_corect === 1 ? "draf": "active"}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <hr/>
           
          </div>
        </Tab>
      )
    }
  }

  setShowModal(show, id) {
    if(show === true){
      this.setState({
        id         : id,
        showModal  : show
      }, function(){
        if(this.props.jenjang === 2){
          this.showDataPerItem("I", this.state.id)
          this.showDataPerItem("II", this.state.id)
          this.showDataPerItem("III", this.state.id)
          this.showDataPerItem("IV", this.state.id)
          this.showDataPerItem("V", this.state.id)
          this.showDataPerItem("VI", this.state.id)
        } else if(this.props.jenjang === 3){
          this.showDataPerItem("VII", this.state.id)
          this.showDataPerItem("VIII", this.state.id)
          this.showDataPerItem("IX", this.state.id)
        } else if(this.props.jenjang === 4){
          this.showDataPerItem("X", this.state.id)
          this.showDataPerItem("XI", this.state.id)
          this.showDataPerItem("XII", this.state.id)
        }
      });
    } else {
      this.setState({ 
        id         : '',
        showModal  : show,
        dataI      : '',
        dataII     : '',
        dataIII    : '',
        dataIV     : '',
        dataV      : '',
        dataVI     : '',
        dataVII    : '',
        dataVIII   : '',
        dataIX     : '',
        dataX      : '',
        dataXI     : '',
        dataXII    : '',
      });
    }
  }
  
  render() {
    return (
      <div className="mt-4">
        <Modal
          size="xl"
          show={this.state.showModal}
          onHide={() => this.setShowModal(false)}
        >
          <Modal.Header>
            <div className="col-12 p-2 mx-2">
              Daftar Mata Pelajaran
            </div>
          </Modal.Header>
          <Modal.Body>
            

            
            <form id="corect">
            
            {this.showData()}
            </form>
          </Modal.Body>
          <Modal.Footer>
            <div className="col-12 text-right">
                <button className="btn btn-success" onClick={this.benar.bind(this, this.state.dataKelas)}>hapus</button>
                <button className="btn btn-success" onClick={this.salah.bind(this, this.state.dataKelas)}>benar</button>
              <button
                className="btn btn-default px-4 py-2 rounded-full items-center"
                type="button"
                style={{ transition: "all .15s ease" }}
                onClick={() => this.setShowModal(false)}
              >
                Tutup
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <DataTable
          ref={(dt) => {
            this.dt = dt;
          }}
          action={{
            detail:(col, data) => {
              return (
                <div className="text-gray-700 px-4 py-1 flex items-center">
                  <button onClick={this.setShowModal.bind(this, true, data.id)} className="btn btn-sm btn-outline-danger" >
                    Detail
                  </button> 
                </div>
              );
            }
          }}
          url={`${conf.uri_backend}api/admin/v1/referensi/spektrum`}
          columns={[
            {
              index: "nama_spektrum",
              label: "Program Peminatan",
            },
            {
              index: "kode_spektrum",
              label: "Kode",
            },
            {
              index: "lama_belajar",
              label: "Masa Studi",
            },
            {
              index: "detail",
              label: "Aksi",
            },
          ]}
        />
      </div>
    );
  }
}