import DataTable from "../../../partials/table/DataTable";
import React from "react";
import conf from "../../../../config";
import DetailUser from "./DetailUser";
import Select from "react-select";
import axios from "axios";
import SkeletonFilter from "../../../partials/SkeletonFilter";
import { Link, Redirect, withRouter } from "react-router-dom";

import tokenApi from "../../../auth/Helpers";
import SweetAlert from "sweetalert2-react";

import { Modal } from "react-bootstrap";

export default class ContentGuru extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: `${conf.uri_backend}api/admin/v1/user/user/PU`,
      select_count: `${conf.uri_backend}api/admin/v1/user/select_count`,
      user_count: `${conf.uri_backend}api/admin/v1/user/count-user?type=PU`,
      user_count_aktivasi: `${conf.uri_backend}api/admin/v1/user/count-activasi?type=PU`,
      delete: `${conf.uri_backend}api/admin/v1/user/delete`,
      showModal: false,
      data: "",
      institusi_sc: "",
      text_sc: "",
      dataInstitusi: "",
      total: "",
      total_activasi: "",
      show_alert: false,
      title_alert: "",
      message_alert: "",
      icon_alert: "",
      display: false,
      
    };
    this.getInstitusi = this.getInstitusi.bind(this);
    this.filterInstitusi = this.filterInstitusi.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.searchText = this.searchText.bind(this);
    this.detailShow = this.detailShow.bind(this);
    this.hideModal = this.hideModal.bind(this);

    this.delete = this.delete.bind(this);
    this.hideModalDel = this.hideModalDel.bind(this);
  }

  componentDidMount() {
    this.getInstitusi();
    this.dt.loading();
    this.dt.fetchEntities();
    axios.get(this.state.user_count).then((res) => {
      this.setState({ total: res.data });
    });

    axios.get(this.state.user_count_aktivasi).then((res) => {
      this.setState({ total_activasi: res.data });
    });
  }
  btnFilter = () => {
    this.setState({
      display: !this.state.display,
    });
  };

  getInstitusi() {
    axios.get(this.state.select_count).then((res) => {
      console.log(res.data);
      this.setState({ dataInstitusi: res.data });
    });
  }

  filterInstitusi(e) {
    if (e) {
      this.setState({ institusi_sc: e.value });
    } else {
      this.setState({ institusi_sc: "" });
    }
  }

  modalDelete(data) {
    console.log(data);
    this.setState(
      {
        modal_delete: true,
        delete_id: data.id,
      },
      function () {
        console.log(this.state.delete_id);
      }
    );
  }

  hideModalDel() {
    this.setState({
      modal_delete: false,
    });
  }

  delete(){
    // console.log(data);
    axios.post(this.state.delete, { type: "PU", id: this.state.delete_id })
      .then((res) => {
        this.setState({
          show_alert: true,
          title_alert: "Berhasil",
          icon_alert: "success",
          message_alert: `Berhasil Menghapus`,
        });
        this.hideModalDel();
        this.dt.loading();
        this.dt.resetPage();
      })
      .catch((err) => {
        if (err.response.status == 422) {
          this.setState({
            show_alert: true,
            title_alert: "Gagal",
            icon_alert: "error",
            message_alert: err.response.data.messages[0],
          });
        }
      });
  }


  resetFilter() {
    this.setState(
      {
        institusi_sc: "",
        dataInstitusi: "",
      },
      function () {
        this.getInstitusi();
        this.dt.loading();
        this.dt.resetPage(`
        &query=${this.state.text_sc}
        &jumlah=${this.state.institusi_sc}
      `);
      }
    );
  }

  setFilter() {
    this.dt.loading();
    this.dt.resetPage(`
      &query=${this.state.text_sc}
      &jumlah=${this.state.institusi_sc}
    `);
  }

  searchText(e) {
    e.preventDefault();
    this.setState({ text_sc: e.target.value }, function () {
      this.dt.loading();
      this.dt.resetPage(`
        &query=${this.state.text_sc}
        &jumlah=${this.state.institusi_sc}
      `);
    });
  }

  detailShow(id) {
    this.setState({ id_detail: id });
  }

  hideModal(status) {
    if (status) {
      this.setState({
        loadingModal: false,
        id_detail: "",
      });
    }
  }

  render() {
    return (

      <>

      {/* alert */}
      <SweetAlert
            show={this.state.show_alert}
            title={this.state.title_alert}
            text={this.state.message_alert}
            type={this.state.icon_alert}
            onConfirm={() => this.setState({ show_alert: false })}
          />
          <Modal show={this.state.modal_delete}>
            <Modal.Header className="mx-auto">Hapus Data Guru</Modal.Header>
            <Modal.Body className="mx-auto">
              <div className="flex flex-col py-4 mx-auto w-full">
                <span
                  className="bx bx-error text-red-700 text-center"
                  style={{ fontSize: "3.5rem" }}
                ></span>
                <h2 className="text-lg">
                  Hapus data dan akun {this.state.delete_data} ?
                </h2>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="text-gray-700 px-4 py-1 flex items-center">
                <button class="btn btn-sm mr-1" onClick={this.hideModalDel}>
                  Batal
                </button>
                <button
                  onClick={this.delete}
                  class="btn btn-sm btn-outline-danger"
                >
                  <i class="tio-delete-outlined"></i> Hapus
                </button>
              </div>
            </Modal.Footer>
          </Modal>

     
      <div className="col-12 mt-4">
        <div className="input-group input-group-merge input-group-borderless">
          <div className="input-group-prepend">
            <div className="input-group-text">
              <i className="tio-search"></i>
            </div>
          </div>
          <input
            id="datatableSearch"
            onChange={this.searchText}
            type="search"
            className="form-control"
            placeholder="Cari Nama Publik, Nama Sekolah"
            aria-label="Search users"
          />
          <div className="col-auto">
            <div className="d-flex align-items-center">
              {/* <!-- Filter Collapse Trigger --> */}
              <a
                className="btn btn-white dropdown-toggle"
                data-toggle="collapse"
                href="#filterSearchCollapse"
                role="button"
                onClick={() => this.btnFilter()}
              >
                <i className="tio-filter-outlined mr-1"></i> Filters
              </a>
              {/* <!-- End Filter Collapse Trigger --> */}
            </div>
          </div>
        </div>

        <hr />

        {/* <!-- Filter Search Collapse --> */}
        <div
          style={{ display: this.state.display === false ? "none" : "block" }}
        >
          <form>
            <div className="row">
              {this.state.dataInstitusi !== "" ? (
                <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
                  <label className="text-sm">Jumlah</label>
                  <Select
                    placeholder={"10"}
                    isClearable={true}
                    options={this.state.dataInstitusi}
                    onChange={this.filterInstitusi}
                  />
                </div>
              ) : (
                <SkeletonFilter />
              )}
            </div>

            {/* <!-- End Row --> */}

            <div className="d-flex justify-content-end">
              <button
                onClick={this.resetFilter}
                type="button"
                className="btn btn-white mr-2"
              >
                Reset
              </button>
              <button
                onClick={this.setFilter}
                type="button"
                className="btn btn-primary"
              >
                Cari
              </button>
            </div>
          </form>
          <hr />
        </div>

        {this.state.id_detail !== "" ? (
          <DetailUser
            id={this.state.id_detail}
            typeUser={"GU"}
            showMode={true}
            hideModal={this.hideModal}
          />
        ) : (
          ""
        )}

        <DataTable
          ref={(dt) => {
            this.dt = dt;
          }}
          action={{
            created_at: (col, data) => {
              if (col.created_at !== null) {
                var date = "";
                var month = "";
                var year = "";
                date = col.created_at.substr(8, 2);
                month = col.created_at.substr(5, 2);
                year = col.created_at.substr(0, 4);

                return (
                  <p className="text-sm">{date + "-" + month + "-" + year}</p>
                );
              } else {
                return <p className="text-sm">Tidak Diketahui</p>;
              }
            },

            tanggal_lahir: (col, data) => {
              if (col.tanggal_lahir !== null) {
                var date = "";
                var month = "";
                var year = "";
                date = col.tanggal_lahir.substr(8, 2);
                month = col.tanggal_lahir.substr(5, 2);
                year = col.tanggal_lahir.substr(0, 4);

                return (
                  <p className="text-sm">{date + "-" + month + "-" + year}</p>
                );
              } else {
                return <p className="text-sm">Tidak Diketahui</p>;
              }
            },

            status: (col, data) => {
              var statusUsr = "";
              var tooltip = "";
              var warna = "";
              var ikon = "";
              var width = "";

              if (col.status === "Active") {
                statusUsr = "Aktif";
                tooltip = "User sudah teraktivasi";
                warna = "bg-green-600";
                ikon = "bx-check";
                width = "w-16";
              } else if (col.status === "Non-Active") {
                statusUsr = "Non-Aktif";
                tooltip = "User telah dinon-aktifkan";
                warna = "bg-red-600";
                ikon = "bx-x";
                width = "w-auto";
              } else {
                statusUsr = "Pending";
                tooltip =
                  "User belum aktivasi di SSO dan klik link konfirmasi di email";
                warna = "bg-gray-600";
                ikon = "bx-error";
                width = "w-20";
              }
              return statusUsr;
            },

            jenis_kelamin: (col, data) => {
              var jekel = "";
              if (col.jenis_kelamin === 1) {
                jekel = "Laki - Laki";
              }

              if (col.jenis_kelamin === 0) {
                jekel = "Perempuan";
              }

              if (col.jenis_kelamin === null) {
                jekel = "Belum diketahui";
              }
              return <p className="text-sm">{jekel}</p>;
            },

            aksi: (col, data) => {
              return (
                <button
                  className="btn btn-sm btn-white"
                  onClick={this.detailShow.bind(this, data.id)}
                >
                  <i className="tio-book-outlined"></i> Detail
                </button>
              );
            },

            edit: (col, data) => {
              return (
                <div className="text-gray-700 px-4 py-1 flex items-center">
                  <Link to={`/user/edit/${data.id}/PU`} className="btn btn-sm btn-white">
                    <i className="tio-book-outlined"></i> Edit
                  </Link>
                  <button
                    onClick={this.modalDelete.bind(this, data)}
                    className="btn btn-sm btn-outline-danger"
                  >
                    <i className="tio-delete-outlined"></i> Hapus
                  </button>
                </div>
              );
            },
          }}
          url={this.state.url}
          columns={[
            {
              index: "no_index",
              label: "No",
            },
            {
              index: "nama",
              label: "NAMA",
            },
            {
              index: "username",
              label: "USERNAME",
            },
            {
              index: "jenis_kelamin",
              label: "JENIS KELAMIN",
            },
            {
              index: "tanggal_lahir",
              label: "Tanggal Lahir",
            },
            {
              index: "created_at",
              label: "TANGGAL PENDAFTARAN",
            },
            {
              index: "status",
              label: "STATUS",
            },
            {
              index: "edit",
              label: "Edit",
            },
            {
              index: "aksi",
              label: "Detail",
            },
          ]}
        />
      </div>
      </>
    );
  }
}
