import React from "react";
import ContentS from "./ContentSd";
import ContentSmk from "./ContentSmk";
import ContentPenggerak from "./Penggerak";

export default class ContentBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active_menu: '',
    };
  }

  componentDidMount(){
    this.setState({ active_menu: this.props.id });
  }

  render() {
    return (
      <div>
        {this.renderContent()}
      </div>
    );
  }

  renderContent() {
    if (this.state.active_menu === "sd") {
      return <ContentS  jenjang="2" kelas="I" query={this.state.query}/>;
    }

    if (this.state.active_menu === "smp") {
      return <ContentS  jenjang="3" kelas="VII" query={this.state.query}/>;
    }

    if (this.state.active_menu === "sma") {
      return <ContentS  jenjang="4" kelas="X" query={this.state.query}/>;
    }

    if (this.state.active_menu === "smk") {
      return <ContentSmk jenjang="5" kelas="X"  query={this.state.query}/>;
    }

     if (this.state.active_menu === "penggeraksd") {
      return <ContentPenggerak jenjang="2" kelas="I"  query={this.state.query}/>;
    }

     if (this.state.active_menu === "penggeraksmp") {
      return <ContentPenggerak jenjang="3" kelas="VII"  query={this.state.query}/>;
    }

     if (this.state.active_menu === "penggeraksma") {
      return <ContentPenggerak jenjang="4" kelas="X"  query={this.state.query}/>;
    }

    if (this.state.active_menu == "penggeraksma") {
      return <ContentPenggerak jenjang="5" kelas="X"  query={this.state.query}/>;
    }


  }
}
