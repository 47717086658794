import React from 'react';
import conf from '../../../../config';
import SweetAlert from 'sweetalert2-react';
import { Link } from "react-router-dom";
import ContentBase from "./KdItems/ContentBase.jsx";
import Skeleton from 'react-loading-skeleton';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

export default class KompetensiDasar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      kelas: [
        {
          label: "SD / MI",
          value: 2,
          slug: "sd",
        },
        {
          label: "SMP / MTS",
          value: 3,
          slug: "smp",
        },
        {
          label: "SMA / MA",
          value: 4,
          slug: "sma",
        },
        {
          label: "SMK / MAK",
          value: 5,
          slug: "smk",
        },
      ],

      // alert
      show_alert: false,
      title_alert: "",
      message_alert: "",
      icon_alert: "",

      active_class: this.props.match.params.jenjangLabel,
    };

    this.checkLogin = this.checkLogin.bind(this);
  }

  componentDidMount() {
    this.checkLogin();
  }

  checkLogin() {
    if (!localStorage.hasOwnProperty("token")) {
      localStorage.setItem("is_login", "");
      localStorage.setItem("token", "");
    }
    if (localStorage.getItem("token") === "") {
      window.location.replace(conf.uri_frontend + "admin_login");
    }
  }

  setActiveClass(v) {
    this.setState(
      {
        active_class: "",
      },
      function () {
        this.setState({ active_class: v.slug });
      }
    );
  }
  openModal() {
    this.setState({
      modalShow: true,
    });
  }
  closeModal = () => {
    this.setState({
      modalShow: false,
    });
  };
  showKelas() {
    let c = this.state.kelas.map((v) => {
      if (this.state.active_class === v.slug) {
        return (
          <li className="nav-item">
            <span className="nav-link active">{v.label}</span>
          </li>
        );
      } else {
        return (
          <li className="nav-item">
            <Link
              to={`/referensi/kompetensi_dasar/${v.slug}`}
              className="nav-link"
              onClick={() => {
                this.setActiveClass(v);
              }}
            >
              {v.label}
            </Link>
          </li>
        );
      }
    });
    return <ul className="nav nav-pills">{c}</ul>;
  }

  render() {
    return (
      <>
        <main className="main" id="content" role="main">
          {/* alert */}
          <SweetAlert
            show={this.state.show_alert}
            title={this.state.title_alert}
            text={this.state.message_alert}
            type={this.state.icon_alert}
            onConfirm={() => this.setState({ show_alert: false })}
          />

          <div className="content container-fluid">
            {/* <!-- Page Header --> */}
            <div className="page-header">
              <div className="row align-items-end mb-3">
                <div className="col-sm mb-2 mb-sm-0">
                  <nav aria-label="breadcrumb"></nav>

                  <h1 className="page-header-title">Kompetensi Dasar</h1>
                </div>
              </div>
            </div>
            {/* <!-- End Page Header --> */}

            <Modal
              show={this.state.modalShow}
              dialogClassName="modal-lg"
              onHide={this.closeModal}
            >
              <Modal.Header>
                <Modal.Title>Insert Kompetensi Dasar</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-sm-6">
                    <label htmlFor="">Jenjang</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Jenjang"
                    />
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="">Kelompok Mapel</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Kelompok Mapel"
                    />
                  </div>
                  <div className="col-sm-6 mt-3">
                    <label htmlFor="">Jenis Kelompok Mapel</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Jenis Kelompok Mapel"
                    />
                  </div>
                  <div className="col-sm-6 mt-3">
                    <label htmlFor="">Mata Pelajaran</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Mata Pelajaran"
                    />
                  </div>
                  <div className="col-sm-6 mt-3">
                    <label htmlFor="">Kelas</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Kelas"
                    />
                  </div>
                  <div className="col-sm-3 mt-3">
                    <label htmlFor="">KI</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="KI"
                    />
                  </div>
                  <div className="col-sm-3 mt-3">
                    <label htmlFor="">KD</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="KD"
                    />
                  </div>
                  <div className="col-sm-6 mt-3">
                    <label htmlFor="">Rincian Kompetensi Dasar</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Rincian Kompetensi Dasar"
                    />
                  </div>
                  <div className="col-sm-6 mt-3">
                    <label htmlFor="">Indeks KD</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Indeks KD"
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.closeModal}>
                  Close
                </Button>
                <Button variant="primary" onClick={this.btnSave}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>

            <div className="card">
              {/* <!-- Header --> */}
              <div className="card-header">
                <div className="row justify-content-between align-items-center flex-grow-1">
                  <div className="col-12 col-md">
                    <ul className="nav nav-segment" id="expensesTab" role="tablist">
                      {this.showKelas()}
                    </ul>
                    <button
                      onClick={(e) => this.openModal()}
                      className="btn btn-primary float-right"
                    >
                      Tambah Kompetensi Dasar
                    </button>
                    <hr />
                    {this.state.active_class === "" ? (
                      <>
                        <div className="mb-6 mt-6">
                          <div className="flex flex-row mb-2 mt-2 px-4">
                            <div className="w-1/3 px-2">
                              {" "}
                              <Skeleton
                                height={20}
                                className="bg-gray-400"
                              />{" "}
                            </div>
                            <div className="w-1/3 px-2">
                              {" "}
                              <Skeleton
                                height={20}
                                className="bg-gray-400"
                              />{" "}
                            </div>
                            <div className="w-1/3 px-2">
                              {" "}
                              <Skeleton
                                height={20}
                                className="bg-gray-400"
                              />{" "}
                            </div>
                          </div>
                          <div className="flex flex-row mb-2 mt-2 px-4">
                            <div className="w-1/3 px-2">
                              {" "}
                              <Skeleton height={20} />{" "}
                            </div>
                            <div className="w-1/3 px-2">
                              {" "}
                              <Skeleton height={20} />{" "}
                            </div>
                            <div className="w-1/3 px-2">
                              {" "}
                              <Skeleton height={20} />{" "}
                            </div>
                          </div>
                          <div className="flex flex-row mb-2 mt-2 px-4">
                            <div className="w-1/3 px-2">
                              {" "}
                              <Skeleton
                                height={20}
                                className="bg-gray-400"
                              />{" "}
                            </div>
                            <div className="w-1/3 px-2">
                              {" "}
                              <Skeleton
                                height={20}
                                className="bg-gray-400"
                              />{" "}
                            </div>
                            <div className="w-1/3 px-2">
                              {" "}
                              <Skeleton
                                height={20}
                                className="bg-gray-400"
                              />{" "}
                            </div>
                          </div>
                          <div className="flex flex-row mb-2 mt-2 px-4">
                            <div className="w-1/3 px-2">
                              {" "}
                              <Skeleton height={20} />{" "}
                            </div>
                            <div className="w-1/3 px-2">
                              {" "}
                              <Skeleton height={20} />{" "}
                            </div>
                            <div className="w-1/3 px-2">
                              {" "}
                              <Skeleton height={20} />{" "}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <ContentBase id={this.state.active_class} />
                    )}
                  </div>
                </div>
              </div>
              {/* <!-- End Header --> */}
            </div>
          </div>
        </main>
      </>
    );
  }
}