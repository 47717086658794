import React from "react";
import { Modal } from "react-bootstrap";
import ContentBangunan from "./ContentBangunan";
import ContentLahan from "./ContentLahan";
import ContentRuang from "./ContentRuang";
import { Button } from "react-bootstrap";
import Select from "react-select";
export default class ContentBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      itemsprasarana: 1,
      itemssarana: 1,
      menu: [
        {
          label: "Lahan",
          value: "lahan",
        },
        {
          label: "Bangunan",
          value: "bangunan",
        },
        {
          label: "Ruang",
          value: "ruang",
        },
      ],
      jenjang: [
        {
          label: "SD / MI",
          value: 2,
          slug: "sd",
        },
        {
          label: "SMP / MTS",
          value: 3,
          slug: "smp",
        },
        {
          label: "SMA / MA",
          value: 4,
          slug: "sma",
        },
        {
          label: "SMK / MAK",
          value: 5,
          slug: "smk",
        },
      ],
      active_menu: "lahan",
      query: null,
    };
  }

  openModal() {
    this.setState({
      modalShow: true,
    });
  }
  closeModal = () => {
    this.setState({
      modalShow: false,
    });
  };

  addItems = () => {
    this.setState({
      itemsprasarana: this.state.itemsprasarana + 1,
    });
  };

  itemPushPrasarana = () => {
    var itemsprasarana = this.state.itemsprasarana;
    var items = [];
    for (var i = 0; i < itemsprasarana; i++) {
      items.push(
        <>
          <div className="col-sm-3 mt-3">
            <label htmlFor="">Kode </label>
            <input type="text" className="form-control" placeholder="Kode" />
          </div>
          <div className="col-sm-9 mt-3">
            <label htmlFor="">Deskripsi Prasarana </label>
            <input
              type="text"
              className="form-control"
              placeholder="Deskripsi Prasarana"
            />
          </div>
        </>
      );
    }

    return <>{items}</>;
  };

  addItemsSarana = () => {
    this.setState({
      itemssarana: this.state.itemssarana + 1,
    });
  };
  itemPushSarana = () => {
    var itemssarana = this.state.itemssarana;
    var items = [];
    for (var i = 0; i < itemssarana; i++) {
      items.push(
        <>
          <div className="col-sm-4 mt-4">
            <label htmlFor="">Jenis Sarana </label>
            <input
              type="text"
              className="form-control"
              placeholder="Jenis Sarana "
            />
          </div>

          <div className="col-sm-4 mt-4">
            <label htmlFor="">Nama </label>
            <input type="text" className="form-control" placeholder="Nama " />
          </div>
          <div className="col-sm-4 mt-4">
            <label htmlFor="">Rasio </label>
            <input type="text" className="form-control" placeholder="Rasio " />
          </div>
          <div className="col-sm-12 mt-3">
            <label htmlFor="">Deskripsi Sarana </label>
            <textarea
              name=""
              className="form-control"
              placeholder="Deskripsi Sarana"
              id=""
              cols="30"
              rows="10"
            ></textarea>
          </div>
        </>
      );
    }

    return <>{items}</>;
  };

  render() {
    return (
      <div>
        <Modal
          show={this.state.modalShow}
          dialogClassName="modal-lg"
          onHide={this.closeModal}
        >
          <Modal.Header>
            <Modal.Title>Insert {this.state.active_menu}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              {this.state.active_menu !== "ruang" ? (
                <>
                  <div className="col-sm-12">
                    <label htmlFor="">Jenjang</label>
                    <Select isClearable={true} options={this.state.jenjang} />
                  </div>
                  <div className="col-sm-12 mt-3">
                    <label htmlFor="">Kode</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Kode"
                    />
                  </div>
                  <div className="col-sm-12 mt-3">
                    <label htmlFor="">Deskripsi {this.state.active_menu}</label>
                    <textarea
                      name=""
                      className="form-control"
                      id=""
                      cols="30"
                      rows="10"
                    ></textarea>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-sm-12">
                    <label htmlFor="">Jenjang</label>
                    <Select isClearable={true} options={this.state.jenjang} />
                  </div>
                  <div className="col-sm-12 mt-3">
                    <label htmlFor="">Kode</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Kode"
                    />
                  </div>
                  <div className="col-sm-12 mt-3">
                    <label htmlFor="">Kategori Ruang</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Kategori Ruang"
                    />
                  </div>
                  <div className="col-sm-12 mt-3">
                    <label htmlFor="">Jenis Ruang </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Jenis Ruang "
                    />
                  </div>

                  <div className="col-sm-10 mt-4">
                    <h5>Prasarana</h5>
                  </div>
                  <div className="col-sm-2 mt-4">
                    <button onClick={this.addItems} className="btn btn-primary">
                      +
                    </button>
                  </div>
                  {this.itemPushPrasarana()}

                  <div className="col-sm-10 mt-4">
                    <h5>Sarana</h5>
                  </div>
                  <div className="col-sm-2 mt-4">
                    <button
                      onClick={this.addItemsSarana}
                      className="btn btn-primary"
                    >
                      +
                    </button>
                  </div>
                  {this.itemPushSarana()}
                </>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModal}>
              Close
            </Button>
            <Button variant="primary" onClick={this.btnSave}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="flex justify-between">{this.menuData()}</div>
        {this.renderContent()}
      </div>
    );
  }

  renderContent() {
    if (this.state.active_menu === "lahan") {
      return <ContentLahan jenjang={this.props.id} query={this.state.query} />;
    }

    if (this.state.active_menu === "ruang") {
      return <ContentRuang jenjang={this.props.id} query={this.state.query} />;
    }

    if (this.state.active_menu === "bangunan") {
      return (
        <ContentBangunan jenjang={this.props.id} query={this.state.query} />
      );
    }
  }

  setActiveMenu(v) {
    this.setState({
      active_menu: v.value,
    });
  }

  componentWillReceiveProps() {
    this.setState({ active_menu: "lahan" });
  }

  menuData() {
    return (
      <div className="flex items-center mt-4 mb-2 px-4 pb-2">
        {this.state.menu.map((v) => {
          if (this.state.active_menu === v.value) {
            return (
              <>
                <button className="btn btn-primary mr-1">{v.label}</button>
              </>
            );
          } else {
            return (
              <button
                onClick={() => {
                  this.setActiveMenu(v);
                }}
                className="mr-1 btn btn-default"
              >
                {v.label}
              </button>
            );
          }
        })}
        <button
          onClick={() => this.openModal()}
          className="btn btn-primary mr-1 float-right"
        >
          Tambah {this.state.active_menu}
        </button>
      </div>
    );
  }
}
