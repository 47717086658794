
import React from 'react';
import Select from 'react-select';
import conf from '../../../../config';
import tokenApi from "../../../auth/Helpers";
import SweetAlert from 'sweetalert2-react';
import axios from 'axios';

export default class CreateInstitusi extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      url_create          : `${conf.uri_backend}api/admin/v1/institusi`,
      url_get_negara      : `${conf.uri_backend}api/v1/referensi/wilayah/negara?per_page=20`,
      url_get_provinsi    : `${conf.uri_backend}api/v1/referensi/wilayah/provinsi?per_page=20&negara_id=`,
      url_get_kota        : `${conf.uri_backend}api/v1/referensi/wilayah/kota?per_page=20&provinsi_id=`,
      url_get_kecamatan   : `${conf.uri_backend}api/v1/referensi/wilayah/kecamatan?per_page=20&kota_id=`,
      url_get_kelurahan   : `${conf.uri_backend}api/v1/referensi/wilayah/kelurahan?per_page=20&kecamatan_id=`,
      username            : '',
      password            : '',
      email               : '',
      second_email        : '',
      npsn                : '',
      jenjang             : '',
      kode_pos            : '',
      nama_institusi      : '',
      sk_pendirian        : '',
      fax                 : '',
      alamat_1            : '',
      alamat_2            : '',
      bujur               : '',
      lintang             : '',
      status_kepemilikan  : '',
      telp_institusi      : '',
      sk_izin_operasional : '',
      website             : '',
      tagline             : '',
      nama_op             : '',
      tgl_sk_operasional  : '',
      tgl_sk_pendirian    : '',
      rt                  : '',
      rw                  : '',
      logo                : '',
      logo_preview        : '',
      cover               : '',
      cover_preview       : '',
      id_provinsi         : '',
      id_kota             : '',
      id_kecamatan        : '',
      disabled            : false,
      // alert
      show_alert          : false,
      title_alert         : '',
      message_alert       : '',
      icon_alert          : '',
    }

    this.createInstitusi = this.createInstitusi.bind(this);

    // create
    this.getJenjang = this.getJenjang.bind(this);
    this.getStatus = this.getStatus.bind(this);
    this.getNegara = this.getNegara.bind(this);
    this.getProv = this.getProv.bind(this);
    this.getKota = this.getKota.bind(this);
    this.getKec = this.getKec.bind(this);
    this.getDesa = this.getDesa.bind(this);

    // select
    this.selectJenjang = this.selectJenjang.bind(this);
    this.selectStatus = this.selectStatus.bind(this);
    this.selectNegara = this.selectNegara.bind(this);
    this.selectProv = this.selectProv.bind(this);
    this.selectKota = this.selectKota.bind(this);
    this.selectKec = this.selectKec.bind(this);
    this.selectDesa = this.selectDesa.bind(this);

    this.uploadLogo = this.uploadLogo.bind(this);
    this.uploadCover = this.uploadCover.bind(this);
    this.checkLogin = this.checkLogin.bind(this);
  }
  
  componentDidMount(){
    this.checkLogin();
    this.getJenjang();
    this.getStatus();
    this.getNegara();
    
  }
  
  checkLogin(){
    if (!localStorage.hasOwnProperty("token")) {
      localStorage.setItem('is_login',"");
      localStorage.setItem('token',"");
    }
    if(localStorage.getItem("token") === ""){
      window.location.replace(conf.uri_frontend+"admin_login");
    }
  }

  // Bind data
  getJenjang(){
    var jenjang = [
      { value: '1',   label: 'TK'   },
      { value: '2',  label: 'SD/MI'  },
      { value: '3', label: 'SMP/MTS' },
      { value: '4',  label: 'SMA/MA'  },
      { value: '5',   label: 'SMK/MAK'   },
      { value: '6', label: 'PKBM' },
      { value: '7', label: 'SLB' },
    ]
    this.setState({ data_jenjang: jenjang });
  }

  getStatus(){
    var status = [
      { value: 'Negeri',   label: 'Negeri'   },
      { value: 'Swasta',  label: 'Swasta'  },
    ]
    this.setState({ data_status: status });
  }

  getNegara(){
    axios.get(this.state.url_get_negara).then(res=>{
      this.setState({ data_negara: res.data, id_negara: res.value});
    })
  }

  getProv(e){
    axios.get(this.state.url_get_provinsi+e.value).then(res=>{
      this.setState({ data_provinsi: res.data, id_provinsi: res.value});
    })
  }

  getKota(e){
    if(e){
      axios.get(this.state.url_get_kota+e.value).then(res=>{
        this.setState({ data_kota: res.data, id_kota: res.value});
      })
    }
  }

  getKec(e){
    if(e){
      axios.get(this.state.url_get_kecamatan+e.value).then(res=>{
        this.setState({ data_kecamatan: res.data, id_kecamatan: res.value});
      })
    }
  }

  getDesa(e){
    if(e){
      axios.get(this.state.url_get_kelurahan+e.value).then(res=>{
        this.setState({ data_desa: res.data});
      })
    }
  }

  // select
  selectJenjang(e){
    if(e){
      this.setState({jenjang: e.value})
    }else{
      this.setState({jenjang: ''})
    }
  }

  selectStatus(e){
    if(e){
      this.setState({status: e.value})
    }else{
      this.setState({status: ''})
    }
  }

  selectNegara(e){
    if(e){
      this.setState({negara: e.value})
      this.getProv(e);
    }else{
      this.setState({negara: ''})
    }
  }

  selectProv(e){
    if(e){
      this.setState({provinsi: e.value})
      this.getKota(e);
    }else{
      this.setState({provinsi: ''})
    }
  }

  selectKota(e){
    if(e){
      this.setState({kabupaten: e.value})
      this.getKec(e);
    }else{
      this.setState({kabupaten: ''})
    }
  }

  selectKec(e){
    if(e){
      this.setState({kecamatan: e.value})
      this.getDesa(e);
    }else{
      this.setState({kecamatan: ''})
    }
  }
  
  selectDesa(e){
    if(e){
      this.setState({desa: e.value})
    }else{
      this.setState({desa: ''})
    }
  }

  uploadLogo(e) {
    if(e.target.files[0] !== undefined){
      this.setState({ 
        logo: e.target.files[0],
        logo_preview: URL.createObjectURL(e.target.files[0])
      });
    } else {
      this.setState({ 
        logo: '',
        logo_preview: ''
      });
    }
  }

  uploadCover(e) {
    if(e.target.files[0] !== undefined){
      this.setState({ 
        cover: e.target.files[0],
        cover_preview: URL.createObjectURL(e.target.files[0])
      });
    } else {
      this.setState({ 
        cover: '',
        cover_preview: ''
      });
    }
  }

  getDateNow(){
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1;
    var yyyy = today.getFullYear();
    if(dd<10) 
    {
      dd='0'+dd;
    } 
    if(mm<10) 
    {
      mm='0'+mm;
    }
    today = mm+'/'+dd+'/'+yyyy;
    this.setState({date_now: today})
  }

  createInstitusi(event){
    event.preventDefault();
    var formData = new window.FormData();
    this.setState({disabled: true});

    formData.append('logo', this.state.logo);
    formData.append('cover', this.state.cover);

    formData.append('username', this.state.username);
    formData.append('password', this.state.password);
    formData.append('email', this.state.email);
    formData.append('second_email', this.state.second_email);

    formData.append('npsn', this.state.npsn);
    formData.append('m_struktur_jenjang_id', this.state.jenjang);
    formData.append('nama_institusi', this.state.nama_institusi);
    formData.append('status', this.state.status);
    formData.append('telp_institusi', this.state.telp_institusi);
    formData.append('fax', this.state.fax);
    formData.append('website', this.state.website);
    formData.append('tagline', this.state.tagline);
    formData.append('negara', this.state.negara);
    formData.append('provinsi', this.state.provinsi);
    formData.append('kabupaten', this.state.kabupaten);
    formData.append('kecamatan', this.state.kecamatan);
    formData.append('desa', this.state.desa);
    formData.append('kode_pos', this.state.kode_pos);
    formData.append('alamat_1', this.state.alamat_1);
    formData.append('alamat_2', this.state.alamat_2);
    formData.append('rt', this.state.rt);
    formData.append('rw', this.state.rw);
    formData.append('bujur', this.state.bujur);
    formData.append('lintang', this.state.lintang);
    formData.append('status_kepemilikan', this.state.status_kepemilikan);
    formData.append('sk_pendirian', this.state.sk_pendirian);
    formData.append('tgl_sk_pendirian', this.state.tgl_sk_pendirian);
    formData.append('sk_izin_operasional', this.state.sk_izin_operasional);
    formData.append('tgl_sk_izin_operasional', this.state.tgl_sk_operasional);
    // formData.append('created_at', new Date());
    formData.append('created_by', 1);

    axios.post(this.state.url_create, formData, {headers:{
      Authorization:tokenApi,
      'Content-Type': 'multipart/form-data'
    }}).then(res => {
      this.setState({ 
        show_alert    : true,
        title_alert   : "Berhasil",
        icon_alert    : "success",
        message_alert : res.data.messages[0],
        disabled      : false
      })
    }).catch(err => {
      if(err !== null || err !== ''){
        if(err.response.status === 422){
          this.setState({ 
            show_alert    : true,
            title_alert   : "Gagal",
            icon_alert    : "error",
            message_alert : err.response.data.messages[0],
            disabled      : false
          });
        } 
      }
    })
  }

  render(){
    return(
      <>
        <main className="main" id="content" role="main">
          <div className="content container-fluid">
            {/* alert */}
            <SweetAlert
              show={this.state.show_alert}
              title={this.state.title_alert}
              text={this.state.message_alert}
              type={this.state.icon_alert}
              onConfirm={() => this.setState({ show_alert: false })}
            />
            {/* <!-- Page Header --> */}
            <div className="page-header" style={{paddingBottom: 'unset !important'}}>
              <div className="row align-items-end mb-3">
                <div className="col-sm mb-2 mb-sm-0">
                  <nav aria-label="breadcrumb">
                  </nav>

                  <h1 className="page-header-title">Registrasi Sekolah / Institusi</h1>
                </div>

                <div className="col-sm-auto">
                </div>
              </div>
            </div>
            {/* <!-- End Page Header --> */}

            <div className="card mt-4">
              <div className="card-body">
                <form onSubmit={this.createInstitusi}>
                    <div className="container">
                      <h2 className="text-lg text-schoolmedia font-bold mt-3">Data Login</h2>
                      <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                          <label for="teamsFilterLabel" className="input-label">Username</label>
                          <input id="username" type="text" className="form-control" placeholder="Username" required
                            onChange={(event) => {
                              this.setState({username: event.target.value});
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                          <label for="teamsFilterLabel" className="input-label">Password</label>
                          <input id="password" type="password" className="form-control" placeholder="Password" required
                            onChange={(event) => {
                              this.setState({password: event.target.value});
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                          <label for="teamsFilterLabel" className="input-label">Email</label>
                          <input id="email" type="email" className="form-control" placeholder="Email" required
                            onChange={(event) => {
                              this.setState({email: event.target.value});
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                          <label for="teamsFilterLabel" className="input-label">Secondary Email</label>
                          <input id="secondary-email" type="email" className="form-control" placeholder="Secondary Email" 
                            onChange={(event) => {
                              this.setState({second_email: event.target.value});
                            }}
                          />
                        </div>
                      </div>
                      <div className="dropdown-divider"></div>

                      <h2 className="text-lg text-schoolmedia font-bold mt-3">Data 1</h2>
                      <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                          <label for="teamsFilterLabel" className="input-label">NPSN</label>
                          <input id="npsn" type="number" className="form-control" placeholder="NPSN" required
                            onChange={(event) => {
                              this.setState({npsn: event.target.value});
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                          <label for="teamsFilterLabel" className="input-label">Nama Institusi / Sekolah</label>
                          <input id="nama_institusi" type="text" className="form-control" placeholder="Nama Institusi / Sekolah" required
                            onChange={(event) => {
                              this.setState({nama_institusi: event.target.value});
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                          <label for="teamsFilterLabel" className="input-label">FAX</label>
                          <input id="fax" type="number" className="form-control" placeholder="FAX" 
                            onChange={(event) => {
                              this.setState({fax: event.target.value});
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                          <label for="teamsFilterLabel" className="input-label">No Telpon Institusi</label>
                          <input id="telp_institusi" type="number" className="form-control" placeholder="No Telpon Institusi" 
                            onChange={(event) => {
                              this.setState({telp_institusi: event.target.value});
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                          <label for="teamsFilterLabel" className="input-label">Tagline</label>
                          <textarea className="border" name="" id="tagline" cols="30" rows="2" style={{height: '40px', width: '100%'}} required
                            onChange={(event) => {
                              this.setState({tagline: event.target.value});
                            }}
                          ></textarea>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                          <label for="teamsFilterLabel" className="input-label">Jenjang</label>
                          <Select
                            options={this.state.data_jenjang}
                            onChange={this.selectJenjang}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                          <label for="teamsFilterLabel" className="input-label">Status</label>
                          <Select
                            options={this.state.data_status}
                            onChange={this.selectStatus}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                          <label for="teamsFilterLabel" className="input-label">Website</label>
                          <input id="website" type="text" className="form-control" placeholder="Website" required
                            onChange={(event) => {
                              this.setState({website: event.target.value});
                            }}
                          />
                        </div>
                      </div>
                      <div className="dropdown-divider"></div>

                      <h2 className="text-lg text-schoolmedia font-bold mt-3">Data 2</h2>
                      <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                          <label for="teamsFilterLabel" className="input-label">Status Kepemilikan</label>
                          <input id="nama_institusi" type="text" className="form-control" placeholder="Status Kepemilikan" 
                            onChange={(event) => {
                              this.setState({status_kepemilikan: event.target.value});
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                          <label for="teamsFilterLabel" className="input-label">SK Pendirian</label>
                          <input id="sk_pendirian" type="text" className="form-control" placeholder="SK Pendirian" 
                            onChange={(event) => {
                              this.setState({sk_pendirian: event.target.value});
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                          <label for="teamsFilterLabel" className="input-label">Tanggal SK Pendirian</label>
                          <input id="tgl_sk_pendirian" type="date" className="form-control" placeholder="" 
                            onChange={(event) => {
                              this.setState({tgl_sk_pendirian: event.target.value});
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                          <label for="teamsFilterLabel" className="input-label">SK Izin Operasional</label>
                          <input id="sk_pendirian" type="text" className="form-control" placeholder="SK Pendirian" 
                            onChange={(event) => {
                              this.setState({sk_izin_operasional: event.target.value});
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                          <label for="teamsFilterLabel" className="input-label">Tanggal SK Izin Operasional</label>
                          <input id="tgl_sk_operasional" type="date" className="form-control" placeholder="" 
                            onChange={(event) => {
                              this.setState({tgl_sk_operasional: event.target.value});
                            }}
                          />
                        </div>
                      </div>
                      <div className="dropdown-divider"></div>

                      <h2 className="text-lg text-schoolmedia font-bold mt-3">Alamat Institusi</h2>
                      <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                          <label for="teamsFilterLabel" className="input-label">Alamat 1</label>
                          <input id="alamat_1" type="text" className="form-control" placeholder="Alamat 1" required
                            onChange={(event) => {
                              this.setState({alamat_1: event.target.value});
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                          <label for="teamsFilterLabel" className="input-label">Alamat 2</label>
                          <input id="npsn" type="text" className="form-control" placeholder="Alamat 2" 
                            onChange={(event) => {
                              this.setState({alamat_2: event.target.value});
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                          <label for="teamsFilterLabel" className="input-label">Kode Pos</label>
                          <input id="kode_pos" type="number" className="form-control" placeholder="Kode Pos" required
                            onChange={(event) => {
                              this.setState({kode_pos: event.target.value});
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                          <label for="teamsFilterLabel" className="input-label">Bujur</label>
                          <input id="bujur" type="text" className="form-control" placeholder="Bujur" 
                            onChange={(event) => {
                              this.setState({bujur: event.target.value});
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                          <label for="teamsFilterLabel" className="input-label">Lintang</label>
                          <input id="lintang" type="text" className="form-control" placeholder="Lintang" 
                            onChange={(event) => {
                              this.setState({lintang: event.target.value});
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                          <label for="teamsFilterLabel" className="input-label">Negara</label>
                          <Select
                            options={this.state.data_negara}
                            onChange={this.selectNegara}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                          <label for="teamsFilterLabel" className="input-label">Provinsi</label>
                          <Select
                            options={this.state.data_provinsi}
                            onChange={this.selectProv}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                          <label for="teamsFilterLabel" className="input-label">Kota / Kabupaten *</label>
                          <Select
                            options={this.state.data_kota}
                            onChange={this.selectKota}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                          <label for="teamsFilterLabel" className="input-label">Kecamatan</label>
                          <Select
                            options={this.state.data_kecamatan}
                            onChange={this.selectKec}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                          <label for="teamsFilterLabel" className="input-label">Desa / Kelurahan</label>
                          <Select
                            options={this.state.data_desa}
                            onChange={this.selectDesa}
                          />
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-2 mb-2">
                          <label for="teamsFilterLabel" className="input-label">RT</label>
                          <input id="nama_op" type="number" className="form-control" placeholder="Alamat RT"
                            onChange={(event) => {
                              this.setState({rt: event.target.value});
                            }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-3 col-lg-2 mb-2">
                          <label for="teamsFilterLabel" className="input-label">RW</label>
                          <input id="nama_op" type="number" className="form-control" placeholder="Alamat RW"
                            onChange={(event) => {
                              this.setState({rw: event.target.value});
                            }}
                          />
                        </div>
                      </div>
                      <div className="dropdown-divider"></div>

                      <h2 className="text-lg text-schoolmedia font-bold mt-3">Logo Dan Cover</h2>
                      <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                          <label for="teamsFilterLabel" className="input-label">Logo</label>
                          {this.state.logo_preview !== '' ? (<img className="img-thumbnail" src={this.state.logo_preview}/>) : ''}
                          <input id="logo" type="file" className="form-control" placeholder="Logo Sekolah" 
                          onChange={this.uploadLogo}
                          />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                          <label for="teamsFilterLabel" className="input-label">Cover</label>
                          {this.state.cover_preview !== '' ? (<img className="img-thumbnail" src={this.state.cover_preview}/>) : ''}
                          <input id="cover" type="file" className="form-control" placeholder="Cover" 
                            onChange={this.uploadCover}
                          />
                        </div>
                      </div>
                      <div className="dropdown-divider"></div>
                    </div>
                    {/* <!-- End Row --> */}

                    <div className="d-flex justify-content-end">
                      <input type="submit" disabled={this.state.disabled} className="btn btn-primary" value="Simpan"/>
                    </div>
                </form>
              </div>
            </div>
          </div>
        </main>
      </>
    )
  }
}