import { Button, Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import React from "react";
import axios from "axios";
import DataTable from '../../../../partials/table/DataTable';
import SimpleTable from '../../../../partials/table/SimpleTable';
import conf from '../../../../../config';
import tokenApi from "../../../../auth/Helpers";

export default class ContentRuang extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      title: "",
      modal: {
        data: [],
        columns: [],
      },
      data_detail: [],

      show_modal_prasarana: false,
      show_modal_sarana: false,

      url_detail_sarana: '',
    };
  }

  componentDidMount() {
    let q = "";
    if (this.props.query) {
      q = `&query=${this.props.query}`;
    }
    var jenjang= '';
    if(this.props.jenjang === "sd"){
      jenjang = 2;
    }else if(this.props.jenjang === "smp"){
      jenjang = 3;
    } else if(this.props.jenjang === "sma"){
      jenjang = 4;
    } else if(this.props.jenjang === "smk"){
      jenjang = 5;
    } else{
      jenjang = this.props.jenjang;
    }
    this.dt.loading();
    this.dt.fetchEntities(
      `&jenjang_id=${jenjang}&include=m_sarpras_prasaranas,m_sarpras_saranas${q}`
    );
  }

  componentWillReceiveProps(nextProps) {
    let q = "";
    if (nextProps.query) {
      q = `&query=${nextProps.query}`;
    }
    var jenjang= '';
    if(nextProps.jenjang === "sd"){
      jenjang = 2;
    }else{
      jenjang = nextProps.jenjang;
    }
    this.dt.loading();
    this.dt.fetchEntities(
      `&jenjang_id=${jenjang}&include=m_sarpras_prasaranas,m_sarpras_saranas${q}`
    );
  }

  render() {
    return (
      <>
        {this.showDetailModal()}
        <DataTable
          ref={(dtsarana) => {
            this.dt = dtsarana;
          }}
          action={{
            m_sarpras_prasaranas: (col, data) => {
              return (
                <button
                  onClick={this.detailPrasarana.bind(this, data.id)}
                  className="btn btn-warning"
                >
                  <i className="bx bx-show-alt text-sm pr-2"></i> Detail
                </button>
              );
            },
            m_sarpras_saranas: (col, data) => {
              return (
                <button
                  onClick={this.detailSarana.bind(this, data.id)}
                  className="btn btn-warning"
                >
                  <i className="bx bx-show-alt text-sm pr-2"></i> Detail
                </button>
              );
            },
          }}
          url={`${conf.uri_backend}api/v1/referensi/sarpras/ruang`}
          columns={[
            {
              index: "kode_kategori_ruang",
              label: "Kode",
            },
            {
              index: "kategori_ruang",
              label: "Kategori ruang",
            },
            {
              index: "jenis_ruang",
              label: "Jenis Ruang",
            },
            {
              index: "m_sarpras_prasaranas",
              label: "Prasarana",
            },
            {
              index: "m_sarpras_saranas",
              label: "Sarana",
            },
          ]}
        />
      </>
    );
  }

  detailPrasarana(data) {
    this.setState({loading_detail: true})
    axios.get(`${conf.uri_backend}api/v1/referensi/sarpras/ruang/${data}/prasarana`, {
      headers: {
        Authorization: tokenApi
      }
    }).then(res => {
      this.setState({
        data_detail: res.data.data,
        loading_detail: false
      })
    }).catch(err => {
      this.setState({loading_detail: false})
    })
    let modal = {
      columns: [
        {
          index: "kode_prasarana",
          label: "Kode",
          maxWidth: "5rem",
        },
        {
          index: "deskripsi_prasarana",
          label: "Deskripsi Prasarana",
          maxWidth: "100%",
        },
      ],
      // data: data.m_sarpras_prasaranas,
    };
    this.setState({
      title: "Detail Prasarana",
      modal,
    });
    this.setShowModal();
  }

  detailSarana(data) {
    this.setState({loading_detail: true})
    axios.get(`${conf.uri_backend}api/v1/referensi/sarpras/ruang/${data}/sarana`, {
      headers: {
        Authorization: tokenApi
      }
    }).then(res => {
      this.setState({
        data_detail: res.data.data,
        loading_detail: false,
      })
    }).catch(err =>{
      this.setState({loading_detail: false})
    })
    let modal = {
      columns: [
        {
          index: "jenis_sarana",
          label: "Jenis Sarana",
        },
        {
          index: "nama_sarana",
          label: "Nama",
        },
        {
          index: "rasio",
          label: "Rasio",
        },
        {
          index: "deskripsi_sarana",
          label: "Deskripsi Sarana",
        },
      ],
      // data: data.m_sarpras_saranas,
    };
    this.setState({
      title: "Detail Sarana",
      modal,
    });
    // this.dtsarana.fetchEntities();
    this.setShowModal();
  }

  setShowModal(show = true) {
    this.setState({
      showModal: show,
    });
  }

  showDetailModal() {
    if (this.state.showModal) {
      return (
        <>
          <Modal
            size="xl"
            show={this.state.showModal}
            onHide={() => this.setShowModal(false)}
          >
            <Modal.Header>
              <div className="col-12 p-2 mx-2">
                {this.state.title}
                {/* <button
                  className="flex btn-white text-xl text-black px-4 py-1 rounded-full items-center"
                  onClick={() => this.setShowModal(false)}
                >
                  ×
                </button> */}
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="relative px-4 flex-auto max-w-6xl">
                {this.state.loading_detail ? 
                <>
                  <table className="w-full">
                    <thead>
                      <tr>
                        <td><Skeleton height={25}/></td>
                        <td><Skeleton height={25}/></td>
                        <td><Skeleton height={25}/></td>
                        <td><Skeleton height={25}/></td>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td><Skeleton height={25}/></td>
                      <td><Skeleton height={25}/></td>
                      <td><Skeleton height={25}/></td>
                      <td><Skeleton height={25}/></td>
                    </tr>
                    <tr>
                      <td><Skeleton height={25}/></td>
                      <td><Skeleton height={25}/></td>
                      <td><Skeleton height={25}/></td>
                      <td><Skeleton height={25}/></td>
                    </tr>
                    <tr>
                      <td><Skeleton height={25}/></td>
                      <td><Skeleton height={25}/></td>
                      <td><Skeleton height={25}/></td>
                      <td><Skeleton height={25}/></td>
                    </tr>
                    <tr>
                      <td><Skeleton height={25}/></td>
                      <td><Skeleton height={25}/></td>
                      <td><Skeleton height={25}/></td>
                      <td><Skeleton height={25}/></td>
                    </tr>
                    </tbody>
                  </table>
                </> :
                  <SimpleTable
                    columns={this.state.modal.columns}
                    data={this.state.data_detail}
                  />
                }
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="col-12 text-right">
                <button
                  className="btn btn-default px-4 py-2 rounded-full items-center"
                  type="button"
                  style={{ transition: "all .15s ease" }}
                  onClick={() => this.setShowModal(false)}
                >
                  Tutup
                </button>
              </div>
            </Modal.Footer>
          </Modal>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      );
    }
  }
}
  