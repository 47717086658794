import React, { Component } from "react";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortAlphaUp, faSortDown, faSortUp, faYinYang } from "@fortawesome/free-solid-svg-icons";
export default class DataTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      entities: {
        data: [],
        current_page: 1,
        from: 1,
        last_page: 50,
        per_page: 10,
        to: 1,
        total: 1,

      },
      query: '',
      first_page: 1,
      current_page: 1,
      sorted_column: this.props.columns[0].index,
      offset: 4,
      order: "asc",

      loading: null,

      error_value: '',
      LoadingButton: false,
    };

    this.userList = this.userList.bind(this)
    // this.rowsNumber = this.rowsNumber.bind(this)
  }

  loading(load){
    if(load){
      let lo = this.state.loading;
      return lo;
    }
    this.setState({ loading: true });
    // return load;
  }


  fetchEntities(qry = false, searchLoading = null) {
    let fetchUrl = `${this.props.url}?page=${this.state.current_page}&column=${this.state.sorted_column}&order=${this.state.order}&per_page=${this.state.entities.per_page}`;
    if (qry) {
      fetchUrl = `${fetchUrl}${qry}`;
      this.setState({ query: qry }, function(){});
    }
    axios
      .get(fetchUrl)
      .then((response) => {
        this.setState({ entities: response.data });
        this.setState({ loading: false });
        // this.setState({})
        // console.log(response.data)
      })
  }


  loadingIndicator(load){
    // let load = this.state.loading;
    if(this.state.loading === false){
      load = false
    } else if (this.state.loading === true){
      load = true
    } else {
      load = null
    }
    return load;
  }


  changePage(pageNumber) {
    this.loading();
    this.setState({ current_page: pageNumber }, () => {
      this.fetchEntities(this.state.query);
    });
  }

  columnHead(value) {
    if(value === null){
      return(null)
    } else {
    return value.split("_").join(" ").toUpperCase();
    }
  }

  pagesNumbers() {
    if (!this.state.entities.to) {
      return [];
    }
    let from = this.state.entities.current_page - this.state.offset;
    if (from < 1) {
      from = 1;
    }
    let to = from + this.state.offset * 2;
    if (to >= this.state.entities.last_page) {
      to = this.state.entities.last_page;
    }
    let pagesArray = [];
    for (let page = from; page <= to; page++) {
      pagesArray.push(page);
    }
    return pagesArray;
  }

  // componentDidMount() {
  //   this.setState(
  //     { current_page: this.state.entities.current_page },
  //     () => {
  //       this.fetchEntities();
  //     }
  //   );
  // }

  refreshPage(){
    window.location.reload()
  }

  tableHeadsColSpan() {
    return this.props.columns.map((column) => {
      if(column.colSpan !== null){
        return (
          <th
            className="top-0 px-3 font-extrabold border-b break-words py-2 text-gray-700 font-bold tracking-wider uppercase text-xs"
            colSpan={column.colSpan}
          >
            {this.columnHead(column.label)}
          </th>
        );
      }
    });
  }

  resetPage(qry){
    this.setState({current_page : 1}, function(){
      this.fetchEntities(qry);
    });
  }

  tableHeads() {
    return this.props.columns.map((column) => {
      if(column.colSpan === null){
        return (
          <th
            scope="col"
            className="table-column-pl-3 px-3"
            style={{ maxWidth: column.maxWidth, minWidth:"100px"}}
            key={column.index}
            onClick={() => this.sortByColumn(column.index)}
            // style={{minWidth:"100px"}}
          >
            <label htmlFor="" className="mr-1">{this.columnHead(column.label)}</label>
            <FontAwesomeIcon icon={this.state.order === "asc" ? faSortUp : faSortDown } className="float-right mt-1" />
          </th>
        );
      }
      
    });
  }

  userList() {
    if (this.state.loading === true){
      return (
        <tr>
          <td
            className=" border-t px-3 border-gray-200 userId text-center"
            colSpan={this.props.columns.length}
            // className="text-center"
          >
            <div className="flex flex-col py-3">
              <Spinner animation="border" variant="primary" className="mx-auto"/>
              <h3 className="font-bold text-gray-700">Loading</h3>
            </div>
          </td>
        </tr>
      );
    }else if (this.state.entities.data && this.state.entities.data.length) {
        return this.state.entities.data.map((data) => {
          let listAllow = {};
          let maxWidth = {};
          this.props.columns.map((value) => {
            if(value.index !== 'thColSpan'){
              listAllow[value.index] = data[value.index];
              maxWidth[value.index] = value.maxWidth;
            }
            // console.log(value.index);
          });
  
          return (
            <>
            <tr key={listAllow.id}>
              {/* {this.state.entities.data.map((col, index) => (
                <td className="border-t  break-words px-1 border-gray-200 userId">{index.key}</td>
              ))} */}
              {Object.keys(listAllow).map((key) => {
                if (this.props.action !== undefined) {
                  if (key in this.props.action) {
                    let action = this.props.action[key];
                    return (
                      <td
                        className=" break-words px-3 border-t border-gray-200 userId"
                        key={key}
                        style={{ maxWidth: maxWidth[key] }}
                      >
                        {action(listAllow, data)}
                      </td>
                    );
                  }
                }
                return (
                  <td
                    className="border-t  break-words px-3 border-gray-200 userId"
                    key={key}
                    style={{ maxWidth: maxWidth[key] }}
                  >
                    <span className="text-gray-700 py-2 flex items-cente text-sm">
                      {listAllow[key]}
                    </span>
                  </td>
                );
              })}
            </tr>
            </>
          );
        });
    } else if (this.state.entities.total === '0') {
        return (
          <tr>
            <td
              className=" border-t border-gray-200 px-3 userId text-center"
              colSpan={this.props.columns.length}
              // className="text-center"
            >
              <div className="flex flex-col py-3">
                <span className="bx bx-customize text-blue-700" style={{fontSize: '3.5rem'}}></span>
                <h3 className="font-bold text-gray-700">Belum ada data.</h3>
              </div>
            </td>
          </tr>
          
        )
    } else if (this.state.error_value === ''){
      return (
        <tr>
          <td
            className=" border-t px-3 border-gray-200 userId overflow-hidden"
            colSpan={this.props.columns.length}
            // className=""
          >
            
          </td>
        </tr>
      );
    }else if (this.state.error_value === 422){
      return (
        <tr>
          <td
            className=" border-t px-3 border-gray-200 userId text-center"
            colSpan={this.props.columns.length}
            // className="text-center"
          >
            <div className="flex flex-col py-3">
              <span className="bx bx-error text-red-700" style={{fontSize: '3.5rem'}}></span>
              <h3 className="font-bold text-gray-700">aksi tidak dapat diproses</h3>
            </div>
          </td>
        </tr>
        
      )
    }else if (this.state.error_value === 404 || 500){
      return (
        <tr>
          <td
            className=" border-t px-3 border-gray-200 userId text-center"
            colSpan={this.props.columns.length}
            // className="text-center"
          >
            <div className="flex flex-col py-3">
              <span className="bx bx-error text-red-700" style={{fontSize: '3.5rem'}}></span>
              <h3 className="font-bold text-gray-700">Server Error.</h3>
            </div>
          </td>
        </tr>
        
      )
    }else if (this.state.error_value === 408){
      return (
        <tr>
          <td
            className=" border-t px-3 border-gray-200 userId text-center"
            colSpan={this.props.columns.length}
            // className="text-center"
          >
            <div className="flex flex-col py-3">
              <span className="bx bx-error text-orange-700" style={{fontSize: '3.5rem'}}></span>
              <h3 className="font-bold text-gray-700">Oops. Waktu Tunggu Habis.</h3>
              <p className="font-bold text-sm text-gray-500 my-2">Periksa koneksi anda dan refresh halaman ini.</p>
              <button className=" bg-blue-600 rounded-full px-2 py-2 mt-2 mx-auto" onClick={this.refreshPage}><p className="font-bold text-sm text-white">Refresh</p></button>
            </div>
          </td>
        </tr>
        
      )
    }  else {
      return (
        <tr>
          <td
            className=" border-t px-3 border-gray-200 userId overflow-hidden"
            colSpan={this.props.columns.length}
            // className=""
          >
            
          </td>
        </tr>
      );
    }
    // else{
    //   return(
    //     <tr>
    //       <td
    //         className=" border-t border-gray-200 userId overflow-hidden"
    //         colSpan={this.props.columns.length}
    //         className=""
    //       >
            
    //         <TableSkeleton style={{width: '80rem', bottom: '70px', position: 'relative'}}/>
    //       </td>
    //     </tr>
    //   )
    // }
  }

  sortByColumn(column) {
    if (column === this.state.sorted_column) {
      this.state.order === "asc"
        ? this.setState(
            { order: "desc", current_page: this.state.first_page },
            () => {
              this.fetchEntities(this.state.query);
            }
          )
        : this.setState({ order: "asc" }, () => {
            this.fetchEntities(this.state.query);
          });
    } else {
      this.setState(
        {
          sorted_column: column,
          order: "asc",
          current_page: this.state.first_page,
        },
        () => {
          this.fetchEntities(this.state.query);
        }
      );
    }
  }

  pageList() {
    // console.log(this.pagesNumbers());
    return this.pagesNumbers().map((page) => {
      return (
        <button
          key={page}
          onClick={() => this.changePage(page)}
          className={
            page === this.state.entities.current_page
              ? "btn btn-primary mr-1"
              : "btn btn-default mr-1"
          }
        >
          {" "}
          {page}
        </button>
      );
    });
  }


  render() {
    // console.log(this.state.error_value)
    return (
      <div className="py-1">
        <div>
            <div className="table-responsive datatable-custom">
              <table className="table table-sm table-thead-bordered table-bordered table-align-middle card-table dataTable no-footer">
                <thead className="thead-light">
                  <tr className="text-left">{this.tableHeadsColSpan()}</tr>
                  <tr className="text-left">
                  {/* <th className="top-0 px-1 border-b break-words border-gray-500 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs" style={{minWidth: '20px !important'}}>No</th> */}
                    {this.tableHeads()}
                  </tr>
                </thead>
                <tbody>{this.userList()}</tbody>
              </table>
            </div>
            {this.state.entities.data && this.state.entities.data.length > 0 && (
              <div className="mt-4 flex justify-between">
                <div className="px-3">
                  <button
                    disabled={1 === this.state.entities.current_page}
                    onClick={() =>
                      this.changePage(1)
                    }
                    className="btn btn-default mr-1"
                  >
                    Pertama
                  </button>
                  <button
                    disabled={1 === this.state.entities.current_page}
                    onClick={() =>
                      this.changePage(this.state.entities.current_page - 1)
                    }
                    className="btn btn-default mr-1"
                  >
                    Sebelumnya
                  </button>
                  {this.pageList()}
                  <button
                    disabled={
                      this.state.entities.last_page ===
                      this.state.entities.current_page
                    }
                    onClick={() =>
                      this.changePage(this.state.entities.current_page + 1)
                    }
                    className="btn btn-default mr-1"
                  >
                    Selanjutnya
                  </button>
                  <button
                    disabled={this.state.entities.last_page === this.state.entities.current_page}
                    onClick={() =>
                      this.changePage(this.state.entities.last_page)
                    }
                    className="btn btn-default mr-1"
                  >
                    Terakhir
                  </button>
                  {/* <span style={{ marginTop: '8px' }}> &nbsp; <i>Displaying { this.state.entities.data.length } of { this.state.entities.total } entries.</i></span> */}
                </div>
              </div>
            )}
          </div>
      </div>
    );
  }
}