import React from 'react';
import DataTable from '../../../partials/table/DataTable';
import Select from 'react-select';
import conf from '../../../../config';
import tokenApi from "../../../auth/Helpers";
import axios from 'axios';
import SweetAlert from 'sweetalert2-react';
import { Link, Redirect } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

export default class Institusi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      url: `${conf.uri_backend}api/admin/v1/institusi`,
      url_delete: `${conf.uri_backend}api/admin/v1/institusi/delete_institusi/`,
      url_get_negara: `${conf.uri_backend}api/v1/referensi/wilayah/negara?per_page=20`,
      url_get_provinsi: `${conf.uri_backend}api/v1/referensi/wilayah/provinsi?per_page=20&negara_id=`,
      url_get_kota: `${conf.uri_backend}api/v1/referensi/wilayah/kota?per_page=20&provinsi_id=`,
      url_get_kecamatan: `${conf.uri_backend}api/v1/referensi/wilayah/kecamatan?per_page=20&kota_id=`,
      url_get_kelurahan: `${conf.uri_backend}api/v1/referensi/wilayah/kelurahan?per_page=20&kecamatan_id=`,

      modal_delete: false,
      // alert
      show_alert: false,
      title_alert: "",
      message_alert: "",
      icon_alert: "",

      dataJenjang: "",
      dataStatus: "",
      dataNegara: "",
      dataProv: "",
      dataKota: "",
      dataKec: "",
      dataDesa: "",

      scText: "",
      scJenjang: "",
      scStatus: "",
      scNegara: "",
      scProv: "",
      scKota: "",
      scKec: "",
      scDesa: "",

      display: false,
    };

    this.getJenjang = this.getJenjang.bind(this);
    this.getStatus = this.getStatus.bind(this);
    this.getNegara = this.getNegara.bind(this);
    this.getProv = this.getProv.bind(this);
    this.getKota = this.getKota.bind(this);
    this.getKec = this.getKec.bind(this);
    this.getDesa = this.getDesa.bind(this);

    this.showJenjangFil = this.showJenjangFil.bind(this);
    this.showStatusFil = this.showStatusFil.bind(this);
    this.showNegaraFil = this.showNegaraFil.bind(this);
    this.showProvFil = this.showProvFil.bind(this);
    this.showKotFil = this.showKotFil.bind(this);
    this.showKecFil = this.showKecFil.bind(this);
    this.showDesFil = this.showDesFil.bind(this);

    this.filJenjang = this.filJenjang.bind(this);
    this.filStatus = this.filStatus.bind(this);
    this.filNegara = this.filNegara.bind(this);
    this.filProv = this.filProv.bind(this);
    this.filKot = this.filKot.bind(this);
    this.filKec = this.filKec.bind(this);
    this.filDes = this.filDes.bind(this);

    this.setFilter = this.setFilter.bind(this);
    this.searchText = this.searchText.bind(this);
    this.resetFilter = this.resetFilter.bind(this);

    this.edit = this.edit.bind(this);
    this.delete = this.delete.bind(this);
    this.hideModalDel = this.hideModalDel.bind(this);
    this.checkLogin = this.checkLogin.bind(this);

    this.detail = this.detail.bind(this);
  }

  componentDidMount() {
    this.checkLogin();
    this.getJenjang();
    this.getStatus();
    this.getNegara();
    this.dt.loading();
    this.dt.fetchEntities();
  }

  checkLogin() {
    if (!localStorage.hasOwnProperty("token")) {
      localStorage.setItem("is_login", "");
      localStorage.setItem("token", "");
    }
    if (localStorage.getItem("token") === "") {
      window.location.replace(conf.uri_frontend + "admin_login");
    }
  }

  // FILTER FUNCTION
  getJenjang() {
    var jenjang = [
      { value: "1", label: "TK" },
      { value: "2", label: "SD/MI" },
      { value: "3", label: "SMP/MTS" },
      { value: "4", label: "SMA/MA" },
      { value: "5", label: "SMK/MAK" },
      { value: '6', label: 'PKBM' },
      { value: '7', label: 'SLB' },
    ];
    this.setState({ dataJenjang: jenjang });
  }

  getStatus() {
    var status = [
      { value: "Negeri", label: "Negeri" },
      { value: "Swasta", label: "Swasta" },
    ];
    this.setState({ dataStatus: status });
  }

  getNegara() {
    axios.get(this.state.url_get_negara).then((res) => {
      this.setState({ dataNegara: res.data });
    });
  }

  getProv() {
    if (this.state.scNegara !== "" || this.state.scNegara === 1) {
      axios
        .get(this.state.url_get_provinsi + this.state.scNegara)
        .then((res) => {
          this.setState({ dataProv: res.data });
        });
    }
  }

  getKota() {
    if (this.state.scProv !== "" || this.state.scNegara === 1) {
      axios.get(this.state.url_get_kota + this.state.scProv).then((res) => {
        this.setState({ dataKota: res.data });
      });
    }
  }

  getKec() {
    if (this.state.scKota !== "" || this.state.scNegara === 1) {
      axios
        .get(this.state.url_get_kecamatan + this.state.scKota)
        .then((res) => {
          this.setState({ dataKec: res.data });
        });
    }
  }

  getDesa() {
    if (this.state.scKec !== "" || this.state.scNegara === 1) {
      axios
        .get(this.state.url_get_kelurahan + this.state.scKota)
        .then((res) => {
          this.setState({ dataDesa: res.data });
        });
    }
  }
  btnFilter = () => {
    this.setState({
      display: !this.state.display,
    });
  };

  // select
  filJenjang(e) {
    if (e) {
      this.setState({ scJenjang: e.value });
    } else {
      this.setState({ scJenjang: "" });
    }
  }
  filStatus(e) {
    if (e) {
      this.setState({ scStatus: e.value });
    } else {
      this.setState({ scStatus: "" });
    }
  }
  filNegara(e) {
    if (e) {
      this.setState({ scNegara: e.value }, function () {
        this.getProv();
      });
    } else {
      this.setState({
        scNegara: "",
        scProv: "",
        dataProv: "",
        scKota: "",
        dataKota: "",
        scKec: "",
        dataKec: "",
        scDesa: "",
        dataDesa: "",
      });
    }
  }
  filProv(e) {
    if (e) {
      this.setState({ scProv: e.value }, function () {
        this.getKota();
      });
    } else {
      this.setState({
        scProv: "",
        scKota: "",
        dataKota: "",
        scKec: "",
        dataKec: "",
        scDesa: "",
        dataDesa: "",
      });
    }
  }
  filKot(e) {
    if (e) {
      this.setState({ scKota: e.value }, function () {
        this.getKec();
      });
    } else {
      this.setState({
        scKota: "",
        scKec: "",
        dataKec: "",
        scDesa: "",
        dataDesa: "",
      });
    }
  }
  filKec(e) {
    if (e) {
      this.setState({ scKec: e.value }, function () {
        this.getDesa();
      });
    } else {
      this.setState({
        scKec: "",
        scDesa: "",
        dataDesa: "",
      });
    }
  }
  filDes(e) {
    if (e) {
      this.setState({ scDesa: e.value });
    } else {
      this.setState({
        scDesa: "",
      });
    }
  }

  showJenjangFil() {
    if (this.state.dataJenjang !== "") {
      return (
        <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
          <label className="text-sm">Jenjang</label>
          <Select
            placeholder={"Pilih Jenjang"}
            isClearable={true}
            options={this.state.dataJenjang}
            onChange={this.filJenjang}
          />
        </div>
      );
    }
  }

  showStatusFil() {
    if (this.state.dataStatus !== "") {
      return (
        <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
          <label className="text-sm">Status</label>
          <Select
            placeholder={"Pilih Status"}
            isClearable={true}
            options={this.state.dataStatus}
            onChange={this.filStatus}
          />
        </div>
      );
    }
  }

  showNegaraFil() {
    if (this.state.dataNegara !== "") {
      return (
        <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
          <label className="text-sm">Negara</label>
          <Select
            placeholder={"Pilih Negara"}
            isClearable={true}
            options={this.state.dataNegara}
            onChange={this.filNegara}
          />
        </div>
      );
    }
  }

  showProvFil() {
    if (
      this.state.dataProv !== "" &&
      this.state.scNegara !== "" &&
      this.state.scNegara === 1
    ) {
      return (
        <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
          <label className="text-sm">Provinsi</label>
          <Select
            placeholder={"Pilih Provinsi"}
            isClearable={true}
            options={this.state.dataProv}
            onChange={this.filProv}
          />
        </div>
      );
    }
  }

  showKotFil() {
    if (this.state.dataKota !== "" && this.state.scProv !== "") {
      return (
        <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
          <label className="text-sm">Kota</label>
          <Select
            placeholder={"Pilih Kota"}
            isClearable={true}
            options={this.state.dataKota}
            onChange={this.filKot}
          />
        </div>
      );
    }
  }

  showKecFil() {
    if (this.state.dataKec !== "" && this.state.scKota !== "") {
      return (
        <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
          <label className="text-sm">Kecamatan</label>
          <Select
            placeholder={"Pilih Kecamatan"}
            isClearable={true}
            options={this.state.dataKec}
            onChange={this.filKec}
          />
        </div>
      );
    }
  }

  showDesFil() {
    if (this.state.dataDesa !== "" && this.state.scKec !== "") {
      return (
        <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
          <label className="text-sm">Desa</label>
          <Select
            placeholder={"Pilih Desa"}
            isClearable={true}
            options={this.state.dataDesa}
            onChange={this.filDes}
          />
        </div>
      );
    }
  }

  setFilter() {
    var query =
      "&jenjang=" +
      this.state.scJenjang +
      "&status=" +
      this.state.scStatus +
      "&negara=" +
      this.state.scNegara +
      "&provinsi=" +
      this.state.scProv +
      "&kota=" +
      this.state.scKota +
      "&kecamatan=" +
      this.state.scKec +
      "&desa=" +
      this.state.scDesa +
      "&search=" +
      this.state.scText;
    this.setState({ query: query }, function () {
      this.dt.loading();
      this.dt.fetchEntities(this.state.query);
    });
  }

  searchText(e) {
    var query =
      "&jenjang=" +
      this.state.scJenjang +
      "&status=" +
      this.state.scStatus +
      "&negara=" +
      this.state.scNegara +
      "&provinsi=" +
      this.state.scProv +
      "&kota=" +
      this.state.scKota +
      "&kecamatan=" +
      this.state.scKec +
      "&desa=" +
      this.state.scDesa +
      "&search=" +
      e.target.value;
    this.setState({ query: query, scText: e.target.value }, function () {
      this.dt.loading();
      this.dt.fetchEntities(this.state.query);
    });
  }

  resetFilter() {
    document.getElementById("datatableSearch").value = "";
    this.setState(
      {
        scJenjang: "",
        dataJenjang: "",
        scStatus: "",
        dataStatus: "",
        scText: "",
        scNegara: "",
        dataNegara: "",
        scProv: "",
        dataProv: "",
        scKota: "",
        dataKota: "",
        scKec: "",
        dataKec: "",
        scDesa: "",
        dataDesa: "",
      },
      function () {
        this.getJenjang();
        this.getStatus();
        this.getNegara();
        this.dt.loading();
        this.dt.fetchEntities();
      }
    );
  }

  edit(data) {
    this.props.history.push(`/institusi/edit/${data}`);
  }

  modalDelete(data) {
    console.log(data);
    this.setState(
      {
        modal_delete: true,
        delete_id: data.id_institusi,
        delete_uuid: data.uuid,
      },
      function () {
        console.log(this.state.delete_id);
      }
    );
  }
  hideModalDel() {
    this.setState({
      modal_delete: false,
    });
  }
  delete() {
    axios
      .post(
        `${this.state.url_delete}${this.state.delete_id}`,
        { uuid: this.state.delete_uuid },
        { headers: { Authorization: tokenApi } }
      )
      .then((res) => {
        this.setState({
          show_alert: true,
          title_alert: "Berhasil",
          icon_alert: "success",
          message_alert: `Berhasil Menghapus`,
        });
        this.hideModalDel();
        this.dt.loading();
        this.dt.resetPage();
      })
      .catch((err) => {
        if (err.response.status === 422) {
          this.setState({
            show_alert: true,
            title_alert: "Gagal",
            icon_alert: "error",
            message_alert: err.response.data.messages[0],
          });
        }
      });
  }

  detail() {}

  render() {
    return (
      <>
        <main className="main" id="content" role="main">
          {/* alert */}
          <SweetAlert
            show={this.state.show_alert}
            title={this.state.title_alert}
            text={this.state.message_alert}
            type={this.state.icon_alert}
            onConfirm={() => this.setState({ show_alert: false })}
          />
          <Modal show={this.state.modal_delete}>
            <Modal.Header className="mx-auto">Hapus Data</Modal.Header>
            <Modal.Body className="mx-auto">
              <div className="flex flex-col py-4 mx-auto w-full">
                <span
                  className="bx bx-error text-red-700 text-center"
                  style={{ fontSize: "3.5rem" }}
                ></span>
                <h2 className="text-lg">
                  Hapus data dan akun {this.state.delete_data} ?
                </h2>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="text-gray-700 px-4 py-1 flex items-center">
                <button className="btn btn-sm mr-1" onClick={this.hideModalDel}>
                  Batal
                </button>
                <button
                  onClick={this.delete}
                  className="btn btn-sm btn-outline-danger"
                >
                  <i className="tio-delete-outlined"></i> Hapus
                </button>
              </div>
            </Modal.Footer>
          </Modal>

          <div className="content container-fluid">
            {/* <!-- Page Header --> */}
            <div className="page-header">
              <div className="row align-items-end mb-3">
                <div className="col-sm mb-2 mb-sm-0">
                  <nav aria-label="breadcrumb"></nav>

                  <h1 className="page-header-title">Sekolah / Institusi</h1>
                </div>

                <div className="col-sm-auto">
                  <Link to="/institusi/create" className="btn btn-primary">
                    <i className="tio-add mr-1"></i> Tambah Institusi
                  </Link>
                </div>
              </div>
            </div>
            {/* <!-- End Page Header --> */}

            <div className="card mt-4 pb-4">
              {/* <!-- Header --> */}
              <div className="card-header">
                <div className="row justify-content-between align-items-center flex-grow-1">
                  <div className="col-12 col-md">
                    <form>
                      {/* <!-- Search --> */}
                      <div className="input-group input-group-merge input-group-borderless">
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                            <i className="tio-search"></i>
                          </div>
                        </div>
                        <input
                          id="datatableSearch"
                          onChange={this.searchText}
                          type="search"
                          className="form-control"
                          placeholder="Search users"
                          aria-label="Search users"
                        />
                      </div>
                      {/* <!-- End Search --> */}
                    </form>
                  </div>

                  <div className="col-auto">
                    <div className="d-flex align-items-center">
                      {/* <!-- Datatable Info --> */}
                      <div
                        id="datatableCounterInfo"
                        className="mr-2"
                        style={{ display: "none" }}
                      >
                        <div className="d-flex align-items-center">
                          <span className="font-size-sm mr-3">
                            <span id="datatableCounter">0</span>
                            Selected
                          </span>

                          <a
                            className="btn btn-sm btn-outline-danger"
                            href="javascript:;"
                          >
                            <i className="tio-delete-outlined"></i> Delete
                          </a>
                        </div>
                      </div>
                      {/* <!-- End Datatable Info --> */}

                      {/* <!-- Filter Collapse Trigger --> */}
                      <a
                        className="btn btn-white dropdown-toggle"
                        data-toggle="collapse"
                        href="#filterSearchCollapse"
                        role="button"
                        onClick={()=>this.btnFilter()}
                      >
                        <i className="tio-filter-outlined mr-1"></i> Filters
                      </a>
                      {/* <!-- End Filter Collapse Trigger --> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- End Header --> */}

              {/* <!-- Filter Search Collapse --> */}
              <div style={{display: this.state.display === false ? "none" : "block",}}>
                <div className="card-body">
                  <form>
                    <div className="row">
                      {this.showJenjangFil()}
                      {this.showStatusFil()}
                      {this.showNegaraFil()}
                      {this.showProvFil()}
                      {this.showKotFil()}
                      {this.showKecFil()}
                      {this.showDesFil()}
                    </div>
                    {/* <!-- End Row --> */}

                    <div className="d-flex justify-content-end">
                      <button
                        onClick={this.resetFilter}
                        type="button"
                        className="btn btn-white mr-2"
                      >
                        Reset
                      </button>
                      <button
                        onClick={this.setFilter}
                        type="button"
                        className="btn btn-primary"
                      >
                        Cari
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              {/* <!-- End Filter Search Collapse --> */}

              <div className="card-body">
                <DataTable
                  ref={(dt) => {
                    this.dt = dt;
                  }}

                  url={this.state.url}
                  columns={[
                    {
                      index: "jenjang",
                      label: "Jenjang",
                      maxWidth: "4rem ",
                      // sort: 'asc',
                    },
                    {
                      index: "status",
                      label: "Status",
                      // sort: 'asc',
                    },
                    {
                      index: "npsn",
                      label: "NPSN",
                      // sort: 'asc',
                    },
                    {
                      index: "nama_institusi",
                      label: "Nama Institusi",
                      // sort: 'asc',
                    },
                    {
                      index: "provinsi",
                      label: "Provinsi",
                      // sort: 'asc',
                    },
                    {
                      index: "kabupaten",
                      label: "Kab / Kota",
                      // sort: 'asc',
                    },
                    {
                      index: "website",
                      label: "Website",
                      // sort: 'asc',
                    },
                    {
                      index: "aksi",
                      label: "Aksi",
                      // sort: 'asc',
                    },
                  ]}
                  action={{
                    aksi: (col, data) => {
                      return (
                        <div className="text-gray-700 px-4 py-1 flex items-center">
                          <button
                            className="btn btn-sm btn-white"
                            onClick={this.edit.bind(this, data.id_institusi)}
                          >
                            <i className="tio-book-outlined"></i> Edit
                          </button>
                          <button
                            onClick={this.modalDelete.bind(this, data)}
                            className="btn btn-sm btn-outline-danger"
                          >
                            <i className="tio-delete-outlined"></i> Hapus
                          </button>
                        </div>
                      );
                    },
                  }}
                  
                                  />
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

