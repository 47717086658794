import React from "react";
import Select from "react-select";
import conf from "../../../../config";
import tokenApi from "../../../auth/Helpers";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import noImg from "./noimg.png";

export default class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: `${conf.uri_backend}api/admin/v1/user/edit`,
      // url_update: `${conf.uri_backend}api/admin/v1/institusi/update_guru`,
      url_update: `${conf.uri_backend}api/admin/v1/user/update_user`,

      url_get_negara: `${conf.uri_backend}api/v1/referensi/wilayah/negara?per_page=20`,
      url_get_provinsi: `${conf.uri_backend}api/v1/referensi/wilayah/provinsi?per_page=20&negara_id=`,
      url_get_kota: `${conf.uri_backend}api/v1/referensi/wilayah/kota?per_page=20&provinsi_id=`,
      url_get_kecamatan: `${conf.uri_backend}api/v1/referensi/wilayah/kecamatan?per_page=20&kota_id=`,
      url_get_kelurahan: `${conf.uri_backend}api/v1/referensi/wilayah/kelurahan?per_page=20&kecamatan_id=`,
      
      email: "",
      second_email: "",
      npsn: "",
      jenjang: "",
      kode_pos: "",
      nama_institusi: "",

      jenis_kelamin: "",
      data_jenis_kelamin: [],
      nama: "",
      no_handphone: "",
      agama: "",

      sk_pendirian: "",
      fax: "",
      alamat_1: "",
      alamat_2: "",
      bujur: "",
      lintang: "",
      status_kepemilikan: "",
      rt_domisili: "",
      rw_domisili: "",
      logo: "",
      logo_preview: "",
      cover: "",
      cover_preview: "",
      negara_id: "",
      negara_label: "",
      provinsi_id: "",
      provinsi_label: "",
      kabupaten_id: "",
      kabupaten_label: "",
      kecamatan_id: "",
      kecamatan_label: "",
      desa_id: "",
      desa_label: "",
      logo_old: "",
      cover_old: "",
      status_sm: "",
      verified: "",
      paid: "",
      tanggal_lahir: "",
      tempat_lahir: "",
      //  data_negara: "",
      // rt_id: "",
      // rw_id: "",

      group_code: "",

      id_provinsi: "",
      id_kota: "",
      id_kecamatan: "",

      jenjang_id: "",
      jenjang_label: "",
      disabled: false,
      showFilter: false,

      // alert
      show_alert: false,
      title_alert: "",
      message_alert: "",
      icon_alert: "",
    };
    this.update = this.update.bind(this);

    this.getNegara      = this.getNegara.bind(this);
    this.getProv        = this.getProv.bind(this);
    this.getKota        = this.getKota.bind(this);
    this.getKec         = this.getKec.bind(this);
    this.getDesa        = this.getDesa.bind(this);
    this.getAgama       = this.getAgama.bind(this);
    this.getJenisKelamin= this.getJenisKelamin.bind(this);
  
    // select
    this.selectNegara   = this.selectNegara.bind(this);
    this.selectProv     = this.selectProv.bind(this);
    this.selectKota     = this.selectKota.bind(this);
    this.selectKec      = this.selectKec.bind(this);
    this.selectDesa     = this.selectDesa.bind(this);
    this.selectAgama    = this.selectAgama.bind(this);
    this.selectJenisKelamin= this.selectJenisKelamin.bind(this);

    // this.uploadLogo = this.uploadLogo.bind(this);
    // this.uploadCover = this.uploadCover.bind(this);
    this.checkLogin = this.checkLogin.bind(this);
  }
  componentDidMount() {
    this.checkLogin();
    // get data
    const {
      match: { params },
    } = this.props;
    // console.log("ini paramsss : ", this.props);
    this.setState({ id: params.id_user });
    
    axios
      .get(`${this.state.url}/${params.id_user}?type=${params.type}`)
      .then((res) => {
        // console.log(res.data.data);
        this.setState(
          {
            user_id: res.data.data.user_id,
            id_user: res.data.data.id,
            username: res.data.data.username,
            old_username: res.data.data.username,
            email: res.data.data.email,
            old_email: res.data.data.email,
            tempat_lahir: res.data.data.tempat_lahir,
            agama: res.data.data.agama,
            
            negara_id: res.data.data.negara,
            provinsi_id: res.data.data.provinsi_domisili,
            kabupaten_id: res.data.data.kabupaten_domisili,
            kecamatan_id: res.data.data.kecamatan_domisili,
            desa_id: res.data.data.desa_domisili,
            
            rt_domisili: res.data.data.rt_domisili,
            rw_domisili: res.data.data.rw_domisili,
            
            jenis_kelamin: res.data.data.jenis_kelamin,
            nama: res.data.data.nama,
            tanggal_lahir: res.data.data.tanggal_lahir,
            no_handphone: res.data.data.no_handphone,
            group_code: res.data.data.group_code,

            showFilter: true,
          },
          function () {
            this.getProv();
            this.getKota();
            this.getKec();
            this.getDesa();
            this.getJenjang();
            this.getStatus();
            this.getNegara();
            this.getAgama();
            this.getJenisKelamin();
          }
        );
      });

      console.log("state agama", this.state.agama)
  }

  checkLogin() {
    if (!localStorage.hasOwnProperty("token")) {
      localStorage.setItem("is_login", "");
      localStorage.setItem("token", "");
    }
    if (localStorage.getItem("token") == "") {
      window.location.replace(conf.uri_frontend + "admin_login");
    }
  }

  // Bind data
  getJenjang() {
    var jenjang = [
      { value: "1", label: "TK" },
      { value: "2", label: "SD/MI" },
      { value: "3", label: "SMP/MTS" },
      { value: "4", label: "SMA/MA" },
      { value: "5", label: "SMK/MAK" },
    ];
    this.setState({ data_jenjang: jenjang });
  }

  getAgama() {
    var agama = [
      { value: "Islam", label: "Islam" },
      { value: "Kristen", label: "Kristen" },
      { value: "Hindu", label: "Hindu" },
      { value: "Budha", label: "Budha" },
      { value: "Katolik", label: "Katolik" },
      { value: "Khonghuchu", label: "Khonghuchu" },
      { value: "Aliran Kepercayan & Tidak Beragama", label: "Aliran Kepercayan & Tidak Beragama" },
    ];
    this.setState({ data_agama: agama });
  }

  getStatus() {
    var status = [
      { value: "Negeri", label: "Negeri" },
      { value: "Swasta", label: "Swasta" },
    ];
    this.setState({ data_status: status });
  }

  getNegara(){
    axios.get(this.state.url_get_negara)
    .then(res=>{
      this.setState({ data_negara: res.data});
    })
    .catch(error => {
      console.log('error fetching data', error);
    })
  }

  getProv(e){
    const negaraId = e ? e.value : this.state.negara_id;

    if (negaraId != null && negaraId != undefined) {
      axios.get(`${this.state.url_get_provinsi}${negaraId}`)
      .then(res => {
        this.setState({ data_provinsi: res.data});
      })
      .catch(error => {
        console.log('error fetching data', error);
      })
    }
  }

  getKota(e){
    const provinsiId = e ? e.value : this.state.provinsi_id;

    if (provinsiId != null && provinsiId != undefined) {
      axios.get(`${this.state.url_get_kota}${provinsiId}`)
      .then(res=>{
        this.setState({ data_kota: res.data});
      })
      .catch(error => {
        console.log('error fetching data', error);
      })
    }
  }

  getKec(e){
    const kabupatenId = e ? e.value : this.state.kabupaten_id;

    if (kabupatenId != null && kabupatenId != undefined) {
      axios.get(`${this.state.url_get_kecamatan}${kabupatenId}`)
      .then(res=>{
        this.setState({ data_kecamatan: res.data});
      })
      .catch(error => {
        console.log('error fetching data', error);
      })
    }
  }

  getDesa(e){
    const kecamatanId = e ? e.value : this.state.kecamatan_id;

    if (kecamatanId != null && kecamatanId != undefined) {
      axios.get(`${this.state.url_get_kelurahan}${kecamatanId}`)
      .then(res=>{
        this.setState({ data_desa: res.data});
      })
      .catch(error => {
        console.log('error fetching data', error);
      })
    }
  }

  getJenisKelamin() {
    var jenis_kelamin = [
      { value: 0, label: "Perempuan" },
      { value: 1, label: "Laki - laki" },
    ]

    this.setState({ data_jenis_kelamin: jenis_kelamin });
  }

  selectNegara(e){
    if(e){
      if(this.state.negara_id !== e.value){
        this.setState({
          negara_id: e.value,
          provinsi_label: null,
          provinsi_id: null
        })
      }
      this.getProv(e);
    }else{
      this.setState({negara_id: ''})
    }
  }
  selectProv(e){
    if(e){
      if(this.state.provinsi_id !== e.value){
        this.setState({provinsi_id: e.value})
      }
      this.getKota(e);
    }else{
      this.setState({provinsi_id: ''})
    }
  }
  selectKota(e){
    if(e){
      if(this.state.kabupaten_id !== e.value){
        this.setState({kabupaten_id: e.value})
        this.getKec(e);
      }
      this.getKec(e);
    }else{
      this.setState({kabupaten_id: ''})
    }
  }
  selectKec(e){
    if(e){
      if(this.state.kecamatan_id !== e.value){
        this.setState({kecamatan_id: e.value})
      }
      this.getDesa(e);
    }else{
      this.setState({kecamatan_id: ''})
    }
  }
  selectDesa(e){
    if(e){
      if(this.state.desa_id !== e.value){
        this.setState({desa_id: e.value})
      }
    }else{
      this.setState({desa_id: ''})
    }
  }
  
  
  selectAgama(e){
    if(e){
      this.setState({agama: e.value})
    }else{
      this.setState({agama: ''})
    }
  }

  selectJenisKelamin(e) {
    if (e) {
      this.setState({jenis_kelamin: e.value})
    } else {
      this.setState({jenis_kelamin: ''})
    }
  }

  showNegara(){
      return(
        <Select
          defaultValue={this.state.negara_id !== '' ? {value: this.state.negara_id, label: this.state.negara_label} : null}
          options={this.state.data_negara}
          onChange={this.selectNegara}
        />
      )
  }

  showProv(e){
      return(
        <Select
          defaultValue={this.state.provinsi_id !== '' ? {value: this.state.provinsi_id, label: this.state.provinsi_label} : null}
          options={this.state.data_provinsi}
          onChange={this.selectProv}
        />
      )
  }
  showKab(){
      return(
        <Select
          defaultValue={this.state.kabupaten_id !== '' ? {value: this.state.kabupaten_id, label: this.state.kabupaten_label}:null}
          options={this.state.data_kota}
          onChange={this.selectKota}
        />
      )
  }
  showKec(){
      return(
        <Select
          defaultValue={this.state.kecamatan_id !== '' ? {value: this.state.kecamatan_id, label: this.state.kecamatan_label}:null}
          options={this.state.data_kecamatan}
          onChange={this.selectKec}
        />
      )
  }
  showDesa(){
      return(
        <Select
          defaultValue={this.state.desa_id !== '' ? {value: this.state.desa_id, label: this.state.desa_label}:null}
          options={this.state.data_desa}
          onChange={this.selectDesa}
        />
      )
  }
  
  uploadLogo(e) {
    if (e.target.files[0] != undefined) {
      this.setState({
        logo: e.target.files[0],
        logo_preview: URL.createObjectURL(e.target.files[0]),
      });
    } else {
      this.setState({
        logo: "",
        logo_preview: "",
      });
    }
  }
  uploadCover(e) {
    if (e.target.files[0] != undefined) {
      this.setState({
        cover: e.target.files[0],
        cover_preview: URL.createObjectURL(e.target.files[0]),
      });
    } else {
      this.setState({
        cover: "",
        cover_preview: "",
      });
    }
  }
  update(e) {
    e.preventDefault();

    var formData = new window.FormData();
    this.setState({disabled: true});

    formData.append('user_id', (this.state.user_id));
    formData.append('username', (this.state.username !== null ? this.state.username : ''));
    formData.append('old_username', (this.state.old_username !== null ? this.state.old_username : ''));
    formData.append('email', (this.state.email !== null ? this.state.email : ''));
    formData.append('old_email', (this.state.old_email !== null ? this.state.old_email : ''));
    formData.append('tempat_lahir', (this.state.tempat_lahir !== null ? this.state.tempat_lahir : ''));
    formData.append('agama', (this.state.agama !== null ? this.state.agama : ''));
    formData.append('user_type', (this.state.group_code !== null ? this.state.group_code : ''));

    formData.append('negara', (this.state.negara_id !== null ? this.state.negara_id : ''));
    formData.append('provinsi_domisili', (this.state.provinsi_id !== null ? this.state.provinsi_id : ''));
    formData.append('kabupaten_domisili', (this.state.kabupaten_id !== null ? this.state.kabupaten_id : ''));
    formData.append('kecamatan_domisili', (this.state.kecamatan_id !== null ? this.state.kecamatan_id : ''));
    formData.append('desa_domisili', (this.state.desa_id !== null ? this.state.desa_id : ''));
    formData.append('kode_pos_domisili', (this.state.kode_pos !== null ? this.state.kode_pos : ''));

    formData.append('rt_domisili', (this.state.rt_domisili !== null ? this.state.rt_domisili : ''));
    formData.append('rw_domisili', (this.state.rw_domisili !== null ? this.state.rw_domisili : ''));
    formData.append('jenis_kelamin', (this.state.jenis_kelamin !== null ? this.state.jenis_kelamin : ''));
    formData.append('nama', (this.state.nama !== null ? this.state.nama : ''));
    formData.append('tanggal_lahir', (this.state.tanggal_lahir !== null ? this.state.tanggal_lahir : ''));
    formData.append('no_handphone', (this.state.no_handphone !== null ? this.state.no_handphone : ''));

    axios.post(`${this.state.url_update}/${this.state.id_user}`, formData, {headers:{
      Authorization:tokenApi,
      'Content-Type': 'multipart/form-data'
    }}).then(res => {
      this.setState({ 
        show_alert    : true,
        title_alert   : "Berhasil",
        icon_alert    : "success",
        message_alert : "Berhasil Mengupdate",
      })
    }).catch(err => {
      if(err.response.status === 422){
        this.setState({ 
          show_alert    : true,
          title_alert   : "Gagal",
          icon_alert    : "error",
          message_alert : err.response.data.messages[0],
        });
      } 
    })
  }

  render() {
    return (
      <>
        <main className="main" id="content" role="main">
          {/* alert */}
          <SweetAlert
            show={this.state.show_alert}
            title={this.state.title_alert}
            text={this.state.message_alert}
            type={this.state.icon_alert}
            onConfirm={() => this.setState({ show_alert: false })}
          />
          <div className="content container-fluid">
            {/* alert */}
            {/* <!-- Page Header --> */}
            <div
              className="page-header"
              style={{ paddingBottom: "unset !important" }}
            >
              <div className="row align-items-end mb-3">
                <div className="col-sm mb-2 mb-sm-0">
                  <nav aria-label="breadcrumb"></nav>

                  <h1 className="page-header-title">Edit User</h1>
                </div>

                <div className="col-sm-auto"></div>
              </div>
            </div>
            {/* <!-- End Page Header --> */}

            <div className="card mt-4">
              <div className="card-body">
                <form onSubmit={this.update}>
                  <div className="">
                    <h2 className="text-lg text-schoolmedia font-bold mt-3">
                      Edit Data User
                    </h2>
                    <hr />
                    <div className="row">
                      <div className="col">
                        <label className="input-label">
                          Nama
                        </label>
                        <input
                          id="nama"
                          type="text"
                          className="form-control"
                          placeholder="Nama"
                          required
                          defaultValue={this.state.nama}
                          onChange={(event) => {
                            this.setState({ nama: event.target.value });
                          }}
                        />
                      </div>
                      <div className="col">
                        <label className="input-label">
                          Jenis Kelamin
                        </label>

                        <Select
                          value={
                            this.state.jenis_kelamin !== '' ? 
                            { value: this.state.jenis_kelamin, label:  this.state.jenis_kelamin === 0 ? 'Perempuan' : 'Laki-laki' } 
                            : null
                          }
                          options={this.state.data_jenis_kelamin}
                          onChange={this.selectJenisKelamin}
                        />
                      </div>
                      <div className="col">
                        <label className="input-label">
                          Email
                        </label>
                        <input
                          id="email"
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          readOnly="readOnly"
                          required
                          defaultValue={this.state.email}
                          onChange={(event) => {
                            this.setState({
                              email: event.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col">
                        <label className="input-label">
                          Tempat Lahir
                        </label>
                        <input
                          id="tempat-lahir"
                          type="text"
                          className="form-control"
                          placeholder="Tempat Lahir"
                          required
                          defaultValue={this.state.tempat_lahir}
                          onChange={(event) => {
                            this.setState({ tempat_lahir: event.target.value });
                          }}
                        />
                      </div>
                      <div className="col">
                        <label className="input-label">
                          Tanggal Lahir
                        </label>
                        <input
                          id="tanggal_lahir"
                          type="date"
                          className="form-control"
                          placeholder="Tanggal Lahir"
                          required
                          defaultValue={this.state.tanggal_lahir}
                          onChange={(event) => {
                            this.setState({
                              tanggal_lahir: event.target.value,
                            });
                          }}
                        />
                      </div>

                      <div className="col">
                        <label className="input-label">Agama</label>
                        <Select
                          placeholder="Pilih Agama"
                          defaultValue={
                            this.state.agama !== '' ? {
                              value: this.state.agama, 
                              label: this.state.agama
                            } : null
                          }
                          options={this.state.data_agama}
                          onChange={this.selectAgama}
                        />
                      </div> 
                    </div>

                    <div className="row mt-4">
                      <div className="col">
                        <label className="input-label">Golongan Darah</label>
                        <Select
                          placeholder="Pilih Golongan Darah"
                        />
                      </div>
                      <div className="col">
                        <label className="input-label">Pendidikan Terakhir</label>
                        <Select
                          placeholder="Pilih Pendidikan Terakhir"
                        />
                      </div>
                      <div className="col">
                        <label className="input-label">Motto</label>
                        <input
                          placeholder="Masukkan Motto"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <hr />

                    <div className="row mt-4">
                      <div className="col">
                        <label className="input-label">Negara</label>
                        {this.showNegara()}
                      </div>
                      
                      <div className="col">
                        <label className="input-label">
                          Provinsi Domisili
                        </label>
                       
                      {this.showProv()}
                      </div>

                      
                      <div className="col">
                        <label className="input-label">
                          Kabupaten Domisili
                        </label>
                        
                        {this.showKab()}
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col">
                        <label className="input-label">
                          Kecamatan Domisili
                        </label>
                        
                        {this.showKec()}

                      </div>
                      <div className="col">
                        <label className="input-label">
                          Desa Domisili
                        </label>
                       
                        {this.showDesa()}
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <label
                            
                              className="input-label"
                            >
                              Rw Domisili
                            </label>
                            <input
                              id="rw"
                              type="text"
                              className="form-control"
                              placeholder="Rw"
                              required
                              defaultValue={this.state.rw_domisili}
                              onChange={(event) => {
                                this.setState({ rw_domisili: event.target.value });
                              }}
                            />
                          </div>
                          <div className="col">
                            <label
                            
                              className="input-label"
                            >
                              Rt Domisili
                            </label>
                            <input
                              id="Rt"
                              type="text"
                              className="form-control"
                              placeholder="Rt"
                              required
                              defaultValue={this.state.rt_domisili}
                              onChange={(event) => {
                                this.setState({ rt_domisili: event.target.value });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  
                  {/* <!-- End Row --> */}
                  <div className="dropdown-divider"></div>

                  <div className="d-flex justify-content-between">
                    <div>
                      <input
                        type="button"
                        className="btn btn-primary btn-pill"
                        value="Kembali"
                        onClick={() => window.history.back()}
                      />
                    </div>

                    <div>
                      <input
                        type="submit"
                        className="btn btn-primary btn-pill"
                        value="Simpan"
                      />
                    </div>
                  </div>

                </form>
                </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}
