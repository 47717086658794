import React from "react";
import ContentMapel from "./ContentMapel";

export default class ContentBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active_menu: "",
    };
  }

  componentDidMount() {
    this.setState({ active_menu: this.props.id });
  }

  render() {
    return <div>{this.renderContent()}</div>;
  }

  renderContent() {
    if (this.state.active_menu === "sd") {
      return <ContentMapel jenjang="2" query={this.state.query} />;
    }

    if (this.state.active_menu === "smp") {
      return <ContentMapel jenjang="3" query={this.state.query} />;
    }

    if (this.state.active_menu === "sma") {
      return <ContentMapel jenjang="4" query={this.state.query} />;
    }

    if (this.state.active_menu === "smk") {
      return <ContentMapel jenjang="5" query={this.state.query} />;
    }
  }
}
