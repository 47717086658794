import React from 'react';
import DataTable from '../../partials/table/DataTable';
import conf from '../../../config';
import SweetAlert from 'sweetalert2-react';
import Select from 'react-select';
import tokenApi from "../../auth/Helpers";
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

export default class KompetensiDasar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      url: `${conf.uri_backend}api/admin/v1/referensi/wilayah/detail`,
      urlGetProvinsi: `${conf.uri_backend}api/admin/v1/referensi/wilayah/provinsi?&negara_id=1`,
      urlGetKota: `${conf.uri_backend}api/admin/v1/referensi/wilayah/kota?&negara_id=1&provinsi_id=`,
      urlGetKecamatan: `${conf.uri_backend}api/admin/v1/referensi/wilayah/kecamatan?&negara_id=1&kota_id=`,
      urlGetKelurahan: `${conf.uri_backend}api/admin/v1/referensi/wilayah/kelurahan?&negara_id=1&kecamatan_id=`,

      data_provinsi: "",
      data_kota: "",
      data_kecamatan: "",
      data_kelurahan: "",

      text_sc: "",
      provinsi_sc: "",
      kota_sc: "",
      kecamatan_sc: "",
      kelurahan_sc: "",

      // alert
      show_alert: false,
      title_alert: "",
      message_alert: "",
      icon_alert: "",

      display:false,
    };

    this.checkLogin = this.checkLogin.bind(this);
    this.getProvinsi = this.getProvinsi.bind(this);
    this.getKota = this.getKota.bind(this);
    this.getKecamatan = this.getKecamatan.bind(this);
    this.getKelurahan = this.getKelurahan.bind(this);
    this.searchText = this.searchText.bind(this);
    this.filterProvinsi = this.filterProvinsi.bind(this);
    this.filterKota = this.filterKota.bind(this);
    this.filterKecamatan = this.filterKecamatan.bind(this);
    this.filterKelurahan = this.filterKelurahan.bind(this);
    this.detail = this.detail.bind(this);
  }

  componentDidMount() {
    this.checkLogin();
    this.getProvinsi();
    this.dt.loading();
    this.dt.fetchEntities();
  }

  checkLogin() {
    if (!localStorage.hasOwnProperty("token")) {
      localStorage.setItem("is_login", "");
      localStorage.setItem("token", "");
    }
    if (localStorage.getItem("token") === "") {
      window.location.replace(conf.uri_frontend + "admin_login");
    }
  }
  openModal() {
    this.setState({
      modalShow: true,
    });
  }
  closeModal = () => {
    this.setState({
      modalShow: false,
    });
  };
  getProvinsi() {
    axios
      .get(this.state.urlGetProvinsi, {
        headers: {
          Authorization: tokenApi,
        },
      })
      .then((res) => {
        this.setState({
          data_provinsi: res.data,
        });
      });
  }

  getKota() {
    axios
      .get(this.state.urlGetKota + this.state.provinsi_sc, {
        headers: {
          Authorization: tokenApi,
        },
      })
      .then((res) => {
        this.setState({
          data_kota: res.data,
        });
      });
  }

  getKecamatan() {
    axios
      .get(this.state.urlGetKecamatan + this.state.kota_sc, {
        headers: {
          Authorization: tokenApi,
        },
      })
      .then((res) => {
        this.setState({
          data_kecamatan: res.data,
        });
      });
  }

  getKelurahan() {
    axios
      .get(this.state.urlGetKelurahan + this.state.kecamatan_sc, {
        headers: {
          Authorization: tokenApi,
        },
      })
      .then((res) => {
        this.setState({
          data_kelurahan: res.data,
        });
      });
  }

  searchText(e) {
    e.preventDefault();
    this.setState({ text_sc: e.target.value }, function () {
      this.dt.loading();
      this.dt.resetPage(
        `&query=${this.state.text_sc}  
        &provinsi_id=${this.state.provinsi_sc}
        &kota_id=${this.state.kota_sc}
        &kecamatan_id=${this.state.kecamatan_sc}
        &kelurahan_id=${this.state.kelurahan_sc}`
      );
    });
  }
  btnFilter = () => {
    this.setState({
      display: !this.state.display,
    });
  };

  filterProvinsi(e) {
    if (e) {
      this.setState(
        {
          provinsi_sc: e.value,
          data_kota: "",
          data_kecamatan: "",
          data_kelurahan: "",
          kota_sc: "",
          kecamatan_sc: "",
          kelurahan_sc: "",
        },
        function () {
          this.dt.loading();
          this.dt.resetPage(
            `&query=${this.state.text_sc}
          &provinsi_id=${this.state.provinsi_sc}`
          );
          this.getKota();
        }
      );
    } else {
      this.setState(
        {
          provinsi_sc: "",
          data_kota: "",
          data_kecamatan: "",
          data_kelurahan: "",
          kota_sc: "",
          kecamatan_sc: "",
          kelurahan_sc: "",
        },
        function () {
          this.dt.loading();
          this.dt.resetPage(`&query=${this.state.text_sc}`);
        }
      );
    }
  }

  filterKota(e) {
    if (e) {
      this.setState(
        {
          kota_sc: e.value,
          data_kecamatan: "",
          data_kelurahan: "",
          kecamatan_sc: "",
          kelurahan_sc: "",
        },
        function () {
          this.dt.loading();
          this.dt.resetPage(
            `&query=${this.state.text_sc}
          &provinsi_id=${this.state.provinsi_sc}
          &kota_id=${this.state.kota_sc}`
          );
          this.getKecamatan();
        }
      );
    } else {
      this.setState(
        {
          kota_sc: "",
          data_kecamatan: "",
          data_kelurahan: "",
          kecamatan_sc: "",
          kelurahan_sc: "",
        },
        function () {
          this.dt.loading();
          this.dt.resetPage(
            `&query=${this.state.text_sc}
          &provinsi_id=${this.state.provinsi_sc}`
          );
        }
      );
    }
  }

  filterKecamatan(e) {
    if (e) {
      this.setState(
        {
          kecamatan_sc: e.value,
          data_kelurahan: "",
          kelurahan_sc: "",
        },
        function () {
          this.dt.loading();
          this.dt.resetPage(
            `&query=${this.state.text_sc}
          &provinsi_id=${this.state.provinsi_sc}
          &kota_id=${this.state.kota_sc}
          &kecamatan_id=${this.state.kecamatan_sc}`
          );
          this.getKelurahan();
        }
      );
    } else {
      this.setState(
        {
          kecamatan_sc: "",
          data_kelurahan: "",
          kelurahan_sc: "",
        },
        function () {
          this.dt.loading();
          this.dt.resetPage(
            `&query=${this.state.text_sc}
          &provinsi_id=${this.state.provinsi_sc}
          &kota_id=${this.state.kota_sc}`
          );
        }
      );
    }
  }

  filterKelurahan(e) {
    if (e) {
      this.setState(
        {
          kelurahan_sc: e.value,
        },
        function () {
          this.dt.loading();
          this.dt.resetPage(
            `&query=${this.state.text_sc}
          &provinsi_id=${this.state.provinsi_sc}
          &kota_id=${this.state.kota_sc}
          &kecamatan_id=${this.state.kecamatan_sc}
          &kelurahan_id=${this.state.kelurahan_sc}`
          );
        }
      );
    } else {
      this.setState(
        {
          kelurahan_sc: "",
        },
        function () {
          this.dt.loading();
          this.dt.resetPage(
            `&query=${this.state.text_sc}
          &provinsi_id=${this.state.provinsi_sc}
          &kota_id=${this.state.kota_sc}
          &kecamatan_id=${this.state.kecamatan_sc}`
          );
        }
      );
    }
  }

  detail() {}

  render() {
    return (
      <>
        <main className="main" id="content" role="main">
          {/* alert */}
          <SweetAlert
            show={this.state.show_alert}
            title={this.state.title_alert}
            text={this.state.message_alert}
            type={this.state.icon_alert}
            onConfirm={() => this.setState({ show_alert: false })}
          />

          <div className="content container-fluid">
            {/* <!-- Page Header --> */}
            <div className="page-header">
              <div className="row align-items-end mb-3">
                <div className="col-sm mb-2 mb-sm-0">
                  <nav aria-label="breadcrumb"></nav>

                  <h1 className="page-header-title">Kode Wilayah</h1>
                </div>
              </div>
            </div>
            {/* <!-- End Page Header --> */}

            <Modal
              show={this.state.modalShow}
              dialogClassName="modal-lg"
              onHide={this.closeModal}
            >
              <Modal.Header>
                <Modal.Title>Insert Kode Wilayah</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-sm-6">
                    <label htmlFor="">Kode Provinsi</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Kode Provinsi"
                    />
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="">Provinsi</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Provinsi"
                    />
                  </div>
                  <div className="col-sm-6 mt-3">
                    <label htmlFor="">Kode Kabupaten/Kota</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Kode Kabupaten/Kota"
                    />
                  </div>
                  <div className="col-sm-6 mt-3">
                    <label htmlFor="">Kabupaten/Kota</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Kabupaten/Kota"
                    />
                  </div>
                  <div className="col-sm-6 mt-3">
                    <label htmlFor="">Kode Kecamatan</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Kode Kecamatan"
                    />
                  </div>
                  <div className="col-sm-6 mt-3">
                    <label htmlFor="">Kecamatan</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Kecamatan"
                    />
                  </div>
                  <div className="col-sm-6 mt-3">
                    <label htmlFor="">Kode Kelurahan</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Kode Kelurahan"
                    />
                  </div>
                  <div className="col-sm-6 mt-3">
                    <label htmlFor="">Kelurahan</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Kelurahan"
                    />
                  </div>
                  <div className="col-sm-6 mt-3">
                    <label htmlFor="">Kode Pos</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Kode Pos"
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.closeModal}>
                  Close
                </Button>
                <Button variant="primary" onClick={this.btnSave}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>

            <div className="card">
              {/* <!-- Header --> */}
              <div className="card-header">
                <div className="row justify-content-between align-items-center flex-grow-1">
                  <div className="col-12 col-md">
                    <form>
                      {/* <!-- Search --> */}
                      <div className="input-group input-group-merge input-group-borderless">
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                            <i className="tio-search"></i>
                          </div>
                        </div>
                        <input
                          id="datatableSearch"
                          onChange={this.searchText}
                          type="search"
                          className="form-control"
                          placeholder="Search Provinsi, Kota/Kabupaten, Kecamatan, Kelurahan/Desa"
                          aria-label="Search users"
                        />
                      </div>
                      {/* <!-- End Search --> */}
                    </form>
                  </div>

                  <div className="col-auto">
                    <div className="d-flex align-items-center">
                      {/* <!-- Datatable Info --> */}
                      <div
                        id="datatableCounterInfo"
                        className="mr-2"
                        style={{ display: "none" }}
                      >
                        <div className="d-flex align-items-center">
                          <span className="font-size-sm mr-3">
                            <span id="datatableCounter">0</span>
                            Selected
                          </span>

                          <a
                            className="btn btn-sm btn-outline-danger"
                            href="javascript:;"
                          >
                            <i className="tio-delete-outlined"></i> Delete
                          </a>
                        </div>
                      </div>
                      {/* <!-- End Datatable Info --> */}

                      {/* <!-- Filter Collapse Trigger --> */}
                      <a
                        className="btn btn-white dropdown-toggle"
                        data-toggle="collapse"
                        href="#filterSearchCollapse"
                        role="button"
                        aria-expanded={false}
                        onClick={()=> this.btnFilter()}
                      >
                        <i className="tio-filter-outlined mr-1"></i> Filters
                      </a>
                      {/* <!-- End Filter Collapse Trigger --> */}

                      <button
                        onClick={() => this.openModal()}
                        className="btn btn-primary ml-2"
                      >
                        Tambah Kode Wilayah
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Filter Search Collapse --> */}
              <div style={{display:this.state.display === false ? "none" : "block"}}>
                <div className="card-body">
                  <div className="row">
                    {this.state.data_provinsi !== "" ? (
                      <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
                        <label className="text-sm">Provinsi</label>
                        <Select
                          isClearable={true}
                          options={this.state.data_provinsi}
                          placeholder={"Provinsi"}
                          onChange={this.filterProvinsi}
                        />
                      </div>
                    ) : (
                      <div className="col-3 ">
                        <Skeleton height={15} className="bg-light" />
                        <Skeleton height={15} className="bg-light" />
                        <Skeleton height={15} className="bg-light" />
                      </div>
                    )}
                    {this.state.data_kota !== "" &&
                    this.state.provinsi_sc !== "" ? (
                      <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
                        <label className="text-sm">Kota</label>
                        <Select
                          isClearable={true}
                          options={this.state.data_kota}
                          placeholder={"Kota"}
                          onChange={this.filterKota}
                        />
                      </div>
                    ) : this.state.data_kota === "" &&
                      this.state.provinsi_sc !== "" ? (
                      <div className="col-3 ">
                        <Skeleton height={15} className="bg-light" />
                        <Skeleton height={15} className="bg-light" />
                        <Skeleton height={15} className="bg-light" />
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.data_kecamatan !== "" &&
                    this.state.kota_sc !== "" ? (
                      <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
                        <label className="text-sm">Kecamatan</label>
                        <Select
                          isClearable={true}
                          options={this.state.data_kecamatan}
                          placeholder={"Kecamatan"}
                          onChange={this.filterKecamatan}
                        />
                      </div>
                    ) : this.state.data_kecamatan === "" &&
                      this.state.kota_sc !== "" ? (
                      <div className="col-3 ">
                        <Skeleton height={15} className="bg-light" />
                        <Skeleton height={15} className="bg-light" />
                        <Skeleton height={15} className="bg-light" />
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.data_kelurahan !== "" &&
                    this.state.kecamatan_sc !== "" ? (
                      <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
                        <label className="text-sm">Kelurahan</label>
                        <Select
                          isClearable={true}
                          options={this.state.data_kelurahan}
                          placeholder={"Kelurahan"}
                          onChange={this.filterKelurahan}
                        />
                      </div>
                    ) : this.state.data_kelurahan === "" &&
                      this.state.kecamatan_sc !== "" ? (
                      <div className="col-3 ">
                        <Skeleton height={15} className="bg-light" />
                        <Skeleton height={15} className="bg-light" />
                        <Skeleton height={15} className="bg-light" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="card-body">
                <div className="row justify-content-between align-items-center flex-grow-1">
                  <div className="col-12 col-md">
                    <DataTable
                      ref={(dt) => {
                        this.dt = dt;
                      }}
                      url={this.state.url}
                      columns={[
                        {
                          index: "kode_provinsi",
                          label: "Kode",
                          maxWidth: "4rem ",
                        },
                        {
                          index: "provinsi",
                          label: "Provinsi",
                        },
                        {
                          index: "kode_kab_kota",
                          label: "Kode",
                          maxWidth: "4rem ",
                        },
                        {
                          index: "kab_kota",
                          label: "Kabupaten/Kota",
                        },
                        {
                          index: "kode_kecamatan",
                          label: "Kode",
                          maxWidth: "4rem ",
                        },
                        {
                          index: "kecamatan",
                          label: "Kecamatan",
                        },
                        {
                          index: "kode_kelurahan",
                          label: "Kode",
                        },
                        {
                          index: "kelurahan",
                          label: "Kelurahan",
                        },
                        {
                          index: "kodepos",
                          label: "Kode POS",
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
              {/* <!-- End Header --> */}
            </div>
          </div>
        </main>
      </>
    );
  }
}