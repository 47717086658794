import React from "react";
import { Button, Modal, Carousel, Form, Table } from 'react-bootstrap';
import axios from "axios";
import conf from '../../../../../config';
import tokenApi from "../../../../auth/Helpers";
import Swal from "sweetalert2";

export default class ContentBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      // modal
      url_import_data: `${conf.uri_backend}api/admin/v1/referensi/referensi-import`,
      url_import_data_save: `${conf.uri_backend}api/admin/v1/referensi/referensi-import-save`,
      modal_tambah: false,
      modal_detail: false,
      modal_cetak: false,
      modal_import: false,
      simpleLoading: false,
      import_file: '',

      // modal preview
      modal_preview: false,
      data_preview: []

    };

    this.showModalImport = this.showModalImport.bind(this);
    this.hideModalImport = this.hideModalImport.bind(this);
    this.Import = this.Import.bind(this);
    this.import_file = this.import_file.bind(this);

    // modal preview
    this.toggleModalPreview = this.toggleModalPreview.bind(this)
    this.importSave = this.importSave.bind(this)
  }

  componentDidMount() {
    this.setState({ active_menu: this.props.id });
  }

  alertMessage(e) {
    let message = null;

    switch (e.status) {
      case 422:
        message = e.data.messages[0];
        break;
      case 500:
        message = "Ada yang Salah, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 404:
        message = "Url Not Found, Coba Hubungi Admin 😊";
        break;
      case 401:
        message = "Nauthorized, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 400:
        message = "Koneksi Buruk, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 408:
        message = "Request Timeout, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 429:
        message = "Too Many Request, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
      case 405:
        message = "Method Tidak Diperbolehkan, Coba Beberapa Saat Lagi atau Segera Hubungi Admin 😊";
        break;
    }

    this.setState({
      show_alert: true,
      title_alert: "Gagal",
      icon_alert: "error",
      message_alert: message,
      simpleLoading: false,
      loading_delete: false,
    })
  }

  showModalImport() {
    this.setState({ modal_import: true });
  }

  hideModalImport() {
    this.setState({ modal_import: false });
  }

  import_file(e) {

    this.setState({ import_file: e.target.files[0] });
  }

  Import(event) {
    event.preventDefault();

    this.setState({ simpleLoading: true });

    const data = new FormData();
    data.append('file', this.state.import_file);
    axios.post(this.state.url_import_data, data, { headers: { Authorization: tokenApi } })
      .then(res => {
        if (res.status === false) {
          this.setState({
            show_alert: true,
            title_alert: "Gagal",
            icon_alert: "error",
            message_alert: res.data.messages[0],
            simpleLoading: true,
          });
        } else {
          const data = res.data.data
          this.setState({
            data_preview: data
          })

          this.hideModalImport();
          this.toggleModalPreview()
        }

        this.setState({ simpleLoading: false });
      })
      .catch(err => {
        this.alertMessage(err.response)
      })
  }

  toggleModalPreview() {
    this.setState({ modal_preview: !this.state.modal_preview })
  }

  importSave() {
    Swal.fire({
      title: 'Simpan Data',
      text: 'Anda yakin ingin menyimpan data tersebut?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonText: 'Ya'
    }).then(simpan => {
      if (simpan.value) {
        const data = {
          data: this.state.data_preview
        }

        // const data = new FormData()
        // data.append('data', this.state.data_preview)
        
        axios.post(this.state.url_import_data_save, data, { headers: { Authorization: tokenApi } })
        .then(res => {
          const data = res.data
          if (data.status) {
            this.setState({
              import_file: '',
              data_preview: []
            })
            Swal.fire(data.title[0], data.messages[0], data.icon[0])
            this.toggleModalPreview()
          }
        })
      }
    })
  }

  render() {
    return (
      <div>
        <button onClick={this.showModalImport} className="btn-default mx-1 px-4 justify-center font-medium rounded text-gray-800 items-center py-2 text-sm">
          <i className="bx bx-upload text-lg mr-1"></i> Upload
        </button>

        <Modal size="lg" show={this.state.modal_import} onHide={this.hideModalImport}>
          <Modal.Header closeButton>
            <Modal.Title>Upload Data</Modal.Title>
          </Modal.Header>
          <Form onSubmit={this.Import}>
            <Modal.Body>
              <div className="w-full flex flex-row items-center mb-3">
                <p className="text-gray-800">Download template excelnya dulu sebelum upload data</p>
                <a href="https://static.schoolmedia.id/public/assets/um/sample/contoh-import-guru.xlsx" download className="btn-default flex text-xs px-4 py-2 mr-1 rounded items-center">
                    Download
                    <i className="bx bx-download ml-1"></i>
                </a>
                <a href="https://help.schoolmedia.id/" target="_blank" rel="noreferrer" className="btn-default flex text-xs px-4 py-2 mr-1 rounded items-center">
                    Tutorial Upload
                    <i className="bx bx-info-circle ml-1"></i>
                </a>
              </div>

              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>File Excel</Form.Label>
                <Form.Control type="file" onChange={this.import_file} className="p-2 rounded border border-gray-500 focus:outline-none focus:shadow-outline" placeholder="Masukkan excel" />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" type="submit" disabled={this.state.simpleLoading ? true : false}>
                {this.state.simpleLoading ? "Tunggu..." : "Upload"}
              </Button>
              <Button variant="secondary" onClick={() => {this.setState({modal_import: false})}}>
                Batal
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        {/* Modal preview */}
        <Modal size="xl" show={this.state.modal_preview} onHide={this.toggleModalPreview}>
          <Modal.Header closeButton>
            <Modal.Title>Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table striped bordered hover>
              <thead className="text-center">
                <tr>
                  <th>JENJANG</th>
                  <th>JKMP_ID</th>
                  <th>KODE</th>
                  <th>MP</th>
                  <th>INDEKS</th>
                  <th>KUOTA SEMESTER 1</th>
                  <th>KUOTA SEMESTER 2</th>
                  <th>KODE PILIHAN</th>
                  <th>KURIKULUM</th>
                </tr>
              </thead>
              <tbody>
                {
                  console.log(this.state.data_preview)
                }
                {this.state.data_preview.map((item, index) => (

                  <tr>
                    <td>{item.jenjang}</td>
                    <td>{item.m_struktur_jkmp_id}</td>
                    <td>{item.kode_mp}</td>
                    <td>{item.mp}</td>
                    <td>{item.indeks_mp}</td>
                    <td>{item.semester_1}</td>
                    <td>{item.semester_2}</td>
                    <td>{item.kode_pilihan}</td>
                    <td>{item.kurikulum}</td>
                  </tr>
                  ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.importSave}>
              Simpan
            </Button>
            <Button variant="secondary" onClick={this.toggleModalPreview}>
              Batal
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  renderContent() {
    <button className="btn btn-success">import</button>
  }
}
