import React from 'react';
import SweetAlert from 'sweetalert2-react';
import { Link } from "react-router-dom";
import ContentBase from "./SpektrumItems/ContentBase.jsx";
import Skeleton from 'react-loading-skeleton';
import conf from '../../../../config';
import ContentImport from './SpektrumItems/import.jsx';


export default class StrukturKurikulum extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      // alert    
      show_alert        : false,
      title_alert       : '',
      message_alert     : '',
      icon_alert        : '',

      kelas: [
        {
          label: "SD / MI",
          value: 2,
          slug: "sd",
        },
        {
          label: "SMP / MTS",
          value: 3,
          slug: "smp",
        },
        {
          label: "SMA / MA",
          value: 4,
          slug: "sma",
        },
        {
          label: "SMK / MAK",
          value: 5,
          slug: "smk",
        },

         {
          label: "Penggerak SD / MI",
          value: 6,
          slug: "penggeraksd",
        },

        {
          label: "Penggerak SMP / MTS",
          value: 7,
          slug: "penggeraksmp",
        },

        {
          label: "Penggerak SMA / MA",
          value: 8,
          slug: "penggeraksma",
        },

        {
          label: "Penggerak SMK / MAK",
          value: 9,
          slug: "penggeraksmk",
        },
      ],
      active_class: this.props.match.params.jenjangLabel,
    }

    this.checkLogin = this.checkLogin.bind(this);
  }

  componentDidMount(){
    this.checkLogin();
  }
  
  checkLogin(){
    if (!localStorage.hasOwnProperty("token")) {
      localStorage.setItem('is_login',"");
      localStorage.setItem('token',"");
    }
    if(localStorage.getItem("token") === ""){
      window.location.replace(conf.uri_frontend+"admin_login");
    }
  }

  setActiveClass(v) {
    this.setState({
      active_class: '',
    }, function(){
      this.setState({active_class : v.slug})
    });
  }

  showKelas() {
    let c = this.state.kelas.map((v) => {
      if (this.state.active_class === v.slug) {
        return (
          <li className="nav-item">
            <span className="nav-link active">
              {v.label}
            </span>
          </li>
        );
      } else {
        return (
          <li className="nav-item">
            <Link
              to={`/referensi/struktur_kurikulum/${v.slug}`}
              className="nav-link"
              onClick={() => {
                this.setActiveClass(v);
              }}
            >
              {v.label}
            </Link>
          </li>

        )
      }
    });
    return <ul className="nav nav-pills">{c}</ul>;
  }

  render(){
    return(
      <>
        <main className="main" id="content" role="main">
          {/* alert */}
          <SweetAlert
              show={this.state.show_alert}
              title={this.state.title_alert}
              text={this.state.message_alert}
              type={this.state.icon_alert}
              onConfirm={() => this.setState({ show_alert: false })}
          />

          <div className="content container-fluid">
            {/* <!-- Page Header --> */}
            <div className="page-header">
              <div className="row align-items-end mb-3">
                <div className="col-sm mb-2 mb-sm-0">
                  <nav aria-label="breadcrumb">
                  </nav>

                  <h1 className="page-header-title">Struktur Kurikulum</h1>
                </div>

              </div>
            </div>
            {/* <!-- End Page Header --> */}

            <div className="card">
              {/* <!-- Header --> */}
              <div className="card-body">
                <div className="row justify-content-between align-items-center flex-grow-1">
                  <div className="col-12">
                    <ul className="nav nav-segment" id="expensesTab" role="tablist">
                      {this.showKelas()}
                    </ul>
                    
                    <hr/>
                    <ContentImport/> 
                    {this.state.active_class === '' ? (
                      <>
                        <div className="mb-6 mt-6">
                          <div className="flex flex-row mb-2 mt-2 px-4">
                            <div className="w-1/3 px-2"> <Skeleton height={20} className="bg-gray-400"/> </div>
                            <div className="w-1/3 px-2"> <Skeleton height={20} className="bg-gray-400"/> </div>
                            <div className="w-1/3 px-2"> <Skeleton height={20} className="bg-gray-400"/> </div>
                          </div>
                          <div className="flex flex-row mb-2 mt-2 px-4">
                            <div className="w-1/3 px-2"> <Skeleton height={20}/> </div>
                            <div className="w-1/3 px-2"> <Skeleton height={20}/> </div>
                            <div className="w-1/3 px-2"> <Skeleton height={20}/> </div>
                          </div>
                          <div className="flex flex-row mb-2 mt-2 px-4">
                            <div className="w-1/3 px-2"> <Skeleton height={20} className="bg-gray-400"/> </div>
                            <div className="w-1/3 px-2"> <Skeleton height={20} className="bg-gray-400"/> </div>
                            <div className="w-1/3 px-2"> <Skeleton height={20} className="bg-gray-400"/> </div>
                          </div>
                          <div className="flex flex-row mb-2 mt-2 px-4">
                            <div className="w-1/3 px-2"> <Skeleton height={20}/> </div>
                            <div className="w-1/3 px-2"> <Skeleton height={20}/> </div>
                            <div className="w-1/3 px-2"> <Skeleton height={20}/> </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <ContentBase id={this.state.active_class} />
                    )} 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    )
  }
}