import React from "react";
import conf from "../../../../config";
import SweetAlert from "sweetalert2-react";
import { Link } from "react-router-dom";
import ContentBase from "./MapelItems/ContentBase";
import Skeleton from "react-loading-skeleton";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import tokenApi from "../../../auth/Helpers";
import Swal from "sweetalert2";

export default class Mapel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      url: `${conf.uri_backend}api/admin/v1/referensi/kd/`,
      url_kmp: `${conf.uri_backend}api/v1/referensi/kmp/select/`,
      url_jkmp: `${conf.uri_backend}api/v1/referensi/jkmp/select/`,
      url_mp: `${conf.uri_backend}api/v1/referensi/mp/select/`,
      url_mpk: `${conf.uri_backend}api/v1/referensi/mpk/select/`,
      url_ki: `${conf.uri_backend}api/v1/referensi/ki/select/`,
      url_kd: `${conf.uri_backend}api/v1/referensi/kd/select/`,

      data_kmp: "",
      data_jkmp: "",
      data_mp: "",
      data_mpk: "",
      data_ki: "",
      data_kd: "",
      mp: "",

      jenjang_sc: "",
      kmp_sc: "",
      jkmp_sc: "",
      mp_sc: "",
      mpk_sc: "",
      ki_sc: "",
      kd_sc: "",

      jenjang: [
        {
          label: "SD / MI",
          value: 2,
          slug: "sd",
        },
        {
          label: "SMP / MTS",
          value: 3,
          slug: "smp",
        },
        {
          label: "SMA / MA",
          value: 4,
          slug: "sma",
        },
        {
          label: "SMK / MAK",
          value: 5,
          slug: "smk",
        },
      ],
      kelas: [
        {
          label: "SD / MI",
          value: 2,
          slug: "sd",
        },
        {
          label: "SMP / MTS",
          value: 3,
          slug: "smp",
        },
        {
          label: "SMA / MA",
          value: 4,
          slug: "sma",
        },
        {
          label: "SMK / MAK",
          value: 5,
          slug: "smk",
        },
      ],

      // alert
      show_alert: false,
      title_alert: "",
      message_alert: "",
      icon_alert: "",

      active_class: this.props.match.params.jenjangLabel,
    };

    this.checkLogin = this.checkLogin.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  componentDidMount() {
    this.checkLogin();
  }

  checkLogin() {
    if (!localStorage.hasOwnProperty("token")) {
      localStorage.setItem("is_login", "");
      localStorage.setItem("token", "");
    }
    if (localStorage.getItem("token") === "") {
      window.location.replace(conf.uri_frontend + "admin_login");
    }
  }

  btnSave = () => {
    let requestData = {
      jenjang: this.state.jenjang_sc,
      kmp: this.state.kmp_sc,
      jkmp: this.state.jkmp_sc,
      mp: this.state.data_mp,
    };

    let config = {
      headers: {
        Authorization: tokenApi,
      },
    };
    axios
      .post(`ini-enpoint-ya-mas-atok`, requestData, config)
      .then(function (res) {
        Swal.fire({
          title: "Berhasil",
          text: "Berhasil Menambahkan Mapel",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        });
      });
  };

  openModal() {
    this.setState({
      modalShow: true,
    });
  }
  closeModal = () => {
    this.setState({
      modalShow: false,
    });
  };

  setActiveClass(v) {
    this.setState(
      {
        active_class: "",
      },
      function () {
        this.setState({ active_class: v.slug });
      }
    );
  }
  changeJenjang(e) {
    if (e !== null) {
      this.setState({
        jenjang_sc: e.value,
      });
      var valJenjang = e.value;
      axios
        .get(this.state.url_kmp + `?jenjang_id=${valJenjang}`, {
          headers: {
            Authorization: tokenApi,
          },
        })
        .then((res) => {
          this.setState({ data_kmp: res.data });
        });
    } else {
      this.setState({ data_kmp: "", data_jkmp: "" });
    }
  }
  changeKmp(e) {
    if (e !== null) {
      this.setState({
        kmp_sc: e.value,
      });
      var valkmp = e.value;
      axios
        .get(
          this.state.url_jkmp +
            `?jenjang_id=${this.state.jenjang_sc}&kmp_id=${valkmp}`,
          {
            headers: {
              Authorization: tokenApi,
            },
          }
        )
        .then((res) => {
          this.setState({ data_jkmp: res.data });
        });
    } else {
      this.setState({ data_jkmp: "" });
    }
  }
  changeJkmp(e) {
    if (e !== null) {
      this.setState({
        jkmp_sc: e.value,
      });
    } else {
      this.setState({ data_jkmp: "" });
    }
  }
  changeMp(e) {
    var val = e.target.value;
    this.setState({ data_mp: val });
  }

  showKelas() {
    let c = this.state.kelas.map((v) => {
      if (this.state.active_class === v.slug) {
        return (
          <li className="nav-item">
            <span className="nav-link active">{v.label}</span>
          </li>
        );
      } else {
        return (
          <li className="nav-item">
            <Link
              to={`/referensi/mapel/${v.slug}`}
              className="nav-link"
              onClick={() => {
                this.setActiveClass(v);
              }}
            >
              {v.label}
            </Link>
          </li>
        );
      }
    });
    return <ul className="nav nav-pills">{c}</ul>;
  }

  render() {
    return (
      <>
        <main className="main" id="content" role="main">
          {/* alert */}
          <SweetAlert
            show={this.state.show_alert}
            title={this.state.title_alert}
            text={this.state.message_alert}
            type={this.state.icon_alert}
            onConfirm={() => this.setState({ show_alert: false })}
          />

          <div className="content container-fluid">
            {/* <!-- Page Header --> */}
            <div className="page-header">
              <div className="row align-items-end mb-3">
                <div className="col-sm mb-2 mb-sm-0">
                  <nav aria-label="breadcrumb"></nav>

                  <h1 className="page-header-title">Mapel</h1>
                </div>
              </div>
            </div>
            {/* <!-- End Page Header --> */}

            {/* Modal Insert */}
            <Modal
              show={this.state.modalShow}
              dialogClassName="modal-lg"
              onHide={this.closeModal}
            >
              <Modal.Header>
                <Modal.Title>Insert Mapel</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-sm-6">
                    <label className="text-sm">Jenjang</label>
                    <Select
                      isClearable={true}
                      options={this.state.jenjang}
                      placeholder={"Jenjang"}
                      onChange={(e) => this.changeJenjang(e)}
                      className="mt-1"
                    />
                  </div>
                  {this.state.data_kmp !== "" ? (
                    <div className="col-sm-6">
                      <label className="text-sm">Kelompok Mapel</label>
                      <Select
                        isClearable={true}
                        options={this.state.data_kmp}
                        placeholder={"Kelompok Mapel"}
                        onChange={(e) => this.changeKmp(e)}
                      />
                    </div>
                  ) : (
                    <div className="col-sm-6 ">
                      <label htmlFor="">Kelompok Mapel</label>
                      <Skeleton height={15} className="bg-light" />
                      <Skeleton height={15} className="bg-light" />
                    </div>
                  )}

                  {this.state.data_jkmp !== "" ? (
                    <div className="col-sm-6 mt-3">
                      <label className="text-sm">Jenis Mata Kelompok</label>
                      <Select
                        isClearable={true}
                        options={this.state.data_jkmp}
                        placeholder={"Jenis Mata Kelompok"}
                        onChange={(e) => this.changeJkmp(e)}
                      />
                    </div>
                  ) : (
                    <div className="col-sm-6 mt-3 ">
                      <label htmlFor="">Jenis Mata Kelompok</label>
                      <Skeleton height={15} className="bg-light" />
                      <Skeleton height={15} className="bg-light" />
                    </div>
                  )}
                  {this.state.data_jkmp !== "" ? (
                    <div className="col-sm-6 mt-3">
                      <label htmlFor="">Mata Pelajaran</label>
                      <label className="text-sm"></label>
                      <input
                        type="text"
                        onChange={(e) => this.changeMp(e)}
                        className="form-control"
                        placeholder="Mata Pelajaran"
                      />
                    </div>
                  ) : (
                    <div className="col-sm-6 mt-3 ">
                      <label htmlFor="">Mata Pelajaran</label>
                      <Skeleton height={15} className="bg-light" />
                      <Skeleton height={15} className="bg-light" />
                    </div>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.closeModal}>
                  Close
                </Button>
                <Button variant="primary" onClick={this.btnSave}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>

            <div className="card">
              {/* <!-- Header --> */}
              <div className="card-header">
                <div className="row justify-content-between align-items-center flex-grow-1">
                  <div className="col-12 col-md">
                    <ul className="nav nav-segment" id="expensesTab" role="tablist">
                      {this.showKelas()}
                    </ul>
                    <button
                      onClick={this.openModal.bind(this)}
                      className="btn btn-primary float-right"
                    >
                      Tambah Mapel
                    </button>
                    <hr />
                    {this.state.active_class === "" ? (
                      <>
                        <div className="mb-6 mt-6">
                          <div className="flex flex-row mb-2 mt-2 px-4">
                            <div className="w-1/3 px-2">
                              {" "}
                              <Skeleton
                                height={20}
                                className="bg-gray-400"
                              />{" "}
                            </div>
                            <div className="w-1/3 px-2">
                              {" "}
                              <Skeleton
                                height={20}
                                className="bg-gray-400"
                              />{" "}
                            </div>
                            <div className="w-1/3 px-2">
                              {" "}
                              <Skeleton
                                height={20}
                                className="bg-gray-400"
                              />{" "}
                            </div>
                          </div>
                          <div className="flex flex-row mb-2 mt-2 px-4">
                            <div className="w-1/3 px-2">
                              {" "}
                              <Skeleton height={20} />{" "}
                            </div>
                            <div className="w-1/3 px-2">
                              {" "}
                              <Skeleton height={20} />{" "}
                            </div>
                            <div className="w-1/3 px-2">
                              {" "}
                              <Skeleton height={20} />{" "}
                            </div>
                          </div>
                          <div className="flex flex-row mb-2 mt-2 px-4">
                            <div className="w-1/3 px-2">
                              {" "}
                              <Skeleton
                                height={20}
                                className="bg-gray-400"
                              />{" "}
                            </div>
                            <div className="w-1/3 px-2">
                              {" "}
                              <Skeleton
                                height={20}
                                className="bg-gray-400"
                              />{" "}
                            </div>
                            <div className="w-1/3 px-2">
                              {" "}
                              <Skeleton
                                height={20}
                                className="bg-gray-400"
                              />{" "}
                            </div>
                          </div>
                          <div className="flex flex-row mb-2 mt-2 px-4">
                            <div className="w-1/3 px-2">
                              {" "}
                              <Skeleton height={20} />{" "}
                            </div>
                            <div className="w-1/3 px-2">
                              {" "}
                              <Skeleton height={20} />{" "}
                            </div>
                            <div className="w-1/3 px-2">
                              {" "}
                              <Skeleton height={20} />{" "}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <ContentBase id={this.state.active_class} />
                    )}
                  </div>
                </div>
              </div>
              {/* <!-- End Header --> */}
            </div>
          </div>
        </main>
      </>
    );
  }
}
