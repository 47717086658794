import React from "react";
import Skeleton from 'react-loading-skeleton';

class SkeletonFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    return (
      <div className="col-3 "> 
        <Skeleton height={15} className="bg-light"/> 
        <Skeleton height={15} className="bg-light"/> 
        <Skeleton height={15} className="bg-light"/> 
      </div>
    );
  }
}

export default SkeletonFilter;
