import React from 'react';
import SweetAlert from 'sweetalert2-react';
// import { Link } from "react-router-dom";
import ContentJadwalPelajaran from "./JadwalPelajaranItems/ContentJadwalPelajaran";

// import Skeleton from 'react-loading-skeleton';
import conf from '../../../config';

export default class JadwalPelajaran extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // alert
      show_alert: false,
      title_alert: "",
      message_alert: "",
      icon_alert: "",

      menuTittle: "",
      activeMenu: "",

      display: false,
    };

    this.checkLogin = this.checkLogin.bind(this);
    this.showMenu = this.showMenu.bind(this);
  }

  componentDidMount() {
    this.checkLogin();
    this.setState(
      { activeMenu: this.props.match.params.menuLabel },
      function () {
        if (this.props.match.params.menuLabel === "jadwal_pelajaran") {
          this.setState({ menuTittle: "Jadwal Pelajaran" });
        }
      }
    );
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      this.checkLogin();
      this.setState(
        { activeMenu: this.props.match.params.menuLabel },
        function () {
          if (this.props.match.params.menuLabel === "jadwal_pelajaran") {
            this.setState({ menuTittle: "Jadwal Pelajaran" });
          }
        }
      );
    }
  }

  checkLogin() {
    if (!localStorage.hasOwnProperty("token")) {
      localStorage.setItem("is_login", "");
      localStorage.setItem("token", "");
    }
    if (localStorage.getItem("token") === "") {
      window.location.replace(conf.uri_frontend + "admin_login");
    }
  }

  showMenu() {
    if (this.props.match.params.menuLabel !== "") {
      if (this.props.match.params.menuLabel === "jadwal_pelajaran") {
        return <ContentJadwalPelajaran />;
      }
    }
  }

  render() {
    return (
      <>
        <main className="main" id="content" role="main">
          {/* alert */}
          <SweetAlert
            show={this.state.show_alert}
            title={this.state.title_alert}
            text={this.state.message_alert}
            type={this.state.icon_alert}
            onConfirm={() => this.setState({ show_alert: false })}
          />

          <div className="content container-fluid">
            {/* <!-- Page Header --> */}
            <div className="page-header">
              <div className="row align-items-end mb-3">
                <div className="col-sm mb-2 mb-sm-0">
                  <nav aria-label="breadcrumb"></nav>

                  <h1 className="page-header-title">
                    {this.state.menuTittle !== ""
                      ? this.state.menuTittle
                      : "...."}
                  </h1>
                </div>
              </div>
            </div>
            {/* <!-- End Page Header --> */}

            <div className="card mt-4 pb-4">{this.showMenu()}</div>
          </div>
        </main>
      </>
    );
  }
}