import DataTable from '../../../partials/table/DataTable';
import React from "react";
import conf from '../../../../config';
import Select from 'react-select';
import axios from 'axios';
import SkeletonFilter from "../../../partials/SkeletonFilter";
import DetailMapel from "./DetailMapel";

export default class ContentPenugasanLainnya extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: `${conf.uri_backend}api/admin/v1/penugasan/table_penugasan_guru`,
      url_penugasan_slc: `${conf.uri_backend}api/admin/v1/penugasan/select_penugasan?status=GU`,
      url_pengampu_slc: `${conf.uri_backend}api/admin/v1/penugasan/select_pengampu`,
      url_insitutis_slc: `${conf.uri_backend}api/admin/v1/institusi/select_institusi`,
      penugasan_sc: "",
      pengampu_sc: "",
      ta_sc: "",
      institusi_sc: "",
      text_sc: "",
      dataPenugasan: "",
      dataPengampu: "",
      dataInstitusi: "",
      dataTa: "",
      dataMapel: "",

      display: false,
    };

    this.getPenugasan = this.getPenugasan.bind(this);
    this.getPengampu = this.getPengampu.bind(this);
    this.getTa = this.getTa.bind(this);
    this.getInstitusi = this.getInstitusi.bind(this);
    this.filterTa = this.filterTa.bind(this);
    this.filterPengampu = this.filterPengampu.bind(this);
    this.filterInstitusi = this.filterInstitusi.bind(this);
    this.filterPenugasan = this.filterPenugasan.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.searchText = this.searchText.bind(this);
    this.detailShow = this.detailShow.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  componentDidMount() {
    this.getPenugasan();
    this.getInstitusi();
    this.getTa();
    this.getPengampu();
    this.dt.loading();
    this.dt.fetchEntities();
  }

  getTa() {
    var ta = [];
    var u = new Date().getFullYear() + 1;
    var tahun_sekarang = new Date().getFullYear() + 1;

    for (var i = tahun_sekarang; i >= 2005; i--) {
      u = u - 1;
      ta.push({ value: u + " / " + i, label: u + " / " + i });
    }
    this.setState({ dataTa: ta });
  }

  btnFilter = () => {
    this.setState({
      display: !this.state.display,
    });
  };

  getPenugasan() {
    axios.get(this.state.url_penugasan_slc).then((res) => {
      this.setState({ dataPenugasan: res.data });
    });
  }

  getPengampu() {
    axios.get(this.state.url_pengampu_slc).then((res) => {
      this.setState({ dataPengampu: res.data });
    });
  }

  getInstitusi() {
    axios.get(this.state.url_insitutis_slc).then((res) => {
      this.setState({ dataInstitusi: res.data });
    });
  }

  filterInstitusi(e) {
    if (e) {
      this.setState({ institusi_sc: e.value });
    } else {
      this.setState({ institusi_sc: "" });
    }
  }

  filterTa(e) {
    if (e) {
      this.setState({ ta_sc: e.value });
    } else {
      this.setState({ ta_sc: "" });
    }
  }

  filterPenugasan(e) {
    if (e) {
      this.setState({ penugasan_sc: e.value });
    } else {
      this.setState({ penugasan_sc: "" });
    }
  }

  filterPengampu(e) {
    if (e) {
      this.setState({ pengampu_sc: e.value });
    } else {
      this.setState({ pengampu_sc: "" });
    }
  }

  resetFilter() {
    this.setState(
      {
        penugasan_sc: "",
        institusi_sc: "",
        ta_sc: "",
        pengampu_sc: "",
        dataTa: "",
        dataPenugasan: "",
        dataPengampu: "",
        dataInstitusi: "",
      },
      function () {
        this.getTa();
        this.getPenugasan();
        this.getPengampu();
        this.getInstitusi();
        this.dt.loading();
        this.dt.resetPage(`
        &query=${this.state.text_sc}
        &institusi=${this.state.institusi_sc}
        &mapel=${this.state.pengampu_sc}
        &penugasan=${this.state.penugasan_sc}
        &tahun_ajaran=${this.state.ta_sc}
      `);
      }
    );
  }

  setFilter() {
    this.dt.loading();
    this.dt.resetPage(`
      &query=${this.state.text_sc}
      &institusi=${this.state.institusi_sc}
      &mapel=${this.state.pengampu_sc}
      &penugasan=${this.state.penugasan_sc}
      &tahun_ajaran=${this.state.ta_sc}
    `);
  }

  searchText(e) {
    e.preventDefault();
    this.setState({ text_sc: e.target.value }, function () {
      this.dt.loading();
      this.dt.resetPage(`
        &query=${this.state.text_sc}
        &institusi=${this.state.institusi_sc}
        &mapel=${this.state.pengampu_sc}
        &penugasan=${this.state.penugasan_sc}
        &tahun_ajaran=${this.state.ta_sc}
      `);
    });
  }

  detailShow(data) {
    if (data) {
      this.setState({ dataMapel: data });
    } else {
      this.setState({ dataMapel: "" });
    }
  }

  hideModal(status) {
    if (status === true) {
      this.setState({
        dataMapel: "",
        loadingModal: false,
      });
    }
  }

  render() {
    return (
      <div className="col-12 mt-4">
        <div className="input-group input-group-merge input-group-borderless">
          <div className="input-group-prepend">
            <div className="input-group-text">
              <i className="tio-search"></i>
            </div>
          </div>
          <input
            id="datatableSearch"
            onChange={this.searchText}
            type="search"
            className="form-control"
            placeholder="Cari Nama Penugasan, Nama Guru, Nama Institusi"
            aria-label="Search users"
          />
          <div className="col-auto">
            <div className="d-flex align-items-center">
              {/* <!-- Filter Collapse Trigger --> */}
              <a
                className="btn btn-white dropdown-toggle"
                data-toggle="collapse"
                href="#filterSearchCollapse"
                role="button"
                onClick={()=>this.btnFilter()}
              >
                <i className="tio-filter-outlined mr-1"></i> Filters
              </a>
              {/* <!-- End Filter Collapse Trigger --> */}
            </div>
          </div>
        </div>

        {/* <!-- Filter Search Collapse --> */}
        <div style={{display:this.state.display === false ? "none" : "block"}}>
          <form>
            <div className="row">
              {this.state.dataInstitusi !== "" ? (
                <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
                  <label className="text-sm">Institusi</label>
                  <Select
                    placeholder={"Pilih Institusi"}
                    isClearable={true}
                    options={this.state.dataInstitusi}
                    onChange={this.filterInstitusi}
                  />
                </div>
              ) : (
                <SkeletonFilter />
              )}
              {this.state.dataTa !== "" ? (
                <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
                  <label className="text-sm">Tahun Ajaran</label>
                  <Select
                    placeholder={"Pilih Tahun Ajaran"}
                    isClearable={true}
                    options={this.state.dataTa}
                    onChange={this.filterTa}
                  />
                </div>
              ) : (
                <SkeletonFilter />
              )}
              {this.state.dataPenugasan !== "" ? (
                <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
                  <label className="text-sm">Penugasan</label>
                  <Select
                    placeholder={"Pilih Penugasan"}
                    isClearable={true}
                    options={this.state.dataPenugasan}
                    onChange={this.filterPenugasan}
                  />
                </div>
              ) : (
                <SkeletonFilter />
              )}
              {this.state.dataPengampu !== "" ? (
                <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
                  <label className="text-sm">Pengampu</label>
                  <Select
                    placeholder={"Pilih Pengampu"}
                    isClearable={true}
                    options={this.state.dataPengampu}
                    onChange={this.filterPengampu}
                  />
                </div>
              ) : (
                <SkeletonFilter />
              )}
            </div>
            {/* <!-- End Row --> */}

            <div className="d-flex justify-content-end">
              <button
                onClick={this.resetFilter}
                type="button"
                className="btn btn-white mr-2"
              >
                Reset
              </button>
              <button
                onClick={this.setFilter}
                type="button"
                className="btn btn-primary"
              >
                Cari
              </button>
            </div>
          </form>
          <hr />
        </div>

        {this.state.dataMapel !== "" ? (
          <DetailMapel data={this.state.dataMapel} hideModal={this.hideModal} />
        ) : (
          ""
        )}
        <hr />
        <DataTable
          ref={(dt) => {
            this.dt = dt;
          }}
          action={{
            jenis_kelamin: (col, data) => {
              var jekel = "";
              if (col.jenis_kelamin === 1) {
                jekel = "Laki - laki";
              } else if (col.jenis_kelamin === 0) {
                jekel = "Perempuan";
              } else {
                jekel = "Belum diketahui";
              }
              return <p className="text-sm">{jekel}</p>;
            },

            mata_pelajaran: (col, data) => {
              return (
                <button
                  className="btn btn-sm btn-white"
                  onClick={this.detailShow.bind(this, data.pengampu)}
                >
                  <i className="tio-book-outlined"></i> Detail
                </button>
              );
            },
          }}
          url={this.state.url}
          columns={[
            {
              index: "nama_institusi",
              label: "Nama Sekolah",
            },
            {
              index: "tahun_ajaran",
              label: "Tahun Ajaran",
            },
            {
              index: "kode_pendaftaran",
              label: "KODE PENDAFTARAN",
            },
            {
              index: "nama_guru",
              label: "NAMA GURU",
            },
            {
              index: "inisial",
              label: "INISIAL",
            },
            {
              index: "tanggal_lahir",
              label: "TANGGAL LAHIR",
            },
            {
              index: "jenis_kelamin",
              label: "JENIS KELAMIN",
            },
            {
              index: "nama_penugasan",
              label: "PENUGASAN",
            },
            {
              index: "mata_pelajaran",
              label: "MATA PELAJARAN",
            },
          ]}
        />
      </div>
    );
  }
}