import DataTable from '../../../../partials/table/DataTable';
import React from "react";
import conf from '../../../../../config';
import Select from 'react-select';
import tokenApi from "../../../../auth/Helpers";
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import { Modal, Tabs, Tab } from 'react-bootstrap';



export default class ContentSmk extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url             : `${conf.uri_backend}api/admin/v1/referensi/spektrum`,
      url_detail      : `${conf.uri_backend}api/admin/v1/referensi/spektrum/`,
      data_bidang     : '',
      data_program    : '',
      data_kompetensi : '',

      bidang_sc       : '',
      program_sc      : '',
      kompetensi_sc   : '',

      showModal  : false,
      data_mapel : '',
      id         : '',
      dataX      : '',
      dataXI     : '',
      dataXII    : '',
      dataXIII   : '',
    };

    this.getBidang        = this.getBidang.bind(this);
    this.getProgram       = this.getProgram.bind(this);
    this.getKompetensi    = this.getKompetensi.bind(this);
    this.filterBidang     = this.filterBidang.bind(this);
    this.filterProgram    = this.filterProgram.bind(this);
    this.filterKompetensi = this.filterKompetensi.bind(this);

    this.showDataPerItem    = this.showDataPerItem.bind(this);
    this.showData    = this.showData.bind(this);
    this.setShowModal = this.setShowModal.bind(this);
  }

  componentDidMount() {
    let q = "";
    if (this.props.query) {
      q = `&query=${this.props.query}`;
    }
    var jenjang= this.props.jenjang;
    this.dt.loading();
    this.dt.fetchEntities(
      `&jenjang_id=${jenjang}${q}`
    );
    this.getBidang();
  }
    
  componentWillReceiveProps(nextProps) {
    let q = "";
    if (nextProps.query) {
      q = `&query=${nextProps.query}`;
    }
    var jenjang = this.props.jenjang;
    this.dt.loading();
    this.dt.fetchEntities(
      `&jenjang_id=${jenjang}${q}`
    );
  }

  showData(){
    if(this.state.id !== ''){
      return(
        <Tabs defaultActiveKey={this.props.kelas} id="uncontrolled-tab-example">
          {this.state.dataX !== '' ? (this.showTab(this.state.dataX, "X")) : ''}
          {this.state.dataXI !== '' ? (this.showTab(this.state.dataXI, "XI")) : ''}
          {this.state.dataXII !== '' ? (this.showTab(this.state.dataXII, "XII")) : ''}
          {this.state.dataXIII !== '' ? (this.showTab(this.state.dataXIII, "XIII")) : ''}
        </Tabs>
      )
      
    }
  }

  showDataPerItem(kelas, id){
    axios.get(`${this.state.url_detail}${id}/detail?kelas=${kelas}`, {
      headers: {
        Authorization: tokenApi
      }
    }).then(res => {
      if(kelas === "X" ? this.setState({dataX : res.data}) : "");
      if(kelas === "XI" ? this.setState({dataXI : res.data}) : "");
      if(kelas === "XII" ? this.setState({dataXII : res.data}) : "");
      if(kelas === "XIII" ? this.setState({dataXIII : res.data}) : "");
    });
  }

  showTab(data, kelas){
    return(
      <Tab className="text-uppercase" eventKey={kelas} title={kelas}>
        <div className="col-12">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">KODE</th>
                <th scope="col">KMP</th>
                <th scope="col">KODE</th>
                <th scope="col">JKMP</th>
                <th scope="col">KODE</th>
                <th scope="col">MATA PELAJARAN</th>
                <th scope="col">INDEKS MPK</th>
                <th scope="col">KUOTA SEMESTER 1</th>
                <th scope="col">KUOTA SEMESTER 2</th>
              </tr>
            </thead>
            <tbody>
              {data.nasional.map((peminatan, index) => {
                return(
                  <tr>
                    <td>{peminatan.kode_kmp} - {peminatan.id_mpk}</td>
                    <td>{peminatan.kmp}</td>
                    <td>{peminatan.kode_jkmp}</td>
                    <td>{peminatan.jkmp}</td>
                    <td>{peminatan.kode_mp}</td>
                    <td>{peminatan.mp}</td>
                    <td>{peminatan.indeks_mpk}</td>
                    <td>{peminatan.semester_1}</td>
                    <td>{peminatan.semester_2}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </Tab>
    )
  }

  setShowModal(show, id, lama_belajar) {
    if(show === true){
      this.setState({
        id         : id,
        showModal  : show
      }, function(){
        this.showDataPerItem("X", this.state.id)
        this.showDataPerItem("XI", this.state.id)
        this.showDataPerItem("XII", this.state.id)
        if(lama_belajar === "4 th"){
          this.showDataPerItem("XIII", this.state.id)
        }
      });
    } else {
      this.setState({ 
        id         : '',
        showModal  : show,
        dataX      : '',
        dataXI     : '',
        dataXII    : '',
        dataXIII   : '',
      });
    }
  }

  getBidang() {
    axios.get(`${this.state.url}?jenjang_id=5&parent_id=0`, {
      headers: {
        Authorization: tokenApi
      }
    }).then((res) => {
      this.setState({data_bidang: res.data});
    });
  }

  getProgram() {
    axios.get(`${this.state.url}?jenjang_id=5&parent_id=${this.state.bidang_sc}`, {
      headers: {
        Authorization: tokenApi
      }
    }).then((res) => {
      this.setState({ data_program: res.data });
    });
  }

  getKompetensi() {
    axios.get(`${this.state.url}?jenjang_id=5&parent_id=${this.state.program_sc}`, {
      headers: {
        Authorization: tokenApi
      }
    }).then((res) => {
      this.setState({ data_kompetensi: res.data });
    });
  }

  filterBidang(e){
    if(e){
      this.setState({ 
        bidang_sc       : e.value,
        data_program    : '',
        data_kompetensi : '',
        program_sc      : '',
        kompetensi_sc   : '',
      }, function(){
        this.getProgram();
        this.dt.loading();
        this.dt.resetPage(`&jenjang_id=5&bidang_id=${this.state.bidang_sc}`)
      })
    } else {
      this.setState({ 
        bidang_sc       : '',
        data_program    : '',
        data_kompetensi : '',
        program_sc      : '',
        kompetensi_sc   : '',
      }, function(){
        this.getProgram();
        this.dt.loading();
        this.dt.resetPage(`&jenjang_id=5`)
      })
    }
  }

  filterProgram(e){
    if(e){
      this.setState({ 
        program_sc      : e.value,
        data_kompetensi : '',
        kompetensi_sc   : '',
      }, function(){
        this.getKompetensi();
        this.dt.loading();
        this.dt.resetPage(`
          &jenjang_id=5&bidang_id=${this.state.bidang_sc}
          &program_id=${this.state.program_sc}
        `)
      })
    } else {
      this.setState({ 
        data_kompetensi : '',
        program_sc      : '',
        kompetensi_sc   : '',
      }, function(){
        this.dt.loading();
        this.dt.resetPage(`
          &jenjang_id=5
          &bidang_id=${this.state.bidang_sc}
        `)
      })
    }
  }

  filterKompetensi(e){
    if(e){
      this.setState({ 
        kompetensi_sc   : e.value,
      }, function(){
        this.getKompetensi();
        this.dt.loading();
        this.dt.resetPage(`
          &jenjang_id=5
          &bidang_id=${this.state.bidang_sc}
          &program_id=${this.state.program_sc}
          &kompetensi_id=${this.state.kompetensi_sc}
        `)
      })
    } else {
      this.setState({ 
        kompetensi_sc   : '',
      }, function(){
        this.dt.loading();
        this.dt.resetPage(`
          &jenjang_id=5
          &bidang_id=${this.state.bidang_sc}
          &program_id=${this.state.program_sc}
        `)
      })
    }
  }

  render() {
    return (
      <>
        <div className="col-12 mt-4 mb-4">
          <Modal
            size="xl"
            show={this.state.showModal}
            onHide={() => this.setShowModal(false)}
          >
            <Modal.Header>
              <div className="col-12 p-2 mx-2">
                Daftar Mata Pelajaran
              </div>
            </Modal.Header>
            <Modal.Body>
              {this.showData()}
            </Modal.Body>
            <Modal.Footer>
              <div className="col-12 text-right">
                <button
                  className="btn btn-default px-4 py-2 rounded-full items-center"
                  type="button"
                  style={{ transition: "all .15s ease" }}
                  onClick={() => this.setShowModal(false)}
                >
                  Tutup
                </button>
              </div>
            </Modal.Footer>
          </Modal>
          <div className="row">
            {this.state.data_bidang !== '' ? (
              <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
                <label className="text-sm">Bidang Keahlian</label>
                <Select
                  isClearable={true}
                  options={this.state.data_bidang}
                  placeholder={"Bidang Keahlian"}
                  onChange={this.filterBidang}
                />
              </div>
            ) : (
              <div className="col-3 "> 
                <Skeleton height={15} className="bg-light"/> 
                <Skeleton height={15} className="bg-light"/> 
                <Skeleton height={15} className="bg-light"/> 
              </div>
            )}
            {this.state.data_program !== '' && this.state.bidang_sc !== '' ? (
              <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
                <label className="text-sm">Program Keahlian</label>
                <Select
                  isClearable={true}
                  options={this.state.data_program}
                  placeholder={"Program Keahlian"}
                  onChange={this.filterProgram}
                />
              </div>
            ) : (
              this.state.data_program === '' && this.state.bidang_sc !== '' ? (
                <div className="col-3 "> 
                  <Skeleton height={15} className="bg-light"/> 
                  <Skeleton height={15} className="bg-light"/> 
                  <Skeleton height={15} className="bg-light"/> 
                </div>
              ) :("")
            )}
            {this.state.data_kompetensi !== '' && this.state.program_sc !== '' ? (
              <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
                <label className="text-sm">Kompetensi Keahlian</label>
                <Select
                  isClearable={true}
                  options={this.state.data_kompetensi}
                  placeholder={"Kompetensi Keahlian"}
                  onChange={this.filterKompetensi}
                />
              </div>
            ) : (
              this.state.data_kompetensi === '' && this.state.program_sc !== '' ? (
                <div className="col-3 "> 
                  <Skeleton height={15} className="bg-light"/> 
                  <Skeleton height={15} className="bg-light"/> 
                  <Skeleton height={15} className="bg-light"/> 
                </div>
              ) :("")
            )}
          </div>
        </div>
        <DataTable
          ref={(dt) => {
            this.dt = dt;
          }}
          action={{
            detail:(col, data) => {
              return (
                <div className="text-gray-700 px-4 py-1 flex items-center">
                  <button onClick={this.setShowModal.bind(this, true, data.kompetensi_id, data.lama_belajar)} className="btn btn-sm btn-outline-danger" >
                    Detail
                  </button> 
                </div>
              );
            }
          }}
          url={this.state.url}
          columns={[
          {
            index: "program_kode",
            label: "Kode",
          },
          {
            index: "program_nama",
            label: "Program Keahlian",
          },
          {
            index: "kompetensi_kode",
            label: "Kode",
          },
          {
            index: "kompetensi_nama",
            label: "Kompetensi",
          },
          {
            index: "kompetensi_no",
            label: "No Spektrum",
          },
          {
            index: "lama_belajar",
            label: "Lama Belajar",
          },
          {
            index: "detail",
            label: "Aksi",
          },
          ]}
        />
      </>
    );
  }
}