import React from 'react';
import SweetAlert from 'sweetalert2-react';
import { Link } from "react-router-dom";
import ContentGuru from "./UserItems/ContentGuru";
import ContentSiswa from "./UserItems/ContentSiswa";
import ContentLainnya from "./UserItems/ContentLainnya";
import ContentOrtu from "./UserItems/ContentOrtu";
import ContentPublik from "./UserItems/ContentPublik";
import axios from 'axios';

import Skeleton from 'react-loading-skeleton';
import conf from '../../../config';

export default class SarprasManagement extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      user_count: null,
      user_count_aktivasi: null,

      total: "",
      total_activasi: "",
      // alert
      show_alert: false,
      title_alert: "",
      message_alert: "",
      icon_alert: "",

      menuTittle: "",
      activeMenu: "",
    };

    this.checkLogin = this.checkLogin.bind(this);
    this.showMenu   = this.showMenu.bind(this);
    this.getMenuLabel   = this.getMenuLabel.bind(this);
  }

  componentDidMount(){
    this.checkLogin();
    this.getMenuLabel();
    this.setState({ activeMenu : this.props.match.params.menuLabel}, function(){
      if(this.props.match.params.menuLabel === 'guru'){
 
        this.setState({menuTittle : "Guru"});
      } else if(this.props.match.params.menuLabel === 'siswa') {

        this.setState({menuTittle : "Siswa"});
      } else if(this.props.match.params.menuLabel === 'lainnya') {
 
        this.setState({menuTittle : "Lainnya"});
      } else if(this.props.match.params.menuLabel === 'ortu') {
         
        this.setState({menuTittle : "Ortu"});
      } else if(this.props.match.params.menuLabel === 'publik') {
         
        this.setState({menuTittle : "Publik"});
      } 


     
    });
  }

  getMenuLabel(){
     this.setState({ activeMenu : this.props.match.params.menuLabel}, function(){
      if(this.props.match.params.menuLabel === 'guru'){
         axios
           .get(`${conf.uri_backend}api/admin/v1/user/count-user?type=GU`)
           .then((res) => {
             this.setState({ total: res.data });
           });

         axios
           .get(`${conf.uri_backend}api/admin/v1/user/count-activasi?type=GU`)
           .then((res) => {
             this.setState({ total_activasi: res.data });
           });
       
      } else if(this.props.match.params.menuLabel === 'siswa') {
        axios
          .get(`${conf.uri_backend}api/admin/v1/user/count-user?type=SI`)
          .then((res) => {
            this.setState({ total: res.data });
          });

        axios
          .get(`${conf.uri_backend}api/admin/v1/user/count-activasi?type=SI`)
          .then((res) => {
            this.setState({ total_activasi: res.data });
          });
       
      } else if(this.props.match.params.menuLabel === 'lainnya') {
        axios
          .get(`${conf.uri_backend}api/admin/v1/user/count-user?type=LA`)
          .then((res) => {
            this.setState({ total: res.data });
          });

        axios
          .get(`${conf.uri_backend}api/admin/v1/user/count-activasi?type=LA`)
          .then((res) => {
            this.setState({ total_activasi: res.data });
          });
   
      } else if(this.props.match.params.menuLabel === 'ortu') {
         axios
           .get(`${conf.uri_backend}api/admin/v1/user/count-user?type=OW`)
           .then((res) => {
             this.setState({ total: res.data });
           });

         axios
           .get(`${conf.uri_backend}api/admin/v1/user/count-activasi?type=OW`)
           .then((res) => {
             this.setState({ total_activasi: res.data });
           });
    
      } else if(this.props.match.params.menuLabel === 'publik') {
           axios
             .get(`${conf.uri_backend}api/admin/v1/user/count-user?type=PU`)
             .then((res) => {
               this.setState({ total: res.data });
             });

           axios
             .get(`${conf.uri_backend}api/admin/v1/user/count-activasi?type=PU`)
             .then((res) => {
               this.setState({ total_activasi: res.data });
             });
   
      } 

  });
  }


  componentWillReceiveProps(nextProps){
    this.checkLogin();
    this.getMenuLabel();
    this.setState({ activeMenu : nextProps.match.params.menuLabel}, function(){
      if(nextProps.match.params.menuLabel === 'guru'){
        this.setState({menuTittle: "Guru",});
      } else if(nextProps.match.params.menuLabel === 'siswa') {
        this.setState({menuTittle : "Siswa"});
      } else if(nextProps.match.params.menuLabel === 'lainnya') {
        this.setState({menuTittle : "Lainnya"});
      } else if(nextProps.match.params.menuLabel === 'ortu') {
        this.setState({menuTittle : "Ortu"});
      } else if(nextProps.match.params.menuLabel === 'publik') {
        this.setState({menuTittle : "Publik"});
      } 

     
    });
  }
  
  checkLogin(){
    if (!localStorage.hasOwnProperty("token")) {
      localStorage.setItem('is_login',"");
      localStorage.setItem('token',"");
    }
    if(localStorage.getItem("token") === ""){
      window.location.replace(conf.uri_frontend+"admin_login");
    }
  }

  showMenu() {
    if(this.props.match.params.menuLabel !== ''){
      if(this.props.match.params.menuLabel === 'guru'){
        
        return (<ContentGuru />);
      } else if(this.props.match.params.menuLabel === 'siswa') {
        
        return (<ContentSiswa />);
      } else if(this.props.match.params.menuLabel === 'lainnya') {
        
        return (<ContentLainnya />);
      } else if(this.props.match.params.menuLabel === 'ortu') {
       
        return (<ContentOrtu />);
      } else if(this.props.match.params.menuLabel === 'publik') {
     
        return (<ContentPublik />);
      } 
    }
  }

  render(){
    // console.log(this.state.total);
    return (
      <>
        <main className="main" id="content" role="main">
          {/* alert */}
          <SweetAlert
            show={this.state.show_alert}
            title={this.state.title_alert}
            text={this.state.message_alert}
            type={this.state.icon_alert}
            onConfirm={() => this.setState({ show_alert: false })}
          />

          <div className="content container-fluid">
            {/* <!-- Page Header --> */}
            <div className="page-header">
              <div className="row align-items-end mb-3">
                <div className="col-sm mb-2 mb-sm-0">
                  <nav aria-label="breadcrumb"></nav>

                  <h1 className="page-header-title">
                    {this.state.menuTittle !== ""
                      ? this.state.menuTittle
                      : "...."}
                  </h1>
                </div>
              </div>
            </div>
            {/* <!-- End Page Header --> */}

            <div className="container-fluid mt-3">
              <div className="row">
                <div className="card col-sm-3">
                  <div className="card-body">
                    <h5 className="card-title">Total User</h5>

                    <i
                      className="tio-user-outlined nav-icon float-right"
                      style={{ fontSize: "80px" }}
                    ></i>

                    <p className="card-text">
                      <h1
                        className="mt-5 text text-muted"
                        style={{ fontSize: "30px" }}
                      >
                        {this.state.total}
                      </h1>
                    </p>
                  </div>
                </div>
                <div className="card col-sm-3 ml-5">
                  <div className="card-body">
                    <h5 className="card-title">Total Aktivasi User</h5>

                    <i
                      className="tio-user-outlined nav-icon float-right"
                      style={{ fontSize: "80px" }}
                    ></i>

                    <p className="card-text">
                      <h1
                        className="mt-5 text text-muted"
                        style={{ fontSize: "30px" }}
                      >
                        {this.state.total_activasi}
                      </h1>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mt-4 pb-4">{this.showMenu()}</div>
          </div>
        </main>
      </>
    );
  }
}