import React from "react";
import { Link } from "react-router-dom";
import conf from '../../config';

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      labelItem: null,
      typeDropdown: null,
    };
  }

  showDropdown(name) {
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
    } else {
      this.setState({ isOpen: name });
    }
  }

  logout(){
    localStorage.setItem('is_login',"");
    localStorage.setItem('token',"");
    window.location.replace(conf.uri_frontend+"admin_login");
  }

  render() {
    return (
      <>
      <header id="header" className="navbar navbar-expand-lg navbar-fixed navbar-height navbar-flush navbar-container navbar-bordered">
        <div className="navbar-nav-wrap">
          <div className="navbar-brand-wrapper">
            {/* {/* <!-- Logo --> */}
            <a className="navbar-brand" href="/index.html" aria-label="Front">
              <img className="navbar-brand-logo" src="/assets/svg/logos/logo.png" alt="Logo"/>
              <img className="navbar-brand-logo-mini" src="/assets/svg/logos/logo-short.png" alt="Logo"/>
            </a>
            {/* {/* <!-- End Logo --> */}
          </div>

          <div className="navbar-nav-wrap-content-left">
            {/* {/* <!-- Navbar Vertical Toggle --> */}
            <button type="button" className="js-navbar-vertical-aside-toggle-invoker close mr-3">
              <i className="tio-first-page navbar-vertical-aside-toggle-short-align" data-toggle="tooltip" data-placement="right" title="Collapse"></i>
              <i className="tio-last-page navbar-vertical-aside-toggle-full-align" data-template='<div class="tooltip d-none d-sm-block" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>' data-toggle="tooltip" data-placement="right" title="Expand"></i>
            </button>
            {/* {/* <!-- End Navbar Vertical Toggle --> */}
          </div>

          {/* <!-- Secondary Content --> */}
          <div className="navbar-nav-wrap-content-right">
            {/* <!-- Navbar --> */}
            <ul className="navbar-nav align-items-center flex-row">
              <li className="nav-item d-md-none">
                {/* <!-- Search Trigger --> */}
                <div className="hs-unfold">
                  <a className="js-hs-unfold-invoker btn btn-icon btn-ghost-secondary rounded-circle" href="#"
                     data-hs-unfold-options='{
                       "target": "#searchDropdown",
                       "type": "css-animation",
                       "animationIn": "fadeIn",
                       "hasOverlay": "rgba(46, 52, 81, 0.1)",
                       "closeBreakpoint": "md"
                     }'>
                    <i className="tio-search"></i>
                  </a>
                </div>
                {/* <!-- End Search Trigger --> */}
              </li>

              <li className="nav-item">
                {/* <!-- Account --> */}
                <div className="hs-unfold">
                  <a className="js-hs-unfold-invoker navbar-dropdown-account-wrapper" href="#"
                     data-hs-unfold-options='{
                       "target": "#accountNavbarDropdown",
                       "type": "css-animation"
                     }'>
                    <div className="avatar avatar-sm avatar-circle">
                      <img className="avatar-img" src="/assets/img/160x160/img1.jpg" alt="Image Description"/>
                      <span className="avatar-status avatar-sm-status avatar-status-success"></span>
                    </div>
                  </a>

                  <div id="accountNavbarDropdown" className="hs-unfold-content dropdown-unfold dropdown-menu dropdown-menu-right navbar-dropdown-menu navbar-dropdown-account" style={{width: "16rem"}}>
                    <div className="dropdown-item">
                      <div className="media align-items-center">
                        <div className="avatar avatar-sm avatar-circle mr-2">
                          <img className="avatar-img" src="/assets/img/160x160/img1.jpg" alt="Image Description"/>
                        </div>
                        <div className="media-body">
                          <span className="card-title h5">Admin Schoolmedia</span>
                          <span className="card-text">admin@gmail.com</span>
                        </div>
                      </div>
                    </div>

                    <div className="dropdown-divider"></div>

                    {/* <!-- Unfold --> */}
                    {/* <div className="hs-unfold w-100">
                      <a className="js-hs-unfold-invoker navbar-dropdown-submenu-item dropdown-item d-flex align-items-center" href="#"
                         data-hs-unfold-options='{
                           "target": "#navSubmenuPagesAccountDropdown1",
                           "event": "hover"
                         }'>
                        <span className="text-truncate pr-2" title="Set status">Set status</span>
                        <i className="tio-chevron-right navbar-dropdown-submenu-item-invoker ml-auto"></i>
                      </a>

                      <div id="navSubmenuPagesAccountDropdown1" className="hs-unfold-content hs-unfold-has-submenu dropdown-unfold dropdown-menu navbar-dropdown-sub-menu">
                        <a className="dropdown-item" href="#">
                          <span className="legend-indicator bg-success mr-1"></span>
                          <span className="text-truncate pr-2" title="Available">Available</span>
                        </a>
                        <a className="dropdown-item" href="#">
                          <span className="legend-indicator bg-danger mr-1"></span>
                          <span className="text-truncate pr-2" title="Busy">Busy</span>
                        </a>
                        <a className="dropdown-item" href="#">
                          <span className="legend-indicator bg-warning mr-1"></span>
                          <span className="text-truncate pr-2" title="Away">Away</span>
                        </a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item" href="#">
                          <span className="text-truncate pr-2" title="Reset status">Reset status</span>
                        </a>
                      </div>
                    </div> */}
                    {/* <!-- End Unfold --> */}

                    {/* <a className="dropdown-item" href="#">
                      <span className="text-truncate pr-2" title="Profile &amp; account">Profile &amp; account</span>
                    </a>

                    <a className="dropdown-item" href="#">
                      <span className="text-truncate pr-2" title="Settings">Settings</span>
                    </a>

                    <div className="dropdown-divider"></div>

                    <a className="dropdown-item" href="#">
                      <div className="media align-items-center">
                        <div className="avatar avatar-sm avatar-dark avatar-circle mr-2">
                          <span className="avatar-initials">HS</span>
                        </div>
                        <div className="media-body">
                          <span className="card-title h5">Htmlstream <span className="badge badge-primary badge-pill text-uppercase ml-1">PRO</span></span>
                          <span className="card-text">hs.example.com</span>
                        </div>
                      </div>
                    </a>

                    <div className="dropdown-divider"></div> */}

                    {/* <!-- Unfold --> */}
                    {/* <div className="hs-unfold w-100">
                      <a className="js-hs-unfold-invoker navbar-dropdown-submenu-item dropdown-item d-flex align-items-center" href="#"
                         data-hs-unfold-options='{
                           "target": "#navSubmenuPagesAccountDropdown2",
                           "event": "hover"
                         }'>
                        <span className="text-truncate pr-2" title="Customization">Customization</span>
                        <i className="tio-chevron-right navbar-dropdown-submenu-item-invoker  ml-auto"></i>
                      </a>

                      <div id="navSubmenuPagesAccountDropdown2" className="hs-unfold-content hs-unfold-has-submenu dropdown-unfold dropdown-menu navbar-dropdown-sub-menu">
                        <a className="dropdown-item" href="#">
                          <span className="text-truncate pr-2" title="Invite people">Invite people</span>
                        </a>
                        <a className="dropdown-item" href="#">
                          <span className="text-truncate pr-2" title="Analytics">Analytics</span>
                          <i className="tio-open-in-new"></i>
                        </a>
                        <a className="dropdown-item" href="#">
                          <span className="text-truncate pr-2" title="Customize Front">Customize Front</span>
                          <i className="tio-open-in-new"></i>
                        </a>
                      </div>
                    </div> */}
                    {/* <!-- End Unfold --> */}

                    {/* <a className="dropdown-item" href="#">
                      <span className="text-truncate pr-2" title="Manage team">Manage team</span>
                    </a>

                    <div className="dropdown-divider"></div> */}

                    <span className="dropdown-item" href="#">
                      <Link onClick={this.logout} className="text-truncate p-2" title="Keluar" to="#">Keluar</Link>
                    </span>
                  </div>
                </div>
                {/* <!-- End Account --> */}
              </li>
            </ul>
            {/* <!-- End Navbar --> */}
          </div>
          {/* <!-- End Secondary Content --> */}
        </div>
      </header>
      </>
    );
  }
}

export default Navbar;
