import React from 'react';
import {NavLink} from 'react-router-dom';

export default class LeftBar extends React.Component{
  render(){
    return(
      <aside className="js-navbar-vertical-aside navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-xl navbar-bordered  ">
        <div className="navbar-vertical-container">
          <div className="navbar-vertical-footer-offset">
            <div className="navbar-brand-wrapper justify-content-between">
              {/* <!-- Logo --> */}
              
              
                <a className="navbar-brand" href="/index.html" aria-label="Front">
                  <img className="navbar-brand-logo" src="/assets/svg/logos/logo.png" alt="Logo"/>
                  <img className="navbar-brand-logo-mini" src="/assets/svg/logos/logo-short.png" alt="Logo"/>
                </a>
              
              {/* <!-- End Logo --> */}

              {/* <!-- Navbar Vertical Toggle --> */}
              <button type="button" className="js-navbar-vertical-aside-toggle-invoker navbar-vertical-aside-toggle btn btn-icon btn-xs btn-ghost-dark">
                <i className="tio-clear tio-lg"></i>
              </button>
              {/* <!-- End Navbar Vertical Toggle --> */}
            </div>

            {/* <!-- Content --> */}
            <div className="navbar-vertical-content">
              <ul className="navbar-nav navbar-nav-lg nav-tabs">

                <li className="nav-item">
                  <small className="nav-subtitle" title="Pages">Menu</small>
                  <small className="tio-more-horizontal nav-subtitle-replacer"></small>
                </li>

                {/* <!-- Apps --> */}

                {/* referensi */}
                <li className="navbar-vertical-aside-has-menu ">
                  <a className="js-navbar-vertical-aside-menu-link nav-link nav-link-toggle " href="#" title="Apps">
                    <i className="tio-settings-outlined nav-icon"></i>
                    <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Referensi</span>
                  </a>

                  <ul className="js-navbar-vertical-aside-submenu nav nav-sub">
                    <li className="nav-item">
                      <NavLink className="nav-link pl-5" to="/referensi/struktur_kurikulum/sd" title="Referensi/StrukturKurikulum">
                        <span className="tio-circle nav-indicator-icon"></span>
                        <span className="text-truncate">Struktur Kurikulum</span>
                      </NavLink>
                      <NavLink className="nav-link pl-5" to="/referensi/kompetensi_dasar/sd" title="Referensi/KompetensiDasar">
                        <span className="tio-circle nav-indicator-icon"></span>
                        <span className="text-truncate">Kompetensi Dasar</span>
                      </NavLink>
                      <NavLink className="nav-link pl-5" to="/referensi/kode_wilayah" title="Referensi/KodeWilayah">
                        <span className="tio-circle nav-indicator-icon"></span>
                        <span className="text-truncate">Kode Wilayah</span>
                      </NavLink>
                      <NavLink className="nav-link pl-5" to="/referensi/standar_sarpras/sd" title="Referensi/StandarSarpras">
                        <span className="tio-circle nav-indicator-icon"></span>
                        <span className="text-truncate">Standar Sarana dan Prasarana</span>
                      </NavLink>
                      <NavLink className="nav-link pl-5" to="/referensi/mapel/sd" title="Referensi/StandarSarpras">
                        <span className="tio-circle nav-indicator-icon"></span>
                        <span className="text-truncate">Mapel</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>


                {/* trafik */}
                <li className="navbar-vertical-aside-has-menu ">
                  <a className="js-navbar-vertical-aside-menu-link nav-link nav-link-toggle " href="#" title="Apps">
                    <i className="tio-book-outlined nav-icon"></i>
                    <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Trafik</span>
                  </a>

                  <ul className="js-navbar-vertical-aside-submenu nav nav-sub">
                    <li className="nav-item">
                      <NavLink className="nav-link pl-5" to="/trafik/institusi" title="Trafik/Institusi">
                        <span className="tio-circle nav-indicator-icon"></span>
                        <span className="text-truncate">Trafik Institusi</span>
                      </NavLink>
                      <NavLink className="nav-link pl-5" to="/trafik/guru" title="Trafik/Guru">
                        <span className="tio-circle nav-indicator-icon"></span>
                        <span className="text-truncate">Trafik Guru</span>
                      </NavLink>
                      <NavLink className="nav-link pl-5" to="/trafik/lainnya" title="Trafik/Lainnya">
                        <span className="tio-circle nav-indicator-icon"></span>
                        <span className="text-truncate">Trafik Lainnya</span>
                      </NavLink>
                      <NavLink className="nav-link pl-5" to="/trafik/siswa" title="Trafik/Siswa">
                        <span className="tio-circle nav-indicator-icon"></span>
                        <span className="text-truncate">Trafik Siswa</span>
                      </NavLink>
                      <NavLink className="nav-link pl-5" to="/trafik/ortu" title="Trafik Orang Tua">
                        <span className="tio-circle nav-indicator-icon"></span>
                        <span className="text-truncate">Trafik Orang Tua</span>
                      </NavLink>
                      <NavLink className="nav-link pl-5" to="/trafik/publik" title="Trafik Publik">
                        <span className="tio-circle nav-indicator-icon"></span>
                        <span className="text-truncate">Trafik Publik</span>
                      </NavLink>

                    </li>
                  </ul>
                </li>

                 
                {/* Sarpras Management */}
                <li className="navbar-vertical-aside-has-menu ">
                  <a className="js-navbar-vertical-aside-menu-link nav-link nav-link-toggle " href="#" title="Apps">
                    <i className="tio-city nav-icon"></i>
                    <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Sarana dan Prasarana</span>
                  </a>

                  <ul className="js-navbar-vertical-aside-submenu nav nav-sub">
                    <li className="nav-item">
                      <NavLink className="nav-link pl-5" to="/sarpras/lahan">
                        <span className="tio-circle nav-indicator-icon"></span>
                        <span className="text-truncate">Lahan</span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link pl-5" to="/sarpras/bangunan">
                        <span className="tio-circle nav-indicator-icon"></span>
                        <span className="text-truncate">Bangunan</span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link pl-5" to="/sarpras/ruang">
                        <span className="tio-circle nav-indicator-icon"></span>
                        <span className="text-truncate">Ruang</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>

                {/* user */}
                <li className="navbar-vertical-aside-has-menu ">
                  <a className="js-navbar-vertical-aside-menu-link nav-link nav-link-toggle " href="#" title="Apps">
                    <i className="tio-user-outlined nav-icon"></i>
                    <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">User</span>
                  </a>

                  <ul className="js-navbar-vertical-aside-submenu nav nav-sub">
                    <li className="nav-item">
                      <NavLink className="nav-link pl-5" to="/institusi" title="Sekolah/Institusi">
                        <span className="tio-circle nav-indicator-icon"></span>
                        <span className="text-truncate">Institusi</span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link pl-5" to="/user/guru">
                        <span className="tio-circle nav-indicator-icon"></span>
                        <span className="text-truncate">Guru</span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link pl-5" to="/user/lainnya">
                        <span className="tio-circle nav-indicator-icon"></span>
                        <span className="text-truncate">Lainnya</span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link pl-5" to="/user/siswa">
                        <span className="tio-circle nav-indicator-icon"></span>
                        <span className="text-truncate">Siswa</span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link pl-5" to="/user/ortu">
                        <span className="tio-circle nav-indicator-icon"></span>
                        <span className="text-truncate">Ortu</span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link pl-5" to="/user/publik">
                        <span className="tio-circle nav-indicator-icon"></span>
                        <span className="text-truncate">Publik</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>

                {/* Penugasan */}
                <li className="navbar-vertical-aside-has-menu ">
                  <a className="js-navbar-vertical-aside-menu-link nav-link nav-link-toggle " href="#" title="Apps">
                    <i className="tio-book-opened nav-icon"></i>
                    <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Penugasan</span>
                  </a>

                  <ul className="js-navbar-vertical-aside-submenu nav nav-sub">
                    <li className="nav-item">
                      <NavLink className="nav-link pl-5" to="/penugasan/penugasan_guru">
                        <span className="tio-circle nav-indicator-icon"></span>
                        <span className="text-truncate">Penugasan Guru</span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link pl-5" to="/penugasan/penugasan_lainnya">
                        <span className="tio-circle nav-indicator-icon"></span>
                        <span className="text-truncate">Penugasan Lainnya</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>
                
                {/* Rombel */}
                <li className="navbar-vertical-aside-has-menu ">
                  <a className="js-navbar-vertical-aside-menu-link nav-link nav-link-toggle " href="#" title="Apps">
                    <i className="tio-group-equal nav-icon"></i>
                    <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Rombel</span>
                  </a>

                  <ul className="js-navbar-vertical-aside-submenu nav nav-sub">
                    <li className="nav-item">
                      <NavLink className="nav-link pl-5" to="/rombel/rombel_per_ta">
                        <span className="tio-circle nav-indicator-icon"></span>
                        <span className="text-truncate">Rombel Per TA</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>

                {/* Jadwal Pelajaran */}
                <li className="navbar-vertical-aside-has-menu ">
                  <a className="js-navbar-vertical-aside-menu-link nav-link nav-link-toggle " href="#" title="Apps">
                    <i className="tio-calendar-note nav-icon"></i>
                    <span className="navbar-vertical-aside-mini-mode-hidden-elements text-truncate">Jadwal Pelajaran</span>
                  </a>

                  <ul className="js-navbar-vertical-aside-submenu nav nav-sub">
                    <li className="nav-item">
                      <NavLink className="nav-link pl-5" to="/jadwal_pelajaran/jadwal_pelajaran">
                        <span className="tio-circle nav-indicator-icon"></span>
                        <span className="text-truncate">Jadwal Pelajaran</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>
                {/* <!-- End Apps --> */}

              </ul>
            </div>
            {/* <!-- End Content --> */}
          </div>
        </div>
      </aside>
    )
  }
}