import React from 'react';
import conf from '../../../../config';
import SweetAlert from 'sweetalert2-react';
import ContentBase from "./SarprasItems/ContentBase.jsx";
import { Link } from "react-router-dom";


export default class StandarSarpras extends React.Component{
  constructor(props){
    super(props);
    this.state = {
    
      // alert    
      show_alert        : false,
      title_alert       : '',
      message_alert     : '',
      icon_alert        : '',

      kelas: [
        {
          label: "SD / MI",
          value: 2,
          slug: "sd",
        },
        {
          label: "SMP / MTS",
          value: 3,
          slug: "smp",
        },
        {
          label: "SMA / MA",
          value: 4,
          slug: "sma",
        },
        {
          label: "SMK / MAK",
          value: 5,
          slug: "smk",
        },
      ],
      active_class: '2',

    }

    this.checkLogin = this.checkLogin.bind(this);
  }

  componentDidMount(){
    this.checkLogin();
  }
  
  checkLogin(){
    if (!localStorage.hasOwnProperty("token")) {
      localStorage.setItem('is_login',"");
      localStorage.setItem('token',"");
    }
    if(localStorage.getItem("token") === ""){
      window.location.replace(conf.uri_frontend+"admin_login");
    }
  }

  setActiveClass(v) {
    this.setState({
      active_class: v.value,
    });
  }

  showKelas() {
    let c = this.state.kelas.map((v) => {
      if (this.state.active_class === v.value) {
        return (
          <li className="nav-item">
            <span className="nav-link active">
              {v.label}
            </span>
          </li>
        );
      } else {
        return (
          <li className="nav-item">
            <Link
              to={`/referensi/standar_sarpras/${v.slug}`}
              className="nav-link"
              onClick={() => {
                this.setActiveClass(v);
              }}
            >
              {v.label}
            </Link>
          </li>

        )
      }
    });
    return <ul className="nav nav-pills">{c}</ul>;
  }

  render(){
    return(
      <>
        <main className="main" id="content" role="main">
          {/* alert */}
          <SweetAlert
              show={this.state.show_alert}
              title={this.state.title_alert}
              text={this.state.message_alert}
              type={this.state.icon_alert}
              onConfirm={() => this.setState({ show_alert: false })}
          />

          <div className="content container-fluid">
            {/* <!-- Page Header --> */}
            <div className="page-header">
              <div className="row align-items-end mb-3">
                <div className="col-sm mb-2 mb-sm-0">
                  <nav aria-label="breadcrumb">
                  </nav>

                  <h1 className="page-header-title">Standar Sarana dan Prasarana</h1>
                </div>

              </div>
            </div>
            {/* <!-- End Page Header --> */}

            <div className="card">

              <div className="card-body">
                <ul className="nav nav-segment" id="expensesTab" role="tablist">
                  {this.showKelas()}
                </ul>
                <hr/>
                <ContentBase id={this.state.active_class} />
              </div>
              {/* <!-- End Header --> */}

            </div>
          </div>
        </main>
      </>
    )
  }
}