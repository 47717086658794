import DataTable from '../../../partials/table/DataTable';
import React from "react";
import conf from '../../../../config';
import axios from 'axios';
import tokenApi from "../../../auth/Helpers";
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';

export default class DetailMapel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url            : `${conf.uri_backend}api/admin/v1/user/detail`,
      showModal      : true,
      loading_detail : false,
      data_detail    : '',
      data           : null,
    };

    this.showData  = this.showData.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  componentDidMount() {
    if(this.props.data !== null){
      this.setState({ data: this.props.data });
    }
  }
  
  componentWillReceiveProps(nextProps) {
    if(nextProps.data !== null){
      this.setState({ data: nextProps.data });
    }
  }

  hideModal(){
    this.setState({
      showModal   : false,
      data        : null,
    }, function(){
      this.props.hideModal(true);
    })
  }

  showData(){
    if(this.state.data !== null){
      return(
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th>No</th>
              <th>Mata Pelajaran</th>
            </tr>
          </thead>
          <tbody>
            {this.props.data.map((value, index) => (
              <tr>
                <th>{index + 1 }</th>
                <td>{value.materi}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )
    }
  }
  
  render() {
    return (
      <Modal
        show={this.state.showModal}
        onHide={() => this.hideModal()}
      >
        <Modal.Body>
          {this.showData()}
        </Modal.Body>
        <Modal.Footer>
          <div className="col-12 text-right">
            <button
              className="btn btn-default px-4 py-2 rounded-full items-center"
              type="button"
              style={{ transition: "all .15s ease" }}
              onClick={() => this.hideModal()}
            >
              Tutup
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}