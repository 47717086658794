// import DataTable from '../../../partials/table/DataTable';
import React from "react";
import conf from '../../../../config';
import axios from 'axios';
import tokenApi from "../../../auth/Helpers";
import { Modal } from 'react-bootstrap';

export default class DetailJadwalPelajaran extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      url            : `${conf.uri_backend}api/admin/v1/jadwal/jadwal`,
      detailShow     : false,
      loading_detail : false,
      data_detail    : [],
    };

    this.showData     = this.showData.bind(this);
    this.hideModal    = this.hideModal.bind(this);
  }

  componentDidMount() {
    if(this.props.institusi !== null && this.props.rombelId !== null && this.props.sms !== null && this.props.ta !== null){
      axios.get(this.state.url+"?institusi="+this.props.institusi+"&rombel="+this.props.rombelId+"&semester="+this.props.sms+"&tahun_ajaran="+this.props.ta, {headers:{Authorization:tokenApi}})
      .then(res => {
        this.setState({
          data_detail    : res.data.data,
          loading_detail : false,
          detailShow     : true,
        });
      });
    }
  }
  
  componentWillReceiveProps(nextProps) {
    if(nextProps){
      if(nextProps.institusi !== null && nextProps.rombelId !== null && nextProps.sms !== null && nextProps.ta !== null){
        axios.get(this.state.url+"?institusi="+nextProps.institusi+"&rombel="+nextProps.rombelId+"&semester="+nextProps.sms+"&tahun_ajaran="+nextProps.ta, {headers:{Authorization:tokenApi}})
        .then(res => {
          this.setState({
            data_detail    : res.data.data,
            loading_detail : false,
            detailShow     : true,
          });
        });
      }
    }
  }

  showData(){
    if(this.props.institusi !== null && this.props.rombelId !== null && this.props.sms !== null && this.props.ta !== null && this.state.data_detail !== null){

      var senin  = [];
      var selasa = [];
      var rabu   = [];
      var kamis  = [];
      var jumat  = [];
      var sabtu  = [];
      var minggu = [];

      this.state.data_detail.map((value, index) => {
        var jadwal = [];
        if(value.keterangan !== "Pelajaran"){
          jadwal.push(
            <tr>
              <td><small>{value.keterangan === "Upacara" ? value.jam_ke : ''}</small></td>
              <td><small>{value.start}</small></td>
              <td><small>{value.finish}</small></td>
              <td><small>{value.keterangan}</small></td>
              <td><small>-</small></td>
            </tr>
          )
        } else {
          if(value.pelajaran !== null && value.pelajaran !== undefined){
            var ulMapelMulti    = [];
            var ulGuruMulti     = [];
            var liMapelMulti    = [];
            var liGuruMulti     = [];

            for(var i = 0; i < value.pelajaran.length; i++){

              if(value.pelajaran[i].mp !== undefined){
                ulMapelMulti.push(<li><small>{value.pelajaran[i].mp+" - "+value.pelajaran[i].indeks_mpk}</small></li>);
              } else {
                ulMapelMulti.push(<li><small>-</small></li>);
              }

              if(value.pelajaran[i].nama !== undefined){
                ulGuruMulti.push(<li><small>{value.pelajaran[i].nama+" - "+value.pelajaran[i].indeks_mpk+"-"+value.pelajaran[i].nama.substr(0, 3)}</small></li>);
              } else {
                ulGuruMulti.push(<li><small>-</small></li>);
              }
            }

            ulMapelMulti.push(
              <ul style={{paddingInlineStart: "9px"}}>
                {liMapelMulti}
              </ul>
            );
            ulGuruMulti.push(
              <ul style={{paddingInlineStart: "9px"}}>
                {liGuruMulti}
              </ul>
            );
            jadwal.push(
              <tr>
                <td><small>{value.jam_ke}</small></td>
                <td><small>{value.start}</small></td>
                <td><small>{value.finish}</small></td>
                <td><small>{ulMapelMulti}</small></td>
                <td><small>{ulGuruMulti}</small></td>
              </tr>
            )
          } else {
            jadwal.push(
              <tr>
                <td><small>{value.jam_ke}</small></td>
                <td><small>{value.start}</small></td>
                <td><small>{value.finish}</small></td>
                <td><small>{value.mp} - {value.indeks_mpk}</small></td>
                <td><small>{value.nama} - {value.indeks_mpk_guru}</small></td>
              </tr>
            )
          }
        }

        if(value.m_hari_id === 1 ? senin.push(jadwal) : "");
        if(value.m_hari_id === 2 ? selasa.push(jadwal) : "");
        if(value.m_hari_id === 3 ? rabu.push(jadwal) : "");
        if(value.m_hari_id === 4 ? kamis.push(jadwal) : "");
        if(value.m_hari_id === 5 ? jumat.push(jadwal) : "");
        if(value.m_hari_id === 6 ? sabtu.push(jadwal) : "");
        if(value.m_hari_id === 7 ? minggu.push(jadwal) : "");
      });

      return(
        <>
          <div className="row mt-2">
            <div className="col-6">
              <h3>Senin</h3>
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th><small>Jam Ke</small></th>
                    <th><small>Mulai</small></th>
                    <th><small>Akhir</small></th>
                    <th><small>Mata Pelajaran</small></th>
                    <th><small>Guru</small></th>
                  </tr>
                </thead>
                <tbody>
                  {senin.length > 0 ? senin : (
                    <tr><td colSpan="5"><small>Tidak ada jadwal pada hari ini.</small></td></tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="col-6">
              <h3>Selasa</h3>
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th><small>Jam Ke</small></th>
                    <th><small>Mulai</small></th>
                    <th><small>Akhir</small></th>
                    <th><small>Mata Pelajaran</small></th>
                    <th><small>Guru</small></th>
                  </tr>
                </thead>
                <tbody>
                  {selasa.length > 0 ? selasa : (
                    <tr><td colSpan="5"><small>Tidak ada jadwal pada hari ini.</small></td></tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-6">
              <h3>Rabu</h3>
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th><small>Jam Ke</small></th>
                    <th><small>Mulai</small></th>
                    <th><small>Akhir</small></th>
                    <th><small>Mata Pelajaran</small></th>
                    <th><small>Guru</small></th>
                  </tr>
                </thead>
                <tbody>
                  {rabu.length > 0 ? rabu : (
                    <tr><td colSpan="5"><small>Tidak ada jadwal pada hari ini.</small></td></tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="col-6">
              <h3>Kamis</h3>
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th><small>Jam Ke</small></th>
                    <th><small>Mulai</small></th>
                    <th><small>Akhir</small></th>
                    <th><small>Mata Pelajaran</small></th>
                    <th><small>Guru</small></th>
                  </tr>
                </thead>
                <tbody>
                  {kamis.length > 0 ? kamis : (
                    <tr><td colSpan="5"><small>Tidak ada jadwal pada hari ini.</small></td></tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-6">
              <h3>Jumat</h3>
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th><small>Jam Ke</small></th>
                    <th><small>Mulai</small></th>
                    <th><small>Akhir</small></th>
                    <th><small>Mata Pelajaran</small></th>
                    <th><small>Guru</small></th>
                  </tr>
                </thead>
                <tbody>
                  {jumat.length > 0 ? jumat : (
                    <tr><td colSpan="5"><small>Tidak ada jadwal pada hari ini.</small></td></tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="col-6">
              <h3>Sabtu</h3>
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th><small>Jam Ke</small></th>
                    <th><small>Mulai</small></th>
                    <th><small>Akhir</small></th>
                    <th><small>Mata Pelajaran</small></th>
                    <th><small>Guru</small></th>
                  </tr>
                </thead>
                <tbody>
                  {sabtu.length > 0 ? sabtu : (
                    <tr><td colSpan="5"><small>Tidak ada jadwal pada hari ini.</small></td></tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-6 offset-md-3">
              <h3>Minggu</h3>
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th><small>Jam Ke</small></th>
                    <th><small>Mulai</small></th>
                    <th><small>Akhir</small></th>
                    <th><small>Mata Pelajaran</small></th>
                    <th><small>Guru</small></th>
                  </tr>
                </thead>
                <tbody>
                  {minggu.length > 0 ? minggu : (
                    <tr><td colSpan="5"><small>Tidak ada jadwal pada hari ini.</small></td></tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )
    }
  }

  hideModal(){
    this.setState({
      detailShow  : false,
      data_detail : null,
    }, function(){
      this.props.hideModal(true);
    })
  }

  render() {
    return (
      <Modal
        size="xl"
        show={this.state.detailShow}
        onHide={this.hideModal}
      >
        <Modal.Body className="p-4">
          {this.showData()}
        </Modal.Body>
        <Modal.Footer>
          <div className="col-12 text-right">
            <button
              className="btn btn-default px-4 py-2 rounded-full items-center"
              type="button"
              style={{ transition: "all .15s ease" }}
              onClick={this.hideModal}
            >
              Tutup
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}