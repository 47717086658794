import React from 'react';
import Select from 'react-select';
import conf from '../../../../config';
import tokenApi from "../../../auth/Helpers";
import axios from 'axios';
import SweetAlert from 'sweetalert2-react';

export default class EditInstitusi extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      url                 : `${conf.uri_backend}api/admin/v1/institusi`,
      url_update          : `${conf.uri_backend}api/admin/v1/institusi/update_institusi`,
      url_get_negara      : `${conf.uri_backend}api/v1/referensi/wilayah/negara?per_page=20`,
      url_get_provinsi    : `${conf.uri_backend}api/v1/referensi/wilayah/provinsi?per_page=20&negara_id=`,
      url_get_kota        : `${conf.uri_backend}api/v1/referensi/wilayah/kota?per_page=20&provinsi_id=`,
      url_get_kecamatan   : `${conf.uri_backend}api/v1/referensi/wilayah/kecamatan?per_page=20&kota_id=`,
      url_get_kelurahan   : `${conf.uri_backend}api/v1/referensi/wilayah/kelurahan?per_page=20&kecamatan_id=`,
      username            : '',
      password            : '',
      email               : '',
      second_email        : '',
      npsn                : '',
      jenjang             : '',
      kode_pos            : '',
      nama_institusi      : '',
      sk_pendirian        : '',
      fax                 : '',
      alamat_1            : '',
      alamat_2            : '',
      bujur               : '',
      lintang             : '',
      status_kepemilikan  : '',
      telp_institusi      : '',
      sk_izin_operasional : '',
      website             : '',
      tagline             : '',
      nama_op             : '',
      tgl_sk_izin_operasional  : '',
      tgl_sk_pendirian    : '',
      rt                  : '',
      rw                  : '',
      logo                : '',
      logo_preview        : '',
      cover               : '',
      cover_preview       : '',
      negara_id           : '',
      negara_label        : '',
      provinsi_id         : '',
      provinsi_label      : '',
      kabupaten_id        : '',
      kabupaten_label     : '',
      kecamatan_id        : '',
      kecamatan_label     : '',
      desa_id             : '',
      desa_label          : '',
      logo_old            : '',
      cover_old           : '',
      status_sm           : '',
      verified            : '',
      paid                : '',

      id_provinsi         : '',
      id_kota             : '',
      id_kecamatan        : '',

      jenjang_id          : '',
      jenjang_label       : '',
      disabled            : false,
      showFilter            : false,

      // alert
      show_alert          : false,
      title_alert         : '',
      message_alert       : '',
      icon_alert          : '',
    }
    this.update         = this.update.bind(this);

    this.getJenjang     = this.getJenjang.bind(this);
    this.getStatus      = this.getStatus.bind(this);
    this.getNegara      = this.getNegara.bind(this);
    this.getProv        = this.getProv.bind(this);
    this.getKota        = this.getKota.bind(this);
    this.getKec         = this.getKec.bind(this);
    this.getDesa        = this.getDesa.bind(this);

    // select
    this.selectJenjang  = this.selectJenjang.bind(this);
    this.selectStatus   = this.selectStatus.bind(this);
    this.selectNegara   = this.selectNegara.bind(this);
    this.selectProv     = this.selectProv.bind(this);
    this.selectKota     = this.selectKota.bind(this);
    this.selectKec      = this.selectKec.bind(this);
    this.selectDesa     = this.selectDesa.bind(this);
    this.selectStatusSM = this.selectStatusSM.bind(this);
    this.SelectVerifed  = this.SelectVerifed.bind(this);
    this.SelectPaid     = this.SelectPaid.bind(this);

    this.uploadLogo     = this.uploadLogo.bind(this);
    this.uploadCover    = this.uploadCover.bind(this);
    this.checkLogin     = this.checkLogin .bind(this);
  }
  componentDidMount() {
    this.checkLogin();
    // get data
    const { match: { params } } = this.props;
    this.setState({id_institusi: params.id_institusi});

    axios.get(`${this.state.url}/${params.id_institusi}`).then(res=>{
      this.setState({
        id_select        : res.data.id,
        uuid              : res.data.user.uuid,
        user_id        : res.data.user_id,
        username  : res.data.user.username,
        old_username  : res.data.user.username,
        email  : res.data.user.email,
        old_email  : res.data.user.email,
        second_email  : res.data.user.second_email,
        npsn  : res.data.npsn,
        old_npsn  : res.data.npsn,
        nama_institusi  : res.data.nama_institusi,
        jenjang_id: res.data.jenjang.id,
        jenjang_label: res.data.jenjang.jenjang,
        telp_institusi: res.data.telp_institusi,
        fax: res.data.fax,
        status       : res.data.status,
        website      : res.data.website,
        tagline: res.data.tagline,
        logo_old: res.data.logo,
        cover_old: res.data.cover,
        negara_id: (res.data.negara !== undefined && res.data.negara.id !== null ? res.data.negara.id : ''),
        negara_label: (res.data.negara !== undefined && res.data.negara.nama !== null ? res.data.negara.nama : ''),
        provinsi_id: (res.data.provinsi !== undefined && res.data.provinsi.id !== null ? res.data.provinsi.id : ''),
        provinsi_label: (res.data.provinsi !== undefined && res.data.provinsi.nama !== null ? res.data.provinsi.nama : ''),
        kabupaten_id: (res.data.kabupaten !== undefined && res.data.kabupaten.id !== null ? res.data.kabupaten.id : ''),
        kabupaten_label: (res.data.kabupaten !== undefined && res.data.kabupaten.nama !== null ? res.data.kabupaten.nama : ''),
        kecamatan_id: (res.data.kecamatan !== undefined && res.data.kecamatan.id !== null ? res.data.kecamatan.id : ''),
        kecamatan_label: (res.data.kecamatan !== undefined && res.data.kecamatan.nama !== null ? res.data.kecamatan.nama : ''),
        desa_id: (res.data.desa !== undefined && res.data.desa.id !== null ? res.data.desa.id : ''),
        desa_label: (res.data.desa !== undefined && res.data.desa.nama !== null ? res.data.desa.nama : ''),
        status_sm: (res.data.user !== undefined && res.data.user.status !== null ? res.data.user.status : ''),
        verified: (res.data.user !== undefined && res.data.user.status_verified !== null ? res.data.user.status_verified : ''),
        paid: (res.data.user !== undefined && res.data.user.paid !== null ? String(res.data.user.paid) : ''),
        kode_pos: res.data.kode_pos,
        alamat_1: res.data.alamat_1,
        alamat_2: res.data.alamat_2,
        rt: res.data.rt,
        rw: res.data.rw,
        bujur: res.data.bujur,
        lintang: res.data.lintang,
        status_kepemilikan: res.data.status_kepemilikan,
        sk_pendirian: res.data.sk_pendirian,
        tgl_sk_pendirian: res.data.tgl_sk_pendirian,
        sk_izin_operasional: res.data.sk_izin_operasional,
        tgl_sk_izin_operasional: res.data.tgl_sk_izin_operasional,
        showFilter: true,
      }, function(){
        this.getProv();
        this.getKota();
        this.getKec();
        this.getDesa();
        this.getJenjang();
        this.getStatus();
        this.getNegara();
      })
    })
  }

  checkLogin(){
    if (!localStorage.hasOwnProperty("token")) {
      localStorage.setItem('is_login',"");
      localStorage.setItem('token',"");
    }
    if(localStorage.getItem("token") === ""){
      window.location.replace(conf.uri_frontend+"admin_login");
    }
  }

  // Bind data
  getJenjang(){
    var jenjang = [
      { value: '1',   label: 'TK'   },
      { value: '2',  label: 'SD/MI'  },
      { value: '3', label: 'SMP/MTS' },
      { value: '4',  label: 'SMA/MA'  },
      { value: '5',   label: 'SMK/MAK'   },
      { value: '6', label: 'PKBM' },
      { value: '7', label: 'SLB' },
    ]
    this.setState({ data_jenjang: jenjang });
  }
  getStatus(){
    var status = [
      { value: 'Negeri',   label: 'Negeri'   },
      { value: 'Swasta',  label: 'Swasta'  },
    ]
    this.setState({ data_status: status });
  }
  getNegara(){
    axios.get(this.state.url_get_negara).then(res=>{
      this.setState({ data_negara: res.data});
    })
  }
  getProv(e){
    axios.get(this.state.url_get_provinsi+`${e ? e.value : this.state.negara_id}`).then(res=>{
      this.setState({ data_provinsi: res.data});
    })
  }
  getKota(e){
    axios.get(this.state.url_get_kota+`${e ? e.value : this.state.provinsi_id}`).then(res=>{
      this.setState({ data_kota: res.data});
    })
  }
  getKec(e){
    axios.get(this.state.url_get_kecamatan+`${e ? e.value : this.state.kabupaten_id}`).then(res=>{
      this.setState({ data_kecamatan: res.data});
    })
  }
  getDesa(e){
    axios.get(this.state.url_get_kelurahan+`${e ? e.value : this.state.kecamatan_id}`).then(res=>{
      this.setState({ data_desa: res.data});
    })
  }

  // select
  selectJenjang(e){
    if(e){
      this.setState({jenjang_id: e.value})
    }else{
      this.setState({jenjang_id: ''})
    }
  }
  selectStatus(e){
    if(e){
      this.setState({status: e.value})
    }else{
      this.setState({status: ''})
    }
  }
  selectNegara(e){
    if(e){
      if(this.state.negara_id !== e.value){
        this.setState({
          negara_id: e.value,
          provinsi_label: null,
          provinsi_id: null
        })
      }
      this.getProv(e);
    }else{
      this.setState({negara_id: ''})
    }
  }
  selectProv(e){
    if(e){
      if(this.state.provinsi_id !== e.value){
        this.setState({provinsi_id: e.value})
      }
      this.getKota(e);
    }else{
      this.setState({provinsi_id: ''})
    }
  }
  selectKota(e){
    if(e){
      if(this.state.kabupaten_id !== e.value){
        this.setState({kabupaten_id: e.value})
        this.getKec(e);
      }
      this.getKec(e);
    }else{
      this.setState({kabupaten_id: ''})
    }
  }
  selectKec(e){
    if(e){
      if(this.state.kecamatan_id !== e.value){
        this.setState({kecamatan_id: e.value})
      }
      this.getDesa(e);
    }else{
      this.setState({kecamatan_id: ''})
    }
  }
  selectDesa(e){
    if(e){
      if(this.state.desa_id !== e.value){
        this.setState({desa_id: e.value})
      }
    }else{
      this.setState({desa_id: ''})
    }
  }
  selectStatusSM(e){
    if(e){
      this.setState({status_sm: e.value});
    }else{
      this.setState({status_sm: ''});
    }
  }
  SelectVerifed(e){
    if(e){
      this.setState({verified: e.value})
    }else{
      this.setState({verified: ''})
    }
  }
  SelectPaid(e){
    if(e){
      this.setState({paid: e.value})
    }else{
      this.setState({paid: ''})
    }
  }

  // show select
  showJenjang(){
    if(this.state.data_jenjang){
      return(
        <Select
          defaultValue={this.state.jenjang_id !== '' ? {value: this.state.jenjang_id, label: this.state.jenjang_label}:null}
          options={this.state.data_jenjang}
          onChange={this.selectJenjang}
        />
      )
    }
  }
  showStatus(){
    if(this.state.status){
      return(
        <Select
          defaultValue={this.state.status !== '' ? {value: this.state.status, label: this.state.status}:null}
          options={this.state.data_status}
          onChange={this.selectStatus}
        />
      )
    }
  }
  showStatusSM(){
    if(this.state.showFilter){
      return(
        <Select
          defaultValue={this.state.status_sm !== '' ?{value: this.state.status_sm, label: this.state.status_sm}:null}
          options={[{value: "Active", label: "Active"},{value: "Pending", label: "Pending"},{value: "Non-Active", label: "Non-Active"}]}
          onChange={this.selectStatusSM}
        />
      )
    }
  }
  showVerified(){
    if(this.state.showFilter){
      return(
        <Select
          defaultValue={this.state.verified !== '' ?{value: this.state.verified, label: this.state.verified}:null}
          options={[{value: "Unverified", label: "Unverified"},{value: "Verified", label: "Verified"}]}
          onChange={this.SelectVerifed}
        />
      )
    }
  }
  showPaid(){
    if(this.state.showFilter){
      return(
        <Select
          defaultValue={this.state.paid !== "" ?{value: this.state.paid, label: this.state.paid}:null}
          options={[{value: "0", label: "0"},{value: "1", label: "1"}]}
          onChange={this.SelectPaid}
        />
      )
    }
  }
  showNegara(){
    if(this.state.negara_id){
      return(
        <Select
          defaultValue={this.state.negara_id !== '' ? {value: this.state.negara_id, label: this.state.negara_label} : null}
          options={this.state.data_negara}
          onChange={this.selectNegara}
        />
      )
    }
  }
  showProv(e){
    if (this.state.negara_id){
      return(
        <Select
          defaultValue={this.state.provinsi_id !== '' ? {value: this.state.provinsi_id, label: this.state.provinsi_label} : null}
          options={this.state.data_provinsi}
          onChange={this.selectProv}
        />
      )
    }
  }
  showKab(){
    if(this.state.provinsi_id){
      return(
        <Select
          defaultValue={this.state.kabupaten_id !== '' ? {value: this.state.kabupaten_id, label: this.state.kabupaten_label}:null}
          options={this.state.data_kota}
          onChange={this.selectKota}
        />
      )
    }
  }
  showKec(){
    if(this.state.kabupaten_id){
      return(
        <Select
          defaultValue={this.state.kecamatan_id !== '' ? {value: this.state.kecamatan_id, label: this.state.kecamatan_label}:null}
          options={this.state.data_kecamatan}
          onChange={this.selectKec}
        />
      )
    }
  }
  showDesa(){
    if(this.state.kecamatan_id){
      return(
        <Select
          defaultValue={this.state.desa_id !== '' ? {value: this.state.desa_id, label: this.state.desa_label}:null}
          options={this.state.data_desa}
          onChange={this.selectDesa}
        />
      )
    }
  }
  uploadLogo(e) {
    if(e.target.files[0] !== undefined){
      this.setState({ 
        logo: e.target.files[0],
        logo_preview: URL.createObjectURL(e.target.files[0])
      });
    } else {
      this.setState({ 
        logo: '',
        logo_preview: ''
      });
    }
  }
  uploadCover(e) {
    if(e.target.files[0] !== undefined){
      this.setState({ 
        cover: e.target.files[0],
        cover_preview: URL.createObjectURL(e.target.files[0])
      });
    } else {
      this.setState({ 
        cover: '',
        cover_preview: ''
      });
    }
  }
  update(e){
    e.preventDefault();

    var formData = new window.FormData();
    this.setState({disabled: true});

    formData.append('id', (this.state.id_select));
    formData.append('user_id', (this.state.user_id));
    formData.append('uuid', (this.state.uuid));
    formData.append('logo', (this.state.logo));
    formData.append('cover', (this.state.cover));
    formData.append('logo_old', (this.state.logo_old));
    formData.append('cover_old', (this.state.cover_old));

    formData.append('username', (this.state.username !== null ? this.state.username : ''));
    formData.append('old_username', (this.state.old_username !== null ? this.state.old_username : ''));
    formData.append('password', (this.state.password !== null ? this.state.password : ''));
    formData.append('email', (this.state.email !== null ? this.state.email : ''));
    formData.append('old_email', (this.state.old_email !== null ? this.state.old_email : ''));
    formData.append('second_email', (this.state.second_email !== null ? this.state.second_email : ''));

    formData.append('npsn', (this.state.npsn !== null ? this.state.npsn : ''));
    formData.append('old_npsn', (this.state.old_npsn !== null ? this.state.old_npsn : ''));
    formData.append('m_struktur_jenjang_id', (this.state.jenjang_id !== null ? this.state.jenjang_id : ''));
    formData.append('nama_institusi', (this.state.nama_institusi !== null ? this.state.nama_institusi : ''));
    formData.append('status', (this.state.status !== null ? this.state.status : ''));
    formData.append('status_sm', (this.state.status_sm !== null ? this.state.status_sm : ''));
    formData.append('verified', (this.state.verified !== null ? this.state.verified : ''));
    formData.append('paid', (this.state.paid !== null ? this.state.paid : ''));
    formData.append('telp_institusi', (this.state.telp_institusi !== null ? this.state.telp_institusi : ''));
    formData.append('fax', (this.state.fax !== null ? this.state.fax : ''));
    formData.append('website', (this.state.website !== null ? this.state.website : ''));
    formData.append('tagline', (this.state.tagline !== null ? this.state.tagline : ''));
    formData.append('negara', (this.state.negara_id !== null ? this.state.negara_id : ''));
    formData.append('provinsi', (this.state.provinsi_id !== null ? this.state.provinsi_id : ''));
    formData.append('kabupaten', (this.state.kabupaten_id !== null ? this.state.kabupaten_id : ''));
    formData.append('kecamatan', (this.state.kecamatan_id !== null ? this.state.kecamatan_id : ''));
    formData.append('desa', (this.state.desa_id !== null ? this.state.desa_id : ''));
    formData.append('kode_pos', (this.state.kode_pos !== null ? this.state.kode_pos : ''));
    formData.append('alamat_1', (this.state.alamat_1 !== null ? this.state.alamat_1 : ''));
    formData.append('alamat_2', (this.state.alamat_2 !== null ? this.state.alamat_2 : ''));
    formData.append('rt', (this.state.rt !== null ? this.state.rt : ''));
    formData.append('rw', (this.state.rw !== null ? this.state.rw : ''));
    formData.append('bujur', (this.state.bujur !== null ? this.state.bujur : ''));
    formData.append('lintang', (this.state.lintang !== null ? this.state.lintang : ''));
    formData.append('status_kepemilikan', (this.state.status_kepemilikan !== null ? this.state.status_kepemilikan : ''));
    formData.append('sk_pendirian', (this.state.sk_pendirian !== null ? this.state.sk_pendirian : ''));
    formData.append('tgl_sk_pendirian', (this.state.tgl_sk_pendirian !== null ? this.state.tgl_sk_pendirian : ''));
    formData.append('sk_izin_operasional', (this.state.sk_izin_operasional !== null ? this.state.sk_izin_operasional : ''));
    formData.append('tgl_sk_izin_operasional', (this.state.tgl_sk_izin_operasional !== null ? this.state.tgl_sk_izin_operasional : ''));

    axios.post(`${this.state.url_update}/${this.state.id_institusi}`, formData, {headers:{
      Authorization:tokenApi,
      'Content-Type': 'multipart/form-data'
    }}).then(res => {
      this.setState({ 
        show_alert    : true,
        title_alert   : "Berhasil",
        icon_alert    : "success",
        message_alert : "Berhasil Mengupdate",
      })
    }).catch(err => {
      if(err.response.status === 422){
        this.setState({ 
          show_alert    : true,
          title_alert   : "Gagal",
          icon_alert    : "error",
          message_alert : err.response.data.messages[0],
        });
      } 
    })
  }
  render(){
    return(
      <>
        <main className="main" id="content" role="main">
            {/* alert */}
            <SweetAlert
              show={this.state.show_alert}
              title={this.state.title_alert}
              text={this.state.message_alert}
              type={this.state.icon_alert}
              onConfirm={() => this.setState({ show_alert: false })}
            />
          <div className="content container-fluid">
            {/* alert */}
            {/* <!-- Page Header --> */}
            <div className="page-header" style={{paddingBottom: 'unset !important'}}>
              <div className="row align-items-end mb-3">
                <div className="col-sm mb-2 mb-sm-0">
                  <nav aria-label="breadcrumb">
                  </nav>

                  <h1 className="page-header-title">Edit Sekolah / Institusi</h1>
                </div>

                <div className="col-sm-auto">
                </div>
              </div>
            </div>
            {/* <!-- End Page Header --> */}

            <div className="card mt-4">
              <div className="card-body">
                <form onSubmit={this.update}>
                  <div className="container">
                    <h2 className="text-lg text-schoolmedia font-bold mt-3">Data Login</h2>
                    <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <label for="teamsFilterLabel" className="input-label">Username</label>
                        <input id="username" type="text" className="form-control" placeholder="Username" required
                          defaultValue={this.state.username}
                          onChange={(event) => {
                            this.setState({username: event.target.value});
                          }}
                          autoComplete='off'
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <label for="teamsFilterLabel" className="input-label">Password</label>
                        <input id="password" type="password" className="form-control" placeholder="Password"
                          onChange={(event) => {
                            this.setState({password: event.target.value});
                          }}
                          autoComplete='off'
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <label for="teamsFilterLabel" className="input-label">Email</label>
                        <input id="email" type="email" className="form-control" placeholder="Email" required
                          defaultValue={this.state.email}
                          onChange={(event) => {
                            this.setState({email: event.target.value});
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <label for="teamsFilterLabel" className="input-label">Secondary Email</label>
                        <input id="secondary-email" type="email" className="form-control" placeholder="Secondary Email" 
                          defaultValue={this.state.second_email}
                          onChange={(event) => {
                            this.setState({secondary_email: event.target.value});
                          }}
                        />
                      </div>
                    </div>
                    <p className="text-xs text-gray-300 font-bold mt-3"><i>Catatan: Jika ingin password tetap, harap kosongkan form password.</i></p>
                    <div className="dropdown-divider"></div>

                    <h2 className="text-lg font-bold mt-3">Data 1</h2>
                    <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <label for="teamsFilterLabel" className="input-label">NPSN</label>
                        <input id="npsn" type="number" className="form-control" placeholder="NPSN" required
                          defaultValue={this.state.npsn}
                          onChange={(event) => {
                            this.setState({npsn: event.target.value});
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <label for="teamsFilterLabel" className="input-label">Nama Institusi / Sekolah</label>
                        <input id="nama_institusi" type="text" className="form-control" placeholder="Nama Institusi / Sekolah" required
                          defaultValue={this.state.nama_institusi}
                          onChange={(event) => {
                            this.setState({nama_institusi: event.target.value});
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <label for="teamsFilterLabel" className="input-label">FAX</label>
                        <input id="fax" type="number" className="form-control" placeholder="FAX" 
                          defaultValue={this.state.fax}
                          onChange={(event) => {
                            this.setState({fax: event.target.value});
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <label for="teamsFilterLabel" className="input-label">No Telpon Institusi</label>
                        <input id="telp_institusi" type="number" className="form-control" placeholder="No Telpon Institusi" 
                          defaultValue={this.state.telp_institusi}
                          onChange={(event) => {
                            this.setState({telp_institusi: event.target.value});
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <label for="teamsFilterLabel" className="input-label">Tagline</label>
                        <textarea className="border" name="" id="tagline" cols="30" rows="2" style={{height: '40px', width: '100%'}} required
                          defaultValue={this.state.tagline}
                          onChange={(event) => {
                            this.setState({tagline: event.target.value});
                          }}
                        ></textarea>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <label for="teamsFilterLabel" className="input-label">Jenjang</label>
                        {this.showJenjang()}
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <label for="teamsFilterLabel" className="input-label">Status</label>
                        {this.showStatus()}
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <label for="teamsFilterLabel" className="input-label">Website</label>
                        <input id="website" type="text" className="form-control" placeholder="Website" required
                          defaultValue={this.state.website}
                          onChange={(event) => {
                            this.setState({website: event.target.value});
                          }}
                        />
                      </div>
                    </div>
                    <div className="dropdown-divider"></div>

                    <h2 className="text-lg font-bold mt-3">Data 2</h2>
                    <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <label for="teamsFilterLabel" className="input-label">Status Kepemilikan</label>
                        <input id="nama_institusi" type="text" className="form-control" placeholder="Status Kepemilikan" 
                          value={this.state.status_kepemilikan}
                          onChange={(event) => {
                            this.setState({status_kepemilikan: event.target.value});
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <label for="teamsFilterLabel" className="input-label">SK Pendirian</label>
                        <input id="sk_pendirian" type="text" className="form-control" placeholder="SK Pendirian" 
                          value={this.state.sk_pendirian}
                          onChange={(event) => {
                            this.setState({sk_pendirian: event.target.value});
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <label for="teamsFilterLabel" className="input-label">Tanggal SK Pendirian</label>
                        <input id="tgl_sk_pendirian" type="date" className="form-control" placeholder="" 
                          value={this.state.tgl_sk_pendirian}
                          onChange={(event) => {
                            this.setState({tgl_sk_pendirian: event.target.value});
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <label for="teamsFilterLabel" className="input-label">SK Izin Operasioanl</label>
                        <input id="sk_pendirian" type="text" className="form-control" placeholder="SK Operasional" 
                          value={this.state.sk_izin_operasional}
                          onChange={(event) => {
                            this.setState({sk_izin_operasional: event.target.value});
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <label for="teamsFilterLabel" className="input-label">Tanggal SK Izin Operasioanl</label>
                        <input id="tgl_sk_operasioanl" type="date" className="form-control" placeholder="" 
                          defaultValue={this.state.tgl_sk_izin_operasional}
                          onChange={(event) => {
                            this.setState({tgl_sk_izin_operasional: event.target.value});
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <label for="teamsFilterLabel" className="input-label">Status</label>
                        {this.showStatusSM()}
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <label for="teamsFilterLabel" className="input-label">Paid</label>
                        {this.showPaid()}
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <label for="teamsFilterLabel" className="input-label">Verified </label>
                        {this.showVerified()}
                      </div>
                    </div>
                    <div className="dropdown-divider"></div>
                      
                    <h2 className="text-lg font-bold mt-3">Alamat Institusi</h2>
                    <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <label for="teamsFilterLabel" className="input-label">Alamat 1</label>
                        <input id="alamat_1" type="text" className="form-control" placeholder="Alamat 1" required
                          defaultValue={this.state.alamat_1}
                          onChange={(event) => {
                            this.setState({alamat_1: event.target.value});
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <label for="teamsFilterLabel" className="input-label">Alamat 2</label>
                        <input id="npsn" type="text" className="form-control" placeholder="Alamat 2" 
                          defaultValue={this.state.alamat_2}
                          onChange={(event) => {
                            this.setState({alamat_2: event.target.value});
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <label for="teamsFilterLabel" className="input-label">Kode Pos</label>
                        <input id="kode_pos" type="number" className="form-control" placeholder="Kode Pos" required
                          defaultValue={this.state.kode_pos}
                          onChange={(event) => {
                            this.setState({kode_pos: event.target.value});
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <label for="teamsFilterLabel" className="input-label">Bujur</label>
                        <input id="bujur" type="text" className="form-control" placeholder="Bujur" 
                          defaultValue={this.state.bujur}
                          onChange={(event) => {
                            this.setState({bujur: event.target.value});
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <label for="teamsFilterLabel" className="input-label">Lintang</label>
                        <input id="lintang" type="text" className="form-control" placeholder="Lintang" 
                          defaultValue={this.state.lintang}
                          onChange={(event) => {
                            this.setState({lintang: event.target.value});
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <label for="teamsFilterLabel" className="input-label">Negara</label>
                        {this.showNegara()}
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <label for="teamsFilterLabel" className="input-label">Provinsi</label>
                        {/* {this.state.provinsi_id !== null || this.state.provinsi_id === undefined ? this.showProv() : <Select/>} */}
                        {this.showProv()}
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <label for="teamsFilterLabel" className="input-label">Kota / Kabupaten *</label>
                        {this.showKab()}
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <label for="teamsFilterLabel" className="input-label">Kecamatan</label>
                        {this.showKec()}
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <label for="teamsFilterLabel" className="input-label">Desa / Kelurahan</label>
                        {this.showDesa()}
                      </div>
                      <div className="col-sm-12 col-md-3 col-lg-2 mb-2">
                        <label for="teamsFilterLabel" className="input-label">RT</label>
                        <input id="nama_op" type="number" className="form-control" placeholder="Alamat RT" 
                          defaultValue={this.state.rt}
                          onChange={(event) => {
                            this.setState({rt: event.target.value});
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-3 col-lg-2 mb-2">
                        <label for="teamsFilterLabel" className="input-label">RW</label>
                        <input id="nama_op" type="number" className="form-control" placeholder="Alamat RW" 
                          defaultValue={this.state.rw}
                          onChange={(event) => {
                            this.setState({rw: event.target.value});
                          }}
                        />
                      </div>
                    </div>
                    <div className="dropdown-divider"></div>
                      
                    <h2 className="text-lg font-bold mt-3">Logo Dan Cover</h2>
                    <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <label for="teamsFilterLabel" className="input-label">Logo</label>
                        <p className="mb-0">{this.state.logo_old !== null ? 'Logo lama :' : ''}</p>
                        {this.state.logo_old ? (<img className="img-thumbnail" src={`https://static.schoolmedia.id/assets/socmed/NI/photo/${this.state.logo_old}`}/>) : (<p>Tidak ada Logo</p>)}
                        <p className="mb-0 mt-2">{this.state.logo_preview !== null ? 'Logo Baru' : ''}</p>
                        {this.state.logo_preview !== '' ? (<img className="img-thumbnail" src={this.state.logo_preview}/>) : ''}
                        <input id="logo" type="file" className="form-control" placeholder="Logo"  onChange={this.uploadLogo} />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-4 mb-2">
                        <label for="teamsFilterLabel" className="input-label">Cover</label>
                        <p className="mb-0">{this.state.cover_old !== null ? 'Cover lama :' : ''}</p>
                        {this.state.cover_old ? (<img className="img-thumbnail" src={`https://static.schoolmedia.id/assets/socmed/NI/cover/${this.state.cover_old}`}/>) : (<p>Tidak ada Cover</p>)}
                        <p className="mb-0 mt-2">{this.state.cover_preview !== null ? 'Cover Baru' : ''}</p>
                        {this.state.cover_preview !== '' ? (<img className="img-thumbnail" src={this.state.cover_preview}/>) : ''}
                        <input id="cover" type="file" className="form-control" placeholder="Cover"  onChange={this.uploadCover} />
                      </div>
                    </div>
                    <p className="text-xs text-gray-300 font-bold mt-3"><i>Catatan: Jika tidak ingin mengganti Logo maupun Cover harap jangan upload gambar di form tersebut.</i></p>
                  </div>
                  {/* <!-- End Row --> */}
                  <div className="dropdown-divider"></div>

                  <div className="d-flex justify-content-end">
                    <input type="submit" className="btn btn-primary" value="Simpan"/>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
      </>
    )
  }
}