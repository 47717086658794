import React from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import SweetAlert from 'sweetalert2-react';
import conf from '../../config';

export default class LoginPage extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      url : `${conf.uri_backend}api/admin/v1/auth/login`,
      unauthorized : false,
      seePassword : false,
    }
    this.username = this.username.bind(this);
    this.password = this.password.bind(this);
    this.login = this.login.bind(this);
    this.seePassword = this.seePassword.bind(this);
    this.check = this.check.bind(this);
  }

  componentDidMount(){
    if (!localStorage.hasOwnProperty("token")) {
      localStorage.setItem('is_login',"");
      localStorage.setItem('token',"");
    }
    this.check();
  }

  username(e){
    if(e){
      this.setState({ username: e.target.value });
    } else {
      this.setState({ username: '' });
    }
  }

  password(e){
    if(e){
      this.setState({ password: e.target.value });
    } else {
      this.setState({ password: '' });
    }
  }

  seePassword(){
    var see = !this.state.seePassword;
    this.setState({ seePassword : see });
  }

  login(e){
    e.preventDefault();
    axios.post(`${this.state.url}`, {
      email    : this.state.username,
      password : this.state.password,
    })
      .then(res => {
        localStorage.setItem("token", res.data.access_token);
        localStorage.setItem("is_login", "login");
        this.check();
      })
      .catch(err => {
        if(err.response.status === 401){
          this.setState({ unauthorized : true });
          localStorage.setItem("token", "");
          localStorage.setItem("is_login", "");
          this.check();
        }
      })
  }

  check(){
    var data = window.location.pathname;
    if(localStorage.getItem("token") !== ""){
      window.location.replace(conf.uri_frontend+"institusi");
    }
  }

  render(){
    console.log("rendered ygy")
    return(
      <>
        <header className="position-absolute top-0 left-0 right-0 mt-3 mx-3">
          <div className="d-flex d-lg-none justify-content-between">
            <a href="index.html">
              <img className="w-100" src="/assets/svg/logos/logo.png" alt="Image Description" style={{minWidth: "10rem", maxWidth: "10rem"}}/>
            </a>
            <div id="languageSelect2" className="select2-custom select2-custom-right z-index-2">
            </div>
          </div>
        </header>
        {/* <!-- ========== MAIN CONTENT ========== --> */}
        <main id="content" role="main" className="pt-0">
          {/* <!-- Content --> */}
          <div className="container-fluid px-3">
            <div className="row">
              {/* <!-- Cover --> */}
              <div className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center min-vh-lg-100 position-relative bg-3-white px-0" style={{backgroundColor: "#9EB4C5"}}>
                {/* <!-- Logo & Language --> */}
                <div className="position-absolute top-0 left-0 right-0 mt-3">
                  <div className="d-none d-lg-flex justify-content-between">
                    <img className="w-100" src="./assets/svg/logos/logo.png" alt="Image Description" style={{minWidth: "15rem", maxWidth: "15rem"}}/>
                  </div>
                </div>
                {/* <!-- End Logo & Language --> */}

                <div style={{maxWidth: "23rem"}}>
                  <div className="text-center mb-5">
                    <img className="img-fluid" src="./assets/svg/illustrations/chat.svg" alt="Image Description" style={{width: "12rem"}}/>
                  </div>

                  <div className="mb-5 text-center">
                    <h2 className="display-4">Schoolmedia User Management</h2>
                  </div>

                  {/* <!-- List Checked --> */}
                  {/* <ul className="list-checked list-checked-lg list-checked-primary list-unstyled-py-4">
                    <li className="list-checked-item">
                      <span className="d-block font-weight-bold mb-1">All-in-one tool</span>
                      Build, run, and scale your apps - end to end
                    </li>

                    <li className="list-checked-item">
                      <span className="d-block font-weight-bold mb-1">Easily add &amp; manage your services</span>
                      It brings together your tasks, projects, timelines, files and more
                    </li>
                  </ul> */}
                  {/* <!-- End List Checked --> */}

                  <div className="row justify-content-between mt-5 gx-2">
                  </div>
                  {/* <!-- End Row --> */}
                </div>
              </div>
              {/* <!-- End Cover --> */}

              <div className="col-lg-6 d-flex justify-content-center align-items-center min-vh-lg-100">
                <div className="w-100 pt-10 pt-lg-7 pb-7" style={{maxWidth: "25rem"}}>
                  {/* <!-- Form --> */}
                  <form className="js-validate" onSubmit={this.login}>
                    <div className="text-center mb-5">
                      <h1 className="display-4">Masuk</h1>
                      {/* <p>Don't have an account yet? <a href="authentication-signup-cover.html">Sign up here</a></p> */}
                    </div>

                    <div className="text-center mb-4">
                      <span className="divider text-muted">User Management</span>
                    </div>

                    {/* <!-- Form Group --> */}
                    <div className="js-form-message form-group">
                      <label className="input-label" for="signupSrEmail">Email Anda</label>

                      <input onChange={this.username} type="email" className="form-control form-control-lg" name="email" id="signupSrEmail" placeholder="Markwilliams@example.com" aria-label="Markwilliams@example.com" required data-msg="Please enter a valid email address."/>
                    </div>
                    {/* <!-- End Form Group --> */}

                    {/* <!-- Form Group --> */}
                    <div className="js-form-message form-group">
                      <label className="input-label" for="signupSrPassword" tabindex="0">
                        <span className="d-flex justify-content-between align-items-center">
                          Password Akun Anda
                        </span>
                      </label>

                      <div className="input-group input-group-merge">
                        <input  onChange={this.password} type={this.state.seePassword === true ? ("Text") : ("Password")} className="js-toggle-password form-control form-control-lg" name="password" id="signupSrPassword" placeholder="8+ Karakter" aria-label="8+ characters required" required
                              data-msg="Your password is invalid. Please try again."
                              data-hs-toggle-password-options='{
                                "target": "#changePassTarget",
                                "defaultClass": "tio-hidden-outlined",
                                "showClass": "tio-visible-outlined",
                                "classChangeTarget": "#changePassIcon"
                              }'/>
                        <div id="changePassTarget" className="input-group-append">
                          <a className={this.state.seePassword === true ? ("input-group-text text-primary") : "input-group-text"} onClick={this.seePassword}>
                            <i id="changePassIcon" className="tio-visible-outlined"></i>
                          </a>
                        </div>
                      </div>
                    </div>

                    {this.state.unauthorized === true ? (
                      <div className="js-form-message form-group">
                        <label className="input-label text-danger">Email dan Password anda salah</label>
                      </div>
                    ) : ("")}
                    {/* <!-- End Form Group --> */}
                    <input type="submit" className="btn btn-lg btn-block btn-primary" value="Masuk" />
                  </form>
                  {/* <!-- End Form --> */}
                </div>
              </div>
            </div>
            {/* <!-- End Row --> */}
          </div>
          {/* <!-- End Content --> */}
        </main>
      </>
    )
  }
}